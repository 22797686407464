import { useAccount, useMsal } from "@azure/msal-react"
import { useMutation, useQueryClient } from "react-query"
import { postApi } from "../../../Utils/Api.utils"

function useNewIncidentAsEmployee(props) {
  const { instance, accounts, inProgress } = useMsal()
  const account = useAccount(accounts[0] || {})
  const queryClient = useQueryClient()

  const mutationCreateMessage = useMutation(dataFromIncident => {
    return postApi(
      process.env.GATSBY_API_URL + "/Messages",
      {
        subject: props.title,
        description: props.description,
        direction: false,
        regardingObjectIncidentId: dataFromIncident,
      },
      account,
      inProgress,
      instance
    ).then(res => res.data)
  }, {})

  const mutationCreateIncident = useMutation(
    () => {
      const employmentId = queryClient.getQueryData("employmentId")
      return postApi(
        process.env.GATSBY_API_URL + "/Incidents",
        {
          title: props.title,
          description: props.description,
          caseTypeCode: props.type,
          caseOriginCode: 3,
          status: 1,
          state: 0,
          employmentId: employmentId,
          subjectId: "e9b447eb-de7c-ef11-ac20-6045bd9b574c",
        },
        account,
        inProgress,
        instance
      ).then(res => {
        console.log(res.data)
        return res.data
      })
    },
    {
      onSuccess: data => {
        mutationCreateMessage.mutate(data)
      },
    }
  )
  return mutationCreateIncident
}

export default useNewIncidentAsEmployee
