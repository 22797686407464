import React, { useState, useEffect, useContext } from "react"
import "react-datepicker/dist/react-datepicker.css"
import useApi from "../../hooks/useApi"
import { MyEmployments } from "../../../Utils/Entities"
import MyPageLayout from "../../common/layouts/myPageLayout"
import { Loading } from "../../../Utils/Loading"
import WorkingConditionsContent from "./applications/FlowToOrdinary/WorkingConditions/WorkingConditionsContent"
import {
  changeMembershipTabToNext,
  changeMembershipTabToPrevious,
} from "./helpers"

const WorkingConditions = ({
  setTabId,
  memberTypeParent,
  setShowLoadingData,
  membershipCurrentTabId,
}) => {
  const employmentQuery = useApi<MyEmployments[]>("/Contacts/Employments")
  const [membershipNextTabId, setMembershipNextTabId] = useState(0)
  const [membershipPreviousTabId, setMembershipPreviousTabId] = useState(0)
  const [employments, setEmployments] = useState<MyEmployments[]>([])

  useEffect(() => {
    if (membershipCurrentTabId === 2) {
      employmentQuery.refetch()
    }
  }, [membershipCurrentTabId])

  useEffect(() => {
    if (employmentQuery.isSuccess) {
      setEmployments(employmentQuery.data)
    }
  }, [employmentQuery.data])

  useEffect(() => {
    changeMembershipTabToNext(memberTypeParent.value, setMembershipNextTabId)
    changeMembershipTabToPrevious(
      memberTypeParent.value,
      setMembershipPreviousTabId
    )
  }, [])

  return (
    <div>
      {!employmentQuery.isSuccess && <Loading />}
      {employmentQuery.isSuccess && (
        <WorkingConditionsContent
          employments={employments}
          setTabId={setTabId}
          membershipNextTabId={membershipNextTabId}
          setMembershipNextTabId={setMembershipNextTabId}
          membershipPreviousTabId={membershipPreviousTabId}
          setMembershipPreviousTabId={setMembershipPreviousTabId}
          setShowLoadingData={setShowLoadingData}
        />
      )}
    </div>
  )
}

export default WorkingConditions
