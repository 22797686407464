import { ErrorMessage } from "@hookform/error-message"
import React from "react"
import ReactDatePicker from "react-datepicker"
import { Controller, UseFormReturn } from "react-hook-form"
import { FormValues } from "./FlowToOrdinaryTypes"

const CancelEmployment = (props: {
  index: number
  formController: UseFormReturn<FormValues, any>
}) => {
  const {
    control,
    formState: { errors },
  } = props.formController

  const startDateString = props?.formController?.getValues(
    `employments.${props.index}.startDate`
  )
  const startDate = startDateString
    ? new Date(
        new Date(startDateString).setDate(
          new Date(startDateString).getDate() + 1
        )
      )
    : undefined

  return (
    <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:pt-5 mt-6">
      <label
        htmlFor="role"
        className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
      >
        Sluttdato:
      </label>
      <div className="mt-1 sm:mt-0 sm:col-span-2">
        <Controller
          rules={{ required: { message: "Sluttdato påkrevet", value: true } }}
          control={control}
          name={`employments.${props.index}.endDate`}
          render={({ field: { onChange, onBlur, value } }) => (
            <ReactDatePicker
              locale="nb"
              onChange={onChange}
              onBlur={onBlur}
              selected={value ? new Date(value) : undefined}
              dateFormat="dd/MM/yyyy"
              minDate={startDate}
              className="block max-w-sm w-full shadow-sm focus:ring-meny-700 focus:border-meny-500 sm:text-sm border-gray-300 rounded-md"
            />
          )}
        />
        <ErrorMessage
          errors={errors}
          name={`employments.${props.index}.endDate`}
          render={({ message }) => (
            <p className="text-sm text-red-600">{message}</p>
          )}
        />
      </div>
    </div>
  )
}

export default CancelEmployment
