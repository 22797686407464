import { Link } from "gatsby"
import React from "react"
import HeaderText from "../../../common/text/Header"

const EndringStudent = () => {
  return (
    <div className="max-w-3xl mx-auto divide-y-2 divide-gray-200">
      <HeaderText text="Endring fra studentmedlemskap til ordinært medlemskap" />
      <form
        className="mt-6
                  space-y-6
                  
                  bg-white
                  rounded-lg
                  shadow
                  px-5
                  py-6
                  sm:px-6"
        method="POST"
      >
        <h1>
          <p>
            Så snart du har autorisasjon eller lisens må du melde overgang til
            ordinært medlem. Ordinært medlemskap i Norsk psykologforening er
            betinget av godkjenning (autorisasjon/lisens) som psykolog i Norge
            iht. helsepersonelloven av 16. juni 2000. Godkjenning foretas av
            Helsedirektoratet. Du må derfor oppgi ditt HPR-nr. Dette benyttes
            som oppslag mot Helsepersonellregisteret.{" "}
          </p>
        </h1>
        <div className="flex justify-center py-4">
          <div>
            <Link to="/app/medlemskap/applications/studentordinaer">
              <button className="ml-1 inline-flex justify-center py-2 px-8 border border-transparent shadow-sm text-md font-medium rounded-md text-white bg-meny-700 hover:bg-meny-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-meny-600">
                Søk her
              </button>
            </Link>
          </div>
        </div>
        <h2>
          {" "}
          Når du har mottatt din autorisasjon kan du melde deg inn som ordinært
          medlem. Dette skjer ved at du fyller ut{" "}
          <a
            href="/app/medlemskap/nymedlemskap"
            className="underline text-blue-600 hover:text-blue-800 visited:text-purple-600"
          >
            innmeldingsskjema for psykolog
          </a>{" "}
          . Her ber vi om at du oppgir ditt HPR-nr. Dette benyttes som oppslag
          mot Helsepersonellregisteret.
        </h2>

        <ul>
          <li>
            &bull;&nbsp;{" "}
            <a
              href="/app/medlemskap/fordeler"
              className="underline text-blue-600 hover:text-blue-800 visited:text-purple-600"
            >
              Se dine medlemsfordeler
            </a>
          </li>
          <li>
            &bull;&nbsp;{" "}
            <a
              href="/app/medlemskap/nymedlemskap"
              className="underline text-blue-600 hover:text-blue-800 visited:text-purple-600"
            >
              Innmeldingsskjema for psykolog
            </a>
          </li>
          <li>
            &bull;&nbsp;{" "}
            <a
              href="/app/profil"
              className="underline text-blue-600 hover:text-blue-800 visited:text-purple-600"
            >
              {" "}
              Min Profil
            </a>
          </li>
        </ul>
      </form>
    </div>
  )
}

export default EndringStudent
