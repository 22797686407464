import { useAccount, useMsal } from "@azure/msal-react"
import { useMutation } from "react-query"
import { toast } from "react-toastify"
import { MyEmployments } from "../../../../Utils/Entities"
import { patchApi } from "../../../../Utils/Api.utils"

function usePatchEmploymentSimple() {
  const { instance, accounts, inProgress } = useMsal()
  const account = useAccount(accounts[0] || {})

  return useMutation<unknown, unknown, Partial<MyEmployments>>(
    data => {
      return patchApi(
        `${process.env.GATSBY_API_URL}/Employments/${data?.id}`,
        data,
        account,
        inProgress,
        instance
      ).then(res => {
        res.data
      })
    },
    {
      onSuccess: () => {},
    }
  )
}

export default usePatchEmploymentSimple
