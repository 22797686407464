import { ErrorMessage } from "@hookform/error-message"
import React, { useState } from "react"
import { DropDownPresetValues } from "../../../../common/inputFields/DropDownPresetValues"
import MyPageLayout from "../../../../common/layouts/myPageLayout"
import HelperText from "../../../../common/text/HelperText"
import { useFormContext } from "react-hook-form"
import { FormValues } from "./FlowToOrdinaryTypes"

export const NewEmploymentIncident = () => {
  const {
    register,
    setValue,
    formState: { errors },
  } = useFormContext<FormValues>() // retrieve all hook methods

  const descriptionDefaultText =
    "Rolle: Hovedarbeidsgiver \nArbeidsgiver: \nArbeidssted: \nAdresse på arbeidssted: \nPostnummer på arbeidssted: \nAvdeling på arbeidssted: \nOrgnummer: \nStilling: \nProsentvis stilling: \nStartdato:"

  return (
    <div>
      <div>
        <p className="block text-sm italic font-normal sm:col-span-2 text-meny-700 sm:mt-px sm:pt-2 sm:col-start-3">
          <button
            type="button"
            onClick={() => {
              setValue("makeIncidentAsEmployee", false)
              setValue("iSNewEmploymentAsEmployee", true)
            }}
            className="text-meny-700 underline"
          >
            Gå tilbake til å velge arbeidsgiver, arbeidssted og stilling
          </button>
        </p>
      </div>
      <div className="space-y-8 divide-y divide-gray-200">
        <div className="pt-8 pb-8">
          <div>
            <h3 className="text-lg leading-6 font-medium text-meny-700">
              Saksinformasjon
            </h3>
            <HelperText helpertext="Felter markert med * er obligatoriske" />
          </div>
          <div className=" mt-6 grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6">
            <div className="sm:col-span-3">
              <label
                htmlFor="title"
                className="block text-sm font-medium text-gray-700"
              >
                Emne*
              </label>
              <div className="mt-1">
                <input
                  disabled
                  defaultValue="Mangler arbeidsgiver eller arbeidssted"
                  className=" p-2 shadow-sm focus:ring-meny-700 focus:border-meny-500 block w-full sm:text-sm border border-gray-300 bg-white rounded-md"
                />
              </div>
            </div>

            <div className="sm:col-span-3">
              <label
                htmlFor="country"
                className="block text-sm font-medium text-gray-700"
              >
                Type*
              </label>
              <div className="mt-1">
                <DropDownPresetValues
                  options={[]}
                  setParentValue={() => {}}
                  text={"Arbeidsforhold"}
                />
              </div>
            </div>

            <div className="sm:col-span-6">
              <label
                htmlFor="description"
                className="block text-sm font-medium text-gray-700"
              >
                Beskrivelse*
              </label>
              <p className="mt-2 text-sm text-gray-600">
                Skriv noen ord om saken.
              </p>
              <div className="mt-1">
                <textarea
                  {...register("incident.description")}
                  id="incident.description"
                  name="incident.description"
                  minLength={10}
                  maxLength={2000}
                  rows={3}
                  required
                  className=" h-72 shadow-sm focus:ring-meny-700 focus:border-meny-500 block w-full sm:text-sm border border-gray-300 rounded-md"
                  defaultValue={descriptionDefaultText}
                />
                <ErrorMessage
                  errors={errors}
                  name="incident.description"
                  render={({ message }) => (
                    <p className="text-sm text-red-600">{message}</p>
                  )}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
