import { memberShipTabs } from "../../../../Utils/Tabs"

export const resetMembershipTab = () => {
  const index0 = memberShipTabs.findIndex(item => item.id === 0)
  const index1 = memberShipTabs.findIndex(item => item.id === 1)
  const index2 = memberShipTabs.findIndex(item => item.id === 2)
  const index3 = memberShipTabs.findIndex(item => item.id === 3)

  memberShipTabs[index0].current = true

  memberShipTabs[index0].show = true
  memberShipTabs[index1].show = true
  memberShipTabs[index2].show = true
  memberShipTabs[index3].show = true
}

export const hideMembershipTab = tabid => {
  const index0 = memberShipTabs.findIndex(item => item.id === 0)
  const index1 = memberShipTabs.findIndex(item => item.id === 1)
  const index2 = memberShipTabs.findIndex(item => item.id === 2)
  const index3 = memberShipTabs.findIndex(item => item.id === 3)

  switch (tabid) {
    case 0:
      memberShipTabs[index0].show = false
      break
    case 1:
      memberShipTabs[index1].show = false
      break
    case 2:
      memberShipTabs[index2].show = false
      break
    case 3:
      memberShipTabs[index3].show = false
      break

    default:
      break
  }
}

export const changeMembershipTabToNext = (memberTypeParent, setMembershipNextTabId) => {
  const currentTabId = memberShipTabs.find((tab) => tab.current)?.id

  if (memberTypeParent == 1) {
    switch (currentTabId) {
      case 0:
        setMembershipNextTabId(1)
        break
      case 1:
        setMembershipNextTabId(2)
        break
      case 2:
        setMembershipNextTabId(3)
        break
    }
  }

  if (memberTypeParent == 2) {
    switch (currentTabId) {
      case 0:
        setMembershipNextTabId(3)
        break
    }
  }
  if (memberTypeParent == 0) {
    switch (currentTabId) {
      case 0:
        setMembershipNextTabId(1)
        break
      case 1:
        setMembershipNextTabId(3)
        break
    }
  }
}

export const changeMembershipTabToPrevious = (memberTypeParent, setMembershipPreviousTabId) => {
  const currentTabId = memberShipTabs.find((tab) => tab.current)?.id

  if (memberTypeParent == 1) {
    switch (currentTabId) {
      case 1:
        setMembershipPreviousTabId(0)
        break
      case 2:
        setMembershipPreviousTabId(1)
        break
      case 3:
        setMembershipPreviousTabId(2)
        break
    }
  }

  if (memberTypeParent == 2) {
    switch (currentTabId) {
      case 3:
        setMembershipPreviousTabId(0)
        break
    }
  }
  if (memberTypeParent == 0) {
    switch (currentTabId) {
      case 1:
        setMembershipPreviousTabId(0)
        break
      case 3:
        setMembershipPreviousTabId(1)
        break
    }
  }
}

export const changeMembershipTab = tabid => {

  const index0 = memberShipTabs.findIndex(item => item.id === 0)
  const index1 = memberShipTabs.findIndex(item => item.id === 1)
  const index2 = memberShipTabs.findIndex(item => item.id === 2)
  const index3 = memberShipTabs.findIndex(item => item.id === 3)

  switch (tabid) {
    case 0:
      memberShipTabs[index0].current = true
      memberShipTabs[index1].current = false
      memberShipTabs[index2].current = false
      memberShipTabs[index3].current = false
      break
    case 1:
      memberShipTabs[index0].current = false
      memberShipTabs[index1].current = true
      memberShipTabs[index2].current = false
      memberShipTabs[index3].current = false
      break
    case 2:
      memberShipTabs[index0].current = false
      memberShipTabs[index1].current = false
      memberShipTabs[index2].current = true
      memberShipTabs[index3].current = false
      break
    case 3:
      memberShipTabs[index0].current = false
      memberShipTabs[index1].current = false
      memberShipTabs[index2].current = false
      memberShipTabs[index3].current = true
      break

    default:
      break
  }
}

export const dnummer = [
  { id: "ingen", name: "Jeg har ikke Fødselsnummer/D-nummer" },
]
