import React, { useEffect, useState } from "react"
import { RadioGroupComponent } from "../../../../common/radioGroup/RadioGroup"
import { employerTypes } from "../../../../../Utils/optionSet"
import { NewEmploymentIncident } from "./NewEmploymentIncident"
import { NewEmploymentAsEmployee } from "./NewEmploymentAsEmployee"
import { useFormContext } from "react-hook-form"
import { FormValues } from "./FlowToOrdinaryTypes"
import { NewEmploymentAsIndependent } from "./NewEmploymentAsIndependent"

function AddNewEmploymentAccordionContent() {
  const [employerType, setEmployerType] = useState<number>(0)
  const { watch, setValue } = useFormContext<FormValues>()
  const makeIncidentAsEmployee = watch("makeIncidentAsEmployee")

  useEffect(() => {
    setValue("iSNewEmploymentAsEmployee", employerType === 292460000)
    setValue("iSNewEmploymentAsIndependent", employerType === 292460001)
    if (employerType === 292460001) {
      setValue("makeIncidentAsEmployee", false)
      setValue("iSNewEmploymentAsEmployee", false)
    } else if (employerType === 292460000) {
      setValue("iSNewEmploymentAsIndependent", false)
    }
  }, [employerType])

  return (
    <div>
      <div className="max-w-7xl mx-auto pb-6 px-4 sm:px-6 lg:px-8 flex justify-center">
        <div className="max-w-lg">
          <RadioGroupComponent
            label={"Velg et arbeidsforhold"}
            value={employerType}
            onChange={setEmployerType}
            type={employerTypes}
          />
        </div>
      </div>
      <>
        {employerType === 292460000 && (
          <>
            {makeIncidentAsEmployee && <NewEmploymentIncident />}
            {!makeIncidentAsEmployee && <NewEmploymentAsEmployee />}
          </>
        )}
        {employerType === 292460001 && <NewEmploymentAsIndependent />}
      </>
    </div>
  )
}

export default AddNewEmploymentAccordionContent
