import React, { useEffect, useState } from "react"
import {
  FormProvider,
  useFieldArray,
  useForm,
  useFormContext,
  useWatch,
} from "react-hook-form"
import {
  IndependentEmployment,
  MyEmployments,
  MyEmploymentsIndepedent,
  PatchAndPostEmployment,
} from "../../../../../Utils/Entities"
import { employmentTypes } from "../../../../../Utils/optionSet"
import { RadioGroupComponent } from "../../../../common/radioGroup/RadioGroup"
import AddNewEmploymentAccordion from "./AddNewEmploymentAccordion"
import EmploymentCard from "./EmploymentCard"
import {
  FormValues,
  Incident,
  MyEmploymentsExtended,
} from "./FlowToOrdinaryTypes"
import { navigate } from "gatsby"
import { toast } from "react-toastify"

export type ExistingEmploymentToOrdinaryProps = {
  employments: MyEmployments[]
  consentLiabality: boolean
}

function ExistingEmploymentToOrdinary(
  props: ExistingEmploymentToOrdinaryProps
) {
  const [disableButton, setDisableButton] = useState<boolean>(false)
  const [error, setError] = useState<string>()
  const [shouldUnregister, setShouldUnregister] = useState<boolean | undefined>(
    false
  )
  const [employerType, setEmployerType] = useState<number>(0)
  const [hasIncremented, setHasIncremented] = useState(false)

  const employmentData = props.employments
    ?.filter(isEmploymentOngoing)
    .filter(isValidEmployment)
    .sort(sortEmploymentsByDate)
    .map(item => ({
      ...item,
      action: item.endDate ? "cancel" : "keep",
    })) as MyEmploymentsExtended[]

  const formController = useForm<FormValues>({
    shouldUnregister: shouldUnregister,
    defaultValues: {
      employments: employmentData,
      isNewEmployment: false,
      makeIncidentAsEmployee: false,
      iSNewEmploymentAsEmployee: false,
      iSNewEmploymentAsIndependent: false,
      newEmployment: {},
      newEmploymentAsEmployeeProps: {},
      newEmploymentAsIndependentProps: {},
      incidentDescriptionForIndependent: {},
    },
  })

  useEffect(() => {
    formController.setValue("employments", employmentData)
  }, [props.employments])

  const fieldController = useFieldArray({
    control: formController.control,
    name: "employments",
  })

  const watchForm = useWatch({
    control: formController.control,
    name: "employments",
  })

  const watchIsEmploymentAsEmployee = useWatch({
    control: formController.control,
    name: "iSNewEmploymentAsEmployee",
  })

  const watchIsNewEmployment = useWatch({
    control: formController.control,
    name: "isNewEmployment",
  })

  //Change the shouldRegister value when the user selects a new employment type independent = true and employee = false
  useEffect(() => {
    const checkUnregister = formController.getValues(
      "iSNewEmploymentAsIndependent"
    )
    setShouldUnregister(checkUnregister)
  }, [formController.getValues("iSNewEmploymentAsIndependent")])

  useEffect(() => {
    let mainEmploymentCount = checkMainEmploymentCount(watchForm)

    if (watchIsNewEmployment === true) {
      mainEmploymentCount++
      setHasIncremented(true)
    } else if (watchIsNewEmployment === false && hasIncremented) {
      mainEmploymentCount--
      setHasIncremented(false)
    }

    if (mainEmploymentCount > 1) {
      setError("Du kan ikke ha flere hovedarbeidsforhold")
      setDisableButton(true)
      return
    }

    setError("")
    setDisableButton(false)
  }, [watchForm, watchIsNewEmployment])

  //Remeber to put newEmploymentAsEmployeeProps.workplaceError back to false when --> "Legg til ny arbeidsgiver" --> "Ansatt" --> "Avbryt"
  useEffect(() => {
    if (watchIsEmploymentAsEmployee === false) {
      formController.setValue(
        "newEmploymentAsEmployeeProps.workplaceError",
        false
      )
    }
  }, [watchIsEmploymentAsEmployee])

  const onSubmit = (data: FormValues) => {
    let mainEmploymentCount = checkMainEmploymentCount(watchForm)
    let remainingData: IndependentEmployment = {}

    if (formController.getValues("isNewEmployment") === true)
      mainEmploymentCount++

    if (mainEmploymentCount > 1) {
      setError("Du kan ikke ha flere hovedarbeidsforhold")
      setDisableButton(true)
      toast.error("Du kan ikke ha flere hovedarbeidsforhold")
      return
    }

    //Disable button if employerType === "Yrkesaktiv"/292460000 and mainEmploymentCount === 0
    if (employerType === 292460000 && mainEmploymentCount === 0) {
      setError("Du må legge til et hovedarbeidsforhold")
      setDisableButton(true)
      toast.error("Du må legge til et hovedarbeidsforhold")
      return
    }

    const isNewEmployment = formController.getValues("isNewEmployment")

    // New Employment as employee with or without incident
    const iSNewEmploymentAsEmployee = formController.getValues(
      "iSNewEmploymentAsEmployee"
    )

    const NewEmploymentValues = formController.getValues("newEmployment")

    const newEmploymentAsEmployeePropsValues = formController.getValues(
      "newEmploymentAsEmployeeProps"
    )

    const makeIncidentAsEmployee = formController.getValues(
      "makeIncidentAsEmployee"
    )

    //New Employment as independent
    const iSNewEmploymentAsIndependent = formController.getValues(
      "iSNewEmploymentAsIndependent"
    )

    const newEmploymentAsIndependentValues = formController.getValues(
      "newEmploymentAsIndependent"
    )

    const newEmploymentAsIndependentPropsValues = formController.getValues(
      "newEmploymentAsIndependentProps"
    )

    const incidentDescriptionForIndependent = formController.getValues(
      "incidentDescriptionForIndependent"
    )
    // create new employment as employee
    const startDate: Date | undefined = iSNewEmploymentAsEmployee
      ? formController.getValues("newEmployment.startDate")
      : formController.getValues(
          "newEmploymentAsIndependent.employment.startDate"
        )

    const statuscode = startDate > new Date() ? 292460002 : 1
    const statecode = 0 // 0 = aktiv
    const role = 125600000 // 125600000 = hoverarbeidsgiver

    const newEmploymentEmployeeBody: MyEmployments | null =
      iSNewEmploymentAsEmployee && !makeIncidentAsEmployee
        ? {
            ...NewEmploymentValues,
            role: role, // 125600000 = hoverarbeidsgiver
            statecode: statecode, // 0 = aktiv
            workcategory: 292460000,

            //@ts-ignore
            statuscode: statuscode,
          }
        : null

    const newEmploymentEmployeeIncidentBody: Incident | null =
      makeIncidentAsEmployee
        ? {
            type: 20, // 20 = "Arbeidsforhold"
            title: "Mangler arbeidsgiver eller arbeidssted",
            description: formController.getValues("incident.description"),
          }
        : null

    //Check if an incident is created for independent
    const newEmploymentIndependentIncidentBody =
      newEmploymentAsIndependentPropsValues?.clientAccountWithOrgNumber === null
        ? {
            formattedDescription:
              `Orgnummer: ${incidentDescriptionForIndependent?.clientOrgNumber},
              Navn: ${incidentDescriptionForIndependent?.clientAccountName},
              Adresse: ${incidentDescriptionForIndependent?.clientAccountAddress},
              Postnummer: ${incidentDescriptionForIndependent?.clientAccountZipCode}`
                .split("\n") // Split the string into individual lines
                .map(line => line.trim()) // Trim each line
                .join("\n"), // Join the lines back together
          }
        : null

    // create new employment as independent
    const newEmploymentIndependentBody: MyEmploymentsIndepedent =
      iSNewEmploymentAsIndependent
        ? {
            ...newEmploymentAsIndependentValues,
            employment: {
              ...newEmploymentAsIndependentValues?.employment,
              role: role, // 125600000 = main employment
              statecode: statecode, // 0 = aktiv
              statuscode: statuscode,
            },
          }
        : null

    if (newEmploymentIndependentBody) {
      // Check if the user works for a client
      if (newEmploymentAsIndependentPropsValues?.worksForClient === "Yes") {
        // If client employment is selected from the list or not
        if (newEmploymentAsIndependentPropsValues?.showEmpAlt) {
          newEmploymentIndependentBody.employment.clientsName =
            newEmploymentAsIndependentPropsValues?.clientAccountWithOrgNumber?.name
          newEmploymentIndependentBody.employment.clientOrgNo =
            newEmploymentAsIndependentPropsValues?.clientAccountWithOrgNumber?.accountNumber
          newEmploymentIndependentBody.employment.clientAddress =
            newEmploymentAsIndependentPropsValues?.clientAccountWithOrgNumber?.address1_Line1
          newEmploymentIndependentBody.employment.clientZipCodeId =
            newEmploymentAsIndependentPropsValues?.clientAccountWithOrgNumber?.zipCodeId
          newEmploymentIndependentBody.employment.clientId =
            newEmploymentAsIndependentPropsValues?.clientAccountWithOrgNumber?.id
        } else {
          newEmploymentIndependentBody.employment.clientsName =
            newEmploymentAsIndependentPropsValues?.selectedClientAccount?.name
          newEmploymentIndependentBody.employment.clientOrgNo =
            newEmploymentAsIndependentPropsValues?.selectedClientAccount?.accountNumber
          newEmploymentIndependentBody.employment.clientAddress =
            newEmploymentAsIndependentPropsValues?.selectedClientAccount?.address1_Line1
          newEmploymentIndependentBody.employment.clientZipCodeId =
            newEmploymentAsIndependentPropsValues?.selectedClientAccount?.zipCodeId
          newEmploymentIndependentBody.employment.clientId =
            newEmploymentAsIndependentPropsValues?.selectedClientAccount?.id
        }
      }

      //Check if there is an account with the orgNumber
      if (
        newEmploymentAsIndependentPropsValues?.accountWithOrgNumber === null
      ) {
        // Add different vlaues depending on workcategory and typePrivatePractitioner
        {
          newEmploymentAsIndependentPropsValues?.workcategory === 292460001 &&
          newEmploymentAsIndependentPropsValues?.typePrivatePractitioner ===
            292460000
            ? (newEmploymentIndependentBody.account.name =
                newEmploymentAsIndependentPropsValues.accountName)
            : null
        }
        newEmploymentIndependentBody.account.accountNumber =
          newEmploymentAsIndependentPropsValues.orgNumber
        newEmploymentIndependentBody.account.countryCodeId =
          process.env.GATSBY_COUNTRY_CODE
        newEmploymentIndependentBody.account.address1_Line2 =
          newEmploymentIndependentBody.account.address1_Line1
        newEmploymentIndependentBody.account.zipCodeId =
          newEmploymentAsIndependentPropsValues.zipCodeData?.id
        {
          newEmploymentAsIndependentPropsValues?.workcategory === 292460001 &&
          newEmploymentAsIndependentPropsValues?.typePrivatePractitioner ===
            292460000
            ? (newEmploymentIndependentBody.account.employer = false)
            : (newEmploymentIndependentBody.account.employer = true)
        }
        if (
          newEmploymentAsIndependentPropsValues.typePrivatePractitioner ===
          292460000
        ) {
          newEmploymentIndependentBody.account.tariffId =
            process.env.GATSBY_TARIFF_INDEPENDENT
        } else if (
          newEmploymentAsIndependentPropsValues.typePrivatePractitioner ===
          292460001
        ) {
          newEmploymentIndependentBody.account.tariffId =
            process.env.GATSBY_TARIFF_IN_AS
        }
      } else {
        newEmploymentIndependentBody.employment.employer =
          newEmploymentAsIndependentPropsValues?.accountWithOrgNumber?.id
        delete newEmploymentIndependentBody.account
      }

      //Check if clientAccountWithOrgNumber is not ""
      if (
        newEmploymentAsIndependentPropsValues?.clientAccountWithOrgNumber !== ""
      ) {
        newEmploymentIndependentBody.employment.clientId =
          newEmploymentAsIndependentPropsValues.clientAccountWithOrgNumber?.id
      }

      //Check if aggremmentspecialist is "yes" or "no"
      if (
        newEmploymentAsIndependentPropsValues?.agreementspecialist === 1 &&
        newEmploymentAsIndependentPropsValues?.workcategory === 292460001
      ) {
        newEmploymentIndependentBody.employment.rightOfReimbursment = true
      } else if (
        newEmploymentAsIndependentPropsValues?.agreementspecialist === 0 &&
        newEmploymentAsIndependentPropsValues?.workcategory === 292460001
      ) {
        newEmploymentIndependentBody.employment.rightOfReimbursment = false
      }

      // Remove undefined properties from newEmploymentAsIndependent.employment
      Object.keys(newEmploymentIndependentBody.employment).forEach(key => {
        if (newEmploymentIndependentBody.employment[key] === undefined) {
          delete newEmploymentIndependentBody.employment[key]
        }
      })

      remainingData = newEmploymentIndependentBody.employment
    }

    // update existing employments
    const existingEmploymentBody = data.employments.map(work => {
      let body: Partial<MyEmployments> = { ...work }
      return body
    })

    // TODO:  added consentLiabality: location.state.consentLiabality
    //Check if the user has selected a valid employer and workplace for employee
    if (
      iSNewEmploymentAsEmployee === true &&
      (newEmploymentAsEmployeePropsValues?.notAWorkplace === true ||
        newEmploymentEmployeeBody?.workPlace === null ||
        newEmploymentEmployeeBody?.workPlace === undefined)
    ) {
      formController.setValue(
        "newEmploymentAsEmployeeProps.workplaceError",
        true
      )
    }
    //Check if the user has selected a valid "Oppdragsgiver" for independent employment
    else if (
      newEmploymentAsIndependentPropsValues?.employerAndWorkplace === false &&
      newEmploymentAsIndependentPropsValues?.showEmpAlt === false &&
      newEmploymentAsIndependentPropsValues?.worksForClient === "Yes" &&
      iSNewEmploymentAsIndependent === true
    ) {
      // setEmployerError("Du må velge en oppdragsgiver");
      formController.setValue(
        "newEmploymentAsIndependentProps.employerError",
        "Du må velge en oppdragsgiver"
      )
    } else {
      navigate("/app/medlemskap/applications/sendStudentToOrdinary", {
        state: {
          iSNewEmploymentAsEmployee: iSNewEmploymentAsEmployee,
          newEmploymentEmployeeBody: newEmploymentEmployeeBody,
          makeIncidentAsEmployee: makeIncidentAsEmployee,
          newEmploymentEmployeeIncidentBody: newEmploymentEmployeeIncidentBody,
          iSNewEmploymentAsIndependent: iSNewEmploymentAsIndependent,
          newEmploymentIndependentBody: newEmploymentIndependentBody,
          newEmploymentIndependentIncidentBody:
            newEmploymentIndependentIncidentBody,
          remainingData: remainingData,
          existingEmployment: existingEmploymentBody,
          isNewEmployment: isNewEmployment,
          professionallyActiveStatus: employerType,
          clientAccountWithOrgNumber:
            newEmploymentAsIndependentPropsValues?.clientAccountWithOrgNumber,
          accountWithOrgNumberExist:
            newEmploymentAsIndependentPropsValues?.accountWithOrgNumberExist,
          consentLiabality: props.consentLiabality,
        },
      })
    }
  }

  return (
    <div>
      <div className="max-w-5xl mx-auto divide-y-2 divide-gray-200">
        <FormProvider {...formController}>
          <form onSubmit={formController.handleSubmit(onSubmit)}>
            <div className="flex justify-center">
              <RadioGroupComponent
                label={"Velg et arbeidsforhold"}
                type={employmentTypes}
                value={employerType}
                onChange={setEmployerType}
              />
            </div>
            {employerType === 292460000 && (
              <>
                <AddNewEmploymentAccordion />
                {fieldController.fields?.map((work: MyEmployments, index) => {
                  return (
                    <div key={work?.id}>
                      <EmploymentCard
                        work={work}
                        index={index}
                        formController={formController}
                      />
                    </div>
                  )
                })}
              </>
            )}
            {(employerType === 292460001 || employerType === 292460002) && (
              <>
                {fieldController.fields?.map((work: MyEmployments, index) => {
                  return (
                    <div key={work?.id}>
                      <EmploymentCard
                        work={work}
                        employerType={employerType}
                        index={index}
                        formController={formController}
                      />
                    </div>
                  )
                })}
              </>
            )}
            <div className="pt-5 flex justify-end">
              <button
                type="submit"
                disabled={disableButton}
                className="disabled:opacity-50 disabled:bg-meny-600 ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-meny-700 hover:bg-meny-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-meny-700"
              >
                Neste
              </button>
            </div>
            <div className="pt-5 flex justify-end">
              <p className="text-red-600">{error}</p>
            </div>
          </form>
        </FormProvider>
      </div>
    </div>
  )
}

const isValidEmployment = (work: MyEmployments) => {
  const isEmployerDefined =
    work.employer !== undefined || work.employerAlternative !== undefined

  return isEmployerDefined
}

const isEmploymentOngoing = (work: MyEmployments) => {
  const today = new Date()
  if (work?.endDate != undefined) {
    const endDate = new Date(work?.endDate)
    if (endDate.getTime() < today.getTime()) return false
  }
  return true
}

const sortEmploymentsByDate = (a, b) => {
  const rolePriority = {
    125600000: 1, // highest priority
    125600001: 2, // second highest priority
  }

  const priorityA = rolePriority[a.role] || 3 // default priority if not found
  const priorityB = rolePriority[b.role] || 3 // default priority if not found

  // First, sort by role priority
  if (priorityA !== priorityB) {
    return priorityA - priorityB
  }

  // If both a and b have no endDate, sort by startDate
  if (!a.endDate && !b.endDate) {
    return (
      new Date(b.startDate || "").getTime() -
      new Date(a.startDate || "").getTime()
    )
  }

  // If only a has no endDate, a should come first
  if (!a.endDate) {
    return -1
  }

  // If only b has no endDate, b should come first
  if (!b.endDate) {
    return 1
  }

  // If both a and b have an endDate, sort by startDate
  return (
    new Date(b.startDate || "").getTime() -
    new Date(a.startDate || "").getTime()
  )
}

const checkMainEmploymentCount = (work: MyEmploymentsExtended[]) => {
  let mainEmploymentCount = 0

  work?.map(work => {
    if (
      (work?.role == 125600000 && work?.endDate !== null) ||
      work?.action === "convert"
    ) {
      mainEmploymentCount++
    }

    if (work?.role == 125600000 && work.action === "cancel") {
      mainEmploymentCount--
    }
  })
  return mainEmploymentCount
}

export default ExistingEmploymentToOrdinary
