import { useAccount, useMsal } from "@azure/msal-react"
import { useMutation } from "react-query"
import { toast } from "react-toastify"
import { postApi } from "../../../Utils/Api.utils"
import useMyEvents from "../events/useMyEvents"
import { MyEmployments } from "../../../Utils/Entities"

function useNewEmploymentSimple() {
  const { instance, accounts, inProgress } = useMsal()
  const account = useAccount(accounts[0] || {})
  const eventQuery = useMyEvents()

  return useMutation<unknown, unknown, Partial<MyEmployments>>(
    data => {
      return postApi(
        `${process.env.GATSBY_API_URL}/Employments`,
        data,
        account,
        inProgress,
        instance
      ).then(res => {
        res.data
      })
    },
    {
      onSuccess: () => {
        if (eventQuery?.data?.eventRegistration?.length > 0) {
          toast.success(
            "Du har en eller flere påmeldinger på kurs, husk å oppdatere fakturamottager på dine deltagelser",
            {
              position: "top-center",
              autoClose: 6000,
            }
          )
        }
      },
    }
  )
}

export default useNewEmploymentSimple
