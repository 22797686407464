import { useAccount, useMsal } from "@azure/msal-react"
import { useMutation, useQueryClient } from "react-query"
import { toast } from "react-toastify"
import { postApi } from "../../../Utils/Api.utils"
import { navigate } from "gatsby"

type ToastConfig = {
  position?:
    | "top-center"
    | "top-right"
    | "top-left"
    | "bottom-center"
    | "bottom-right"
    | "bottom-left"
  autoClose?: number
  hideProgressBar?: boolean
  closeOnClick?: boolean
  pauseOnHover?: boolean
  draggable?: boolean
}

type Incident = {
  title: string
  description: string
  caseTypeCode?: number
  caseOriginCode?: number
  status?: number
  state?: number
  employmentId?: string
  subjectId?: string
}

type IncidentParams = {
  incident: Incident
  toastMessage?: string
  toastConfig?: ToastConfig
}

function useNewIncident({
  incident,
  toastMessage = "Saken er opprettet! Du vil nå bli videreført til dine saker.",
  toastConfig = {
    position: "top-center",
    autoClose: 5000,
  },
}: IncidentParams) {
  const { instance, accounts, inProgress } = useMsal()
  const account = useAccount(accounts[0] || {})
  const queryClient = useQueryClient()

  const mutationCreateIncident = useMutation(
    () => {
      const employmentId = queryClient.getQueryData("employmentId") as
        | string
        | undefined

      incident.employmentId = employmentId

      return postApi(
        process.env.GATSBY_API_URL + "/Incidents",
        incident,
        account,
        inProgress,
        instance
      ).then(res => {
        return res.data
      })
    },
    {
      onSuccess: _data => {
        toast.success(toastMessage, toastConfig)
        queryClient.invalidateQueries(["userIncidentData"])
        window.setTimeout(() => {
            navigate("/app/dialog/minesaker")
          }, 2500)
      },
      onError: _error => {},
    }
  )
  return mutationCreateIncident
}

export default useNewIncident
