import React, { useEffect, useState } from "react"
// import { Disclosure } from "@headlessui/react"
import { navigate } from "gatsby"
import {
  MsalAuthenticationTemplate,
  useAccount,
  useMsal,
} from "@azure/msal-react"
import { InteractionType } from "@azure/msal-browser"
import { fetchApi, patchApi } from "../../../Utils/Api.utils"
import MyPageLayout from "../../common/layouts/myPageLayout"
import HeaderText from "../../common/text/Header"
import { loginRequest } from "../../security/authConfig"
import { useMutation, useQuery } from "react-query"
import { Loading } from "../../../Utils/Loading"
import { ErrorComponent } from "../../common/graphichs/ErrorComponent"
import Modal from "../../common/modal/Modal"
import {
  employmentTypes,
  retiredEmploymentTypes,
} from "../../../Utils/optionSet"
import { RadioGroupComponent } from "../../common/radioGroup/RadioGroup"
import MainArbeidsforhold from "../../mypagecomponents/work/MainArbeidsforhold"
import Arbeidsforhold from "../../mypagecomponents/work/Arbeidsforhold"
import { toast } from "react-toastify"
import LoadingData from "../../common/graphichs/LoadingData"
import { professionallyActiveStatus } from "../../../Utils/Maps"
import { MyEmployments } from "../../../Utils/Types"
import IncomingWork from "../../mypagecomponents/work/IncomingWork"
import useEmploymentStatusModal from "../../hooks/Modal/useEmploymentStatusModal"

const EmploymentContent = () => {
  const [showModal, setShowModal] = useState(false)
  const today = new Date()
  const { instance, accounts, inProgress } = useMsal()
  const account = useAccount(accounts[0] || {})
  const [activeEmployment, setActiveEmployment] = useState<boolean>(false)
  const [activeEmploymentId, setActiveEmploymentId] = useState<string>("")
  const [employmentType, setEmploymentType] = useState<number>(0)
  const [newEmploymentType, setNewEmploymentType] = useState<number>(0)
  const [modalValues, setModalValues] = useState({})
  const [showLoadingData, setShowLoadingData] = useState(true)
  const [timer, setTimer] = useState<number>(2500)
  const [activeMainEmployment, setActiveMainEmployment] =
    useState<MyEmployments>()
  const [incomingMainEmployment, setIncomingMainEmployment] =
    useState<MyEmployments>()
  const [hasIncomingEmployment, setHasIncomingEmployment] =
    useState<boolean>(false)
  const [activeEmployments, setActiveEmployments] = useState<boolean>(false)
  const [changeEmploymentButton, setChangeEmploymentButton] =
    useState<boolean>(false)

  const [sMedlem, setSMedlem] = useState<boolean>(false)
  const [membershipError, setMembershipError] = useState<boolean>(false)
  const [retired, setRetired] = useState<boolean>(false)

  const userInfoQuery = useQuery(
    "userInfo",
    () =>
      fetchApi(
        process.env.GATSBY_API_URL + "/Contacts/me",
        account,
        inProgress,
        instance
      ).then(res => res.data),
    {
      onSuccess: data => {
        setEmploymentType(
          data?.professionallyActiveStatus === undefined
            ? 292460000
            : data?.professionallyActiveStatus
        )
        setNewEmploymentType(
          data?.professionallyActiveStatus === undefined
            ? 292460000
            : data?.professionallyActiveStatus
        )
        setRetired(data?.retired)
      },
      onError: _error => {},
    }
  )

  const getSub = useQuery(
    "getSub",
    () =>
      fetchApi(
        `${process.env.GATSBY_API_URL}/Contacts/Subscriptions`,
        account,
        inProgress,
        instance
      ).then(response => {
        response?.data.forEach(sub => {
          if (
            sub?.parentOrganizationUnit?.name == "Norsk Psykologforening" &&
            sub?.subscriptionTypeName == "Medlemskap" &&
            sub?.stateCode == 0 &&
            sub?.membershipCategory?.name == "Student"
          ) {
            setSMedlem(true)
          }
        })
      }),
    {
      onSuccess: data => {
        setShowLoadingData(false)
      },
      onError: _error => {
        setMembershipError(true)
        toast.error("Det har skjedd en feil, prøv igjen senere", {
          position: "top-center",
          autoClose: 6000,
        })
      },
    }
  )

  const employmentQuery = useQuery(
    "employment",
    () =>
      fetchApi(
        process.env.GATSBY_API_URL + "/Contacts/Employments",
        account,
        inProgress,
        instance
      ).then(res => res.data),
    {
      enabled: userInfoQuery.isSuccess,
      onSuccess: data => {
        data?.find(employment => {
          if (employment.role == 125600000 && employment.statuscode == 1) {
            setActiveEmployment(true)
            setActiveEmploymentId(employment.id)
          }
          if (employment.endDate === undefined) {
            setActiveEmployments(true)
          }
          if (employment.statecode == 0) {
            setChangeEmploymentButton(true)
          }
        })
        const incomingEmployment = data?.filter(
          employment =>
            employment?.role == 125600000 && employment?.statuscode == 292460002
        )
        if (incomingEmployment.length > 0) {
          setHasIncomingEmployment(true)
          setIncomingMainEmployment(incomingEmployment)
        }
      },
      onError: _error => {},
    }
  )

  const mutationUpdateEmployment = useMutation(
    () => {
      return patchApi(
        process.env.GATSBY_API_URL + "/Contacts/me",
        {
          professionallyActiveStatus: newEmploymentType,
        },
        account,
        inProgress,
        instance
      ).then(res => res.data)
    },
    {
      onSuccess: data => {
        setShowLoadingData(false)
        window.setTimeout(() => {
          toast.success("Arbeidsforhold er oppdatert", {
            position: "top-center",
            autoClose: 6000,
          })
        }, timer)
      },
      onError: _error => {
        setShowLoadingData(false)
      },
      onSettled: () => {},
    }
  )

  useEmploymentStatusModal(
    employmentType,
    newEmploymentType,
    activeEmploymentId,
    activeEmployments,
    professionallyActiveStatus,
    setModalValues,
    setShowModal,
    setNewEmploymentType,
    mutationUpdateEmployment,
    activeEmployment,
    incomingMainEmployment,
    hasIncomingEmployment,
    retired
  )

  return showLoadingData ||
    userInfoQuery.isLoading ||
    getSub.isLoading ||
    employmentQuery.isLoading ||
    mutationUpdateEmployment.isLoading ? (
    <LoadingData />
  ) : (
    <MyPageLayout props="Arbeidsforhold">
      {showModal && <Modal setModalOpen={setShowModal}>{modalValues}</Modal>}
      <div className="max-w-7xl mx-auto pb-6 px-4 sm:px-6 lg:px-8 flex justify-center">
        {/* If something goes wrong with the membership check api, dont show any options */}
        {membershipError ? null : (
          <>
            {sMedlem && (
              <div>
                <h3 className="text-md leading-6 font-medium text-meny-700">
                  Du er registrert som studentmedlem. Studentmedlemmer kan ikke
                  ha hovedarbeidsforhold, eller være registrert som
                  arbeidssøkende / ikke yrkesaktive før endt utdanning.
                  Vennligst meld overgang til ordinært medlem, dersom du er
                  ferdig med utdanningen.{" "}
                  <button
                    className="underline"
                    onClick={() =>
                      navigate("/app/medlemskap/applications/studentordinaer")
                    }
                  >
                    Klikk her
                  </button>
                </h3>
              </div>
            )}
            {retired === true && sMedlem === false && (
              <div>
                <div>
                  <h3 className="text-md leading-6 font-medium text-meny-700">
                    Du er registrert som pensjonist. Pensjonister kan ha
                    hovedarbeidsforhold og/eller biarbeidsforhold, eller være
                    registrert som ikke yrkesaktive. For å registrere
                    arbeidsforhold, trykk på knapp for hhv. "Opprett
                    hovedarbeidsforhold" eller "Opprett biarbeidsforhold".
                    Dersom du er registrert med hovedarbeidsforhold, kan du
                    endre på dette ved å trykke på "Endre hovedarbeidsforhold".
                  </h3>
                </div>
                <div className="flex justify-center">
                  <RadioGroupComponent
                    label={"Velg et arbeidsforhold"}
                    value={employmentType}
                    onChange={setNewEmploymentType}
                    type={retiredEmploymentTypes}
                  />
                </div>
              </div>
            )}

            {retired === false && sMedlem === false && (
              <div className="max-w-lg">
                <RadioGroupComponent
                  label={"Velg et arbeidsforhold"}
                  value={employmentType}
                  onChange={setNewEmploymentType}
                  type={employmentTypes}
                />
              </div>
            )}
          </>
        )}
      </div>
      {sMedlem ? (
        <div className="flex flex-col sm:flex-row justify-center sm:space-x-4">
          {changeEmploymentButton && (
            <div className="flex justify-center py-4">
              <button
                onClick={() => {
                  navigate("/app/profil/endre-arbeidsforhold")
                }}
                className=" w-64 sm:w-fit inline-flex justify-center py-2 px-8 border border-transparent shadow-sm text-md font-medium rounded-md text-white bg-meny-700 hover:bg-meny-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-meny-700"
              >
                Endre eksisterende arbeidsforhold
              </button>
            </div>
          )}
          <div className="flex justify-center py-4">
            <button
              type="submit"
              onClick={() =>
                navigate("/app/profil/nyttarbeid/", {
                  state: { role: 125600001 },
                })
              }
              className=" w-64 sm:w-fit inline-flex justify-center py-2 px-8 border border-transparent shadow-sm text-md font-medium rounded-md text-white bg-meny-700 hover:bg-meny-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-meny-600"
            >
              Opprett biarbeidsforhold
            </button>
          </div>
        </div>
      ) : (
        <>
          <div className="max-w-7xl mx-auto py-6 px-4 sm:px-6 lg:px-8">
            <HeaderText text="Hovedarbeidsforhold" />
            <MainArbeidsforhold
              activeMainEmployment={activeMainEmployment}
              setActiveMainEmployment={setActiveMainEmployment}
            />
          </div>

          {hasIncomingEmployment && (
            <div className="max-w-7xl mx-auto py-6 px-4 sm:px-6 lg:px-8">
              <HeaderText text="Kommende hovedarbeidsforhold" />
              <IncomingWork incomingMainEmployment={incomingMainEmployment} />
            </div>
          )}

          <div className="flex flex-col sm:flex-row justify-center sm:space-x-4">
            {/* Bytt hovedarbeidsforhold button only if there is no incoming work
            and the user has a main work */}
            {employmentType === 292460000 &&
              activeMainEmployment !== undefined &&
              hasIncomingEmployment === false && (
                <div className="flex justify-center py-4">
                  <button
                    onClick={() =>
                      navigate("/app/profil/endrearbeid/", {
                        state: {
                          workId: activeEmploymentId,
                          goToNewEmploymentPage: true,
                        },
                      })
                    }
                    className=" w-64 sm:w-fit inline-flex justify-center py-2 px-8 border border-transparent shadow-sm text-md font-medium rounded-md text-white bg-meny-700 hover:bg-meny-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-meny-700"
                  >
                    Bytt hovedarbeidsforhold
                  </button>
                </div>
              )}
            {/* Nytt hovedarbeidsforhold button only if there is no incoming work
            and the user has no main work */}
            {employmentType === 292460000 &&
              activeMainEmployment === undefined &&
              hasIncomingEmployment === false && (
                <div className="flex justify-center py-4">
                  <button
                    type="submit"
                    onClick={() =>
                      navigate("/app/profil/nyttarbeid/", {
                        state: { role: 125600000 },
                      })
                    }
                    className=" w-64 sm:w-fit inline-flex justify-center py-2 px-8 border border-transparent shadow-sm text-md font-medium rounded-md text-white bg-meny-700 hover:bg-meny-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-meny-600"
                  >
                    Nytt hovedarbeidsforhold
                  </button>
                </div>
              )}
            {employmentType !== 292460001 && (
              <>
                {changeEmploymentButton && (
                  <div className="flex justify-center py-4">
                    <button
                      onClick={() => {
                        navigate("/app/profil/endre-arbeidsforhold")
                      }}
                      className=" w-64 sm:w-fit inline-flex justify-center py-2 px-8 border border-transparent shadow-sm text-md font-medium rounded-md text-white bg-meny-700 hover:bg-meny-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-meny-700"
                    >
                      Endre eksisterende arbeidsforhold
                    </button>
                  </div>
                )}

                <div className="flex justify-center py-4">
                  <button
                    type="submit"
                    onClick={() =>
                      navigate("/app/profil/nyttarbeid/", {
                        state: { role: 125600001 },
                      })
                    }
                    className=" w-64 sm:w-fit inline-flex justify-center py-2 px-8 border border-transparent shadow-sm text-md font-medium rounded-md text-white bg-meny-700 hover:bg-meny-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-meny-600"
                  >
                    Opprett biarbeidsforhold
                  </button>
                </div>
              </>
            )}
          </div>
        </>
      )}
      <div className="max-w-7xl mx-auto py-6 px-4 sm:px-6 lg:px-8">
        <HeaderText text="Biarbeidsforhold" />
        <Arbeidsforhold />
      </div>
    </MyPageLayout>
  )
}
const Employments = ({ data }) => {
  const authRequest = {
    ...loginRequest,
  }
  return (
    <MsalAuthenticationTemplate
      interactionType={InteractionType.Redirect}
      authenticationRequest={authRequest}
      errorComponent={ErrorComponent}
      loadingComponent={Loading}
    >
      <EmploymentContent />
    </MsalAuthenticationTemplate>
  )
}

export default Employments
