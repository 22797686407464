import { useAccount, useMsal } from "@azure/msal-react"
import { navigate } from "gatsby"
import { useMutation, useQueryClient } from "react-query"
import { toast } from "react-toastify"
import { postApi } from "../../../Utils/Api.utils"

function useCreateIncident(incidentDescription) {
  const { instance, accounts, inProgress } = useMsal()
  const account = useAccount(accounts[0] || {})
  const queryClient = useQueryClient()

  const mutationCreateMessage = useMutation(
    dataFromIncident => {
      return postApi(
        process.env.GATSBY_API_URL + "/Messages",
        {
          subject: "Oppdragsgiver ikke i listen",
          description: incidentDescription,
          direction: false,
          regardingObjectIncidentId: dataFromIncident,
        },
        account,
        inProgress,
        instance
      ).then(res => res.data)
    },
    {
      onSuccess: data => {
        queryClient.invalidateQueries(["userMessagesData"])
        setTimeout(() => {
          navigate("/app/profil/arbeidsforhold")
        }, 4000)
      },
      onError: _error => {},
      onSettled: () => {},
    }
  )

  const mutationCreateIncident = useMutation(
    () => {
      const employmentId = queryClient.getQueryData("employmentId")
      return postApi(
        process.env.GATSBY_API_URL + "/Incidents",
        {
          title: "Manglende info arbeidsforhold - oppdragsgiver mangler",
          description: incidentDescription,
          caseTypeCode: 20,
          caseOriginCode: 3,
          status: 1,
          state: 0,
          employmentId: employmentId,
          subjectId: "e9b447eb-de7c-ef11-ac20-6045bd9b574c",
        },
        account,
        inProgress,
        instance
      ).then(res => {
        console.log(res.data)
        return res.data
      })
    },
    {
      onSuccess: data => {
        mutationCreateMessage.mutate(data)
        queryClient.invalidateQueries(["userIncidentData"])
      },
      onError: _error => {},
    }
  )
  return mutationCreateIncident
}

export default useCreateIncident
