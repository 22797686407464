import { useAccount, useMsal } from "@azure/msal-react"
import { ChevronDownIcon } from "@heroicons/react/outline"
import React, { useEffect, useState } from "react"
import { useMutation, useQuery, useQueryClient } from "react-query"
import { toast } from "react-toastify"
import { cyanButton } from "../../../styles/tailwindClasses"
import {
  fetchApi,
  patchApi,
  postApi,
  postGetDocumentApi,
} from "../../../Utils/Api.utils"
import { RemoveUnderScore } from "../../../Utils/FormatHelper"
import ConfirmationDialog from "../../common/graphichs/ConfirmationDialog"
import LoadingData from "../../common/graphichs/LoadingData"
import { SmallListLoading } from "../../common/loading"
import GetHelpText from "../../common/text/GetHelpText"
import LinkText from "../../common/text/LinkText"
import {
  documentMutationType,
  documentType,
  DocumentTypeEnum,
} from "../../hooks/useDocuments/types"
import useDocuments from "../../hooks/useDocuments/useDocuments"
import DocumentList from "../document/DocumentList"

const EducationInfo = ({
  setRefetchDoc,
  refetchDoc,
  SelectedSpecialityEducation,
  specialityEducations,
}) => {
  const queryClient = useQueryClient()
  const { instance, accounts, inProgress } = useMsal()
  const account = useAccount(accounts[0] || {})
  const [sentForApproval, setSentForApproval] = useState(false)
  const [speicialityAdded, setSpeicialityAdded] = useState(false)
  const [showRequestApproval, setShowRequestApproval] = useState(false)
  const [openSendSchema, setOpenSendSchema] = useState(false)
  const [showConfirmation1, setShowConfirmation1] = useState(false)
  const [showConfirmation2, setShowConfirmation2] = useState(false)
  const [specialities, setSpecialities] = useState<any[]>()
  const [selectedSpeciality, setSelectedSpeciality] = useState(0)
  const [comment, setComment] = useState("")
  const [programs, setPrograms] = useState<any[]>()
  const [showLoadingData, setShowLoadingData] = useState(false)

  const [showHelpText, setShowHelpText] = useState<boolean>(false)

  const [documents, setDocuments] = useState<Array<documentType>>([])
  const [loading, setLoading] = useState<boolean>(false)

  const GetDocuments = useMutation(
    (item: documentMutationType) => {
      return postGetDocumentApi(
        process.env.GATSBY_API_URL + "/Documents/Regarding/Get",
        item,
        account,
        inProgress,
        instance
      ).then(res => res.data)
    },
    {
      onSuccess: data => {
        setDocuments(data)
        // setRefetchDoc(false)
      },
      onError: _error => {
        console.error("failed to fetch documents", _error)
      },
      onSettled: () => {
        setLoading(false)
      },
    }
  )

  const mutate = (id: documentMutationType) => {
    setLoading(true)
    GetDocuments.mutate(id)
    setRefetchDoc(false)
  }

  useEffect(() => {
    // if (disableAutoFetch !== true) {
    if (refetchDoc) {
      mutate({ EducationId: SelectedSpecialityEducation?.id })
    }
  }, [refetchDoc])

  // const { documents, loading, mutate } = useDocuments({
  //   id: { EducationId: SelectedSpecialityEducation?.id },
  //   disableAutoFetch: !refetchDoc
  // })

  const diplomaDocuments =
    documents && documents.length > 0
      ? documents?.filter(
          item => item.documentType === DocumentTypeEnum.DiplomSpesialist
        )
      : []

  const today = new Date()
  // const [diplomaDocuments, setDiplomaDocuments] = useState<documentType[]>([])

  useEffect(() => {
    setShowHelpText(false)
    if (SelectedSpecialityEducation.startDateOfEducation !== undefined) {
      const startDate = new Date(
        SelectedSpecialityEducation.startDateOfEducation
      )
      if (startDate < today) {
        const diffDays = (today, start) =>
          Math.ceil(Math.abs(start - today) / (1000 * 60 * 60 * 24))

        const difference = diffDays(today, startDate)
        // console.log("Antall dager siden: " + difference)
        // if session was more than three and a half years ago
        if (difference > 1260) {
          setShowHelpText(true)
        }
      }
    }
  }, [SelectedSpecialityEducation.startDateOfEducation])

  // useEffect(() => {

  //   const diploma =
  //     documents && documents.length > 0
  //       ? documents?.filter(
  //           item => item.documentType === DocumentTypeEnum.DiplomSpesialist
  //         )
  //       : []
  //   setDiplomaDocuments(diploma)
  // }, [SelectedSpecialityEducation?.id])

  const specialitiesQuery = useQuery(
    "specialitiesDataActive",
    () =>
      fetchApi(
        process.env.GATSBY_API_URL + "/Specialities/all/active",
        account,
        inProgress,
        instance
      ).then(res => res.data),
    {
      onSuccess: data => {
        setSpecialities(data)
      },
      onError: _error => {},
    }
  )

  const mutationUpdateSpeciality = useMutation(
    () => {
      return patchApi(
        process.env.GATSBY_API_URL + "/SpecialistEducations",
        {
          id: SelectedSpecialityEducation.id,
          specialityId: selectedSpeciality,
        },
        account,
        inProgress,
        instance
      ).then(res => res.data)
    },
    {
      onSuccess: data => {
        setSpeicialityAdded(true)
        queryClient.invalidateQueries(["userSpecialityEducationsData"])
      },
      onError: _error => {},
      onSettled: () => {
        setShowLoadingData(false)
      },
    }
  )

  const mutationUpdateEducation = useMutation(
    () => {
      return patchApi(
        process.env.GATSBY_API_URL + "/SpecialistEducations",
        {
          id: SelectedSpecialityEducation.id,
          approveStatus: 778380000,
        },
        account,
        inProgress,
        instance
      ).then(res => res.data)
    },
    {
      onSuccess: data => {
        // setShowLoadingData(true)
        mutationCreateIncident.mutate()
      },
      onError: _error => {},
      onSettled: () => {
        // setShowLoadingData(false)
      },
    }
  )

  const mutationCreateIncident = useMutation(
    () => {
      return postApi(
        process.env.GATSBY_API_URL + "/Incidents",
        {
          title: "Godkjenning av spesialistutdanning",
          subjectId: "3ab443be-dbb0-ec11-9840-000d3abf775d", //Godkjenning av spesialistutdanning
          caseTypeCode: 8,
          caseOriginCode: 3,
          status: 1, //Pågår
          state: 0,
          approveStatus: 778380001, //Til godkjenning
          comment: comment,
          educationId: SelectedSpecialityEducation.id,
        },
        account,
        inProgress,
        instance
      ).then(res => res.data)
    },
    {
      onSuccess: data => {
        setSentForApproval(true)
        setShowRequestApproval(false)
        SelectedSpecialityEducation.approveStatusName = "Til godkjenning"
        queryClient.invalidateQueries(["userSpecialityEducationsData"])
      },
      onError: _error => {},
      onSettled: () => {
        setShowLoadingData(false)
      },
    }
  )

  const userProgramsQuery = useQuery(
    "userProgramsData-" + SelectedSpecialityEducation?.id,
    () =>
      fetchApi(
        process.env.GATSBY_API_URL +
          "/SpecialistPrograms/me/" +
          SelectedSpecialityEducation?.id,
        account,
        inProgress,
        instance
      ).then(res => res.data),
    {
      enabled: !!SelectedSpecialityEducation?.id,
      onSuccess: data => {
        setPrograms(data)
        data?.filter(
          x =>
            x.programType?.id === "7a958356-c5a6-ec11-983f-000d3aae55a4" ||
            x.programType?.id === "ee9bb462-c5a6-ec11-983f-000d3aae55a4"
        ) // Fellesprogram OR Obligatorisk program
        if (
          selectedSpeciality != 0 ||
          SelectedSpecialityEducation?.speciality?.id != undefined
        ) {
          if (specialityEducations.length > 1) {
            if (
              data?.length >= 1 &&
              (SelectedSpecialityEducation.approveStatus === 292460000 ||
                SelectedSpecialityEducation.approveStatus === 778380002) &&
              !sentForApproval
            ) {
              setShowRequestApproval(true)
            } else {
              setShowRequestApproval(false)
            }
          } else {
            if (
              data?.length > 1 &&
              (SelectedSpecialityEducation.approveStatus === 292460000 ||
                SelectedSpecialityEducation.approveStatus === 778380002) &&
              !sentForApproval
            ) {
              setShowRequestApproval(true)
            } else {
              setShowRequestApproval(false)
            }
          }
        }
      },
      onError: _error => {},
    }
  )

  // console.log(userProgramsQuery)
  // console.log(selectedSpeciality)
  // console.log(SelectedSpecialityEducation?.speciality?.id)
  // console.log(SelectedSpecialityEducation.approveStatus)
  // console.log(showRequestApproval)
  // console.log(specialityEducations)
  // console.log(specialityEducations.length)
  // console.log(specialityEducations.some(obj => obj.approveStatus === 778380001))

  // useEffect(() => {
  //     const programsForApproval = userProgramsQuery.data?.filter(x => x.programType?.id === "7a958356-c5a6-ec11-983f-000d3aae55a4"
  //         || x.programType?.id === "ee9bb462-c5a6-ec11-983f-000d3aae55a4")  // Fellesprogram OR Obligatorisk program
  //     if (selectedSpeciality != 0 || SelectedSpecialityEducation?.speciality?.id != undefined) {
  //         if (programsForApproval?.length > 1 && (SelectedSpecialityEducation.approveStatus === 292460000 || SelectedSpecialityEducation.approveStatus === 778380002)
  //             && !sentForApproval) setShowRequestApproval(true)
  //     }
  // }, [SelectedSpecialityEducation, selectedSpeciality, showRequestApproval])

  const educationSpecializationActivitiesQuery = useQuery(
    "educationSpecializationActivitiesData-" + SelectedSpecialityEducation?.id,
    () =>
      fetchApi(
        process.env.GATSBY_API_URL +
          "/SpecializationActivities/Education/" +
          SelectedSpecialityEducation?.id,
        account,
        inProgress,
        instance
      ).then(res => res.data),
    {
      enabled: !!SelectedSpecialityEducation?.id,
      onSuccess: data => {},
      onError: _error => {},
    }
  )

  const onApprovalChange = e => {
    if (
      educationSpecializationActivitiesQuery.data?.some(
        x =>
          // x.approveStatus == 778380002 ||
          x.approveStatus == 778380003 || x.approveStatus == 778380001
      )
    ) {
      toast.warning("Alle aktiviteter må være godkjent!", {
        position: "top-center",
        autoClose: 10000,
      })
    } else setShowConfirmation1(true)
  }

  const onConfirmation1 = e => {
    if (e) {
      setShowLoadingData(true)
      mutationUpdateEducation.mutate()
    }
  }

  const onSelectSpeicialityChange = e => setSelectedSpeciality(e)
  const onSelectSpeicialitySave = e => setShowConfirmation2(true)

  const onConfirmation2 = e => {
    if (e) {
      setShowLoadingData(true)
      mutationUpdateSpeciality.mutate()
    }
  }

  const onOpenSendSchema = e => setOpenSendSchema(true)

  return specialitiesQuery.isLoading ? (
    // // "Laster inn..."
    <LoadingData />
  ) : specialitiesQuery.isError ? (
    specialitiesQuery.error.message
  ) : (
    <div>
      <div>{showLoadingData && <LoadingData subject="Oppdaterer..." />}</div>
      <div>
        {showConfirmation1 && (
          <ConfirmationDialog
            onConfirmation={onConfirmation1}
            setShowConfirmation={setShowConfirmation1}
            subject="Bekreft innsending"
            description="Du kan søke programløp godkjent når alle krav til praksis, veiledning, kurs og skriftlig arbeid er oppfylt"
            canCondfirm={true}
            showDesc={false}
          />
        )}
      </div>
      <div>
        {showConfirmation2 && (
          <ConfirmationDialog
            onConfirmation={onConfirmation2}
            setShowConfirmation={setShowConfirmation2}
            subject="Bekreft spesialitet"
            description="OBS! Du får ikke endret spesialitet etter lagring"
            canCondfirm={true}
            showDesc={true}
          />
        )}
      </div>
      <div>
        {SelectedSpecialityEducation.id !== undefined ? (
          <div className="bg-gray-200 border border-gray-200 rounded-lg shadow px-5 py-6 sm:px-6">
            {/* <div className="border border-gray-200 rounded-lg shadow-sm"> */}
            <div className="max-w-7xl max-h-fit mx-auto py-16 px-4 sm:py-8 sm:px-6 lg:px-8 lg:flex lg:justify-between">
              <div className="mt-4">
                <h2 className="text-lg font-medium  sm:text-xl sm:tracking-tight lg:text-xl">
                  Spesialistutdanning{" "}
                  {SelectedSpecialityEducation.speciality?.name}
                </h2>
                <p className="mt-2 text-lg ">
                  <span className="block min-w-0 flex-1">
                    {SelectedSpecialityEducation.approveStatus === 292460000 ? (
                      <span className="inline-flex rounded-full bg-blue-300 px-2 text-xs font-semibold leading-5 text-black">
                        {RemoveUnderScore(
                          SelectedSpecialityEducation.approveStatusName
                        )}
                      </span>
                    ) : null}
                    {SelectedSpecialityEducation.approveStatus === 778380000 ? (
                      <span className="inline-flex rounded-full bg-yellow-300 px-2 text-xs font-semibold leading-5 text-black">
                        {RemoveUnderScore(
                          SelectedSpecialityEducation.approveStatusName
                        )}
                      </span>
                    ) : null}
                    {SelectedSpecialityEducation.approveStatus === 778380001 ? (
                      <span className="inline-flex rounded-full bg-green-300 px-2 text-xs font-semibold leading-5 text-black">
                        {RemoveUnderScore(
                          SelectedSpecialityEducation.approveStatusName
                        )}
                      </span>
                    ) : null}
                    {SelectedSpecialityEducation.approveStatus === 778380002 ? (
                      <span className="inline-flex rounded-full bg-red-300 px-2 text-xs font-semibold leading-5 text-black">
                        {RemoveUnderScore(
                          SelectedSpecialityEducation.approveStatusName
                        )}
                      </span>
                    ) : null}
                  </span>
                  {/* {RemoveUnderScore(SelectedSpecialityEducation.approveStatusName)} */}
                </p>
                <p className="mt-2 text-lg ">
                  Startdato:{" "}
                  {SelectedSpecialityEducation.startDateOfEducation !==
                  undefined ? (
                    <time
                      dateTime={
                        SelectedSpecialityEducation.startDateOfEducation
                      }
                    >
                      {new Intl.DateTimeFormat("nb-NO", {
                        dateStyle: "medium",
                        // timeStyle: "short",
                      }).format(
                        new Date(
                          SelectedSpecialityEducation.startDateOfEducation
                        )
                      )}
                    </time>
                  ) : null}
                </p>
                <p className="mt-2 text-lg ">
                  Godkjent fra:{" "}
                  {SelectedSpecialityEducation.startDateOfEducationApproval !==
                  undefined ? (
                    <time
                      dateTime={
                        SelectedSpecialityEducation.startDateOfEducationApproval
                      }
                    >
                      {new Intl.DateTimeFormat("nb-NO", {
                        dateStyle: "medium",
                        // timeStyle: "short",
                      }).format(
                        new Date(
                          SelectedSpecialityEducation.startDateOfEducationApproval
                        )
                      )}
                    </time>
                  ) : null}
                </p>

                <p className="mt-2 text-lg ">
                  Godkjent til:{" "}
                  {SelectedSpecialityEducation.endDateOfEducationApproval !==
                  undefined ? (
                    <time
                      dateTime={
                        SelectedSpecialityEducation.endDateOfEducationApproval
                      }
                    >
                      {new Intl.DateTimeFormat("nb-NO", {
                        dateStyle: "medium",
                        // timeStyle: "short",
                      }).format(
                        new Date(
                          SelectedSpecialityEducation.endDateOfEducationApproval
                        )
                      )}
                    </time>
                  ) : null}
                </p>
              </div>
              <div className="mt-4">
                <div>
                  {showRequestApproval ? (
                    <div>
                      <div className="mt-1.5 relative">
                        {!openSendSchema ? (
                          <div>
                            <button
                              onClick={e => onOpenSendSchema(e)}
                              type="button"
                              className={
                                cyanButton +
                                " relative w-1/2 focus:z-10 sm:w-auto sm:px-8 "
                              }
                            >
                              Søk spesialitet godkjent
                            </button>
                          </div>
                        ) : (
                          <div>
                            <button
                              onClick={e => onApprovalChange(e)}
                              type="button"
                              className={
                                cyanButton +
                                " relative w-1/2 focus:z-10 sm:w-auto sm:px-8 "
                              }
                            >
                              Send til godkjenning
                            </button>
                            <p className="mt-5 text-lg text-gray-800">
                              Du kan søke spesialitet godkjent når alle krav til
                              praksis, veiledning, kurs og skriftlig arbeid er
                              oppfylt
                            </p>
                            <textarea
                              rows={5}
                              onChange={e => setComment(e.target.value)}
                              name="comment"
                              id="comment"
                              required
                              className="shadow-sm focus:ring-indimenygo-500 focus:border-meny-500 block w-full sm:text-sm border-gray-300 rounded-md"
                              placeholder="Legg til kommentar..."
                              defaultValue={""}
                            />
                          </div>
                        )}
                      </div>
                    </div>
                  ) : null}
                </div>
                <div>
                  {SelectedSpecialityEducation.speciality?.id === undefined &&
                  !speicialityAdded ? (
                    <div className="mt-1">
                      <label
                        htmlFor="title"
                        className="block text-sm font-medium "
                      >
                        Spesialitet
                      </label>
                      <p className="mt-4 text-sm text-gray-800">
                        Du har ikke valgt spesialitet. Klikk her for å velge
                        spesialitet
                      </p>
                      <div className="mt-1.5 relative">
                        <select
                          id="speciality"
                          name="speciality"
                          onChange={e =>
                            onSelectSpeicialityChange(e.target.value)
                          }
                          className="appearance-none block w-full border-gray-300  border-transparent rounded-md pl-3 pr-10 py-2 text-base focus:outline-none focus:ring-meny-700 focus:border-meny-500 sm:text-sm"
                          defaultValue={selectedSpeciality}
                        >
                          <option disabled selected value="0">
                            -- Spesialitet --
                          </option>

                          {specialities?.map(a => (
                            <option key={a.id} value={a.id}>
                              {a.name}
                            </option>
                          ))}
                        </select>
                        <div className="pointer-events-none absolute inset-y-0 right-0 px-2 flex items-center">
                          <ChevronDownIcon
                            className="h-4 w-4 text-white"
                            aria-hidden="true"
                          />
                        </div>
                        <button
                          onClick={e => onSelectSpeicialitySave(e)}
                          type="button"
                          className={
                            cyanButton +
                            " relative mt-2 w-1/2 focus:z-10 sm:w-auto sm:px-8 "
                          }
                        >
                          Lagre
                        </button>
                      </div>
                    </div>
                  ) : null}
                </div>
              </div>
            </div>
            <div className="mt-4 w-full px-4 sm:px-6 lg:px-8">
              {loading ? (
                <SmallListLoading />
              ) : (
                <>
                  {diplomaDocuments.length > 0 && (
                    <>
                      <h2 className="text-lg font-medium  sm:text-xl sm:tracking-tight lg:text-xl">
                        Diplom
                      </h2>
                      <DocumentList
                        documents={diplomaDocuments}
                        mutationGetDocuments={mutate}
                        canDelete={false}
                      />
                    </>
                  )}
                </>
              )}
            </div>
          </div>
        ) : null}
      </div>
      {/* Vises kun når startdato er mer enn 3,5 år tilbake i tid */}
      {showHelpText ? (
        <div className="mt-8 ">
          <GetHelpText number={1003} />
          <LinkText
            link={
              "https://www.psykologforeningen.no/medlem/kurs-og-utdanning/block-forsideblokk-tosaker/spesialistutdanningen2/spesialitetene-i-psykologi/spesialistgodkjenning-og-praksisattest"
            }
            text={
              "https://www.psykologforeningen.no/medlem/kurs-og-utdanning/block-forsideblokk-tosaker/spesialistutdanningen2/spesialitetene-i-psykologi/spesialistgodkjenning-og-praksisattest"
            }
          />
        </div>
      ) : (
        <></>
      )}
    </div>
  )
}

export default EducationInfo
