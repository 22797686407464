import React, { useEffect, useState } from "react"
import ContentBox from "../../../../common/inputFields/ContentBox"
import AddNewEmploymentAccordionContent from "./AddNewEmploymentAccordionContent"
import { useFormContext, useWatch } from "react-hook-form"
import { FormValues } from "./FlowToOrdinaryTypes"

function AddNewEmploymentAccordion() {
  const [, setReRender] = React.useState(false)

  const { setValue, getValues } = useFormContext<FormValues>() // retrieve all hook methods
  const [activeMainEmployments, setActiveMainEmployments] =
    useState<boolean>(false)
  const [showError, setShowError] = useState<boolean>(false)
  const error =
    "Du kan ikke legge til ny før du avslutter eksisterende hovedarbeidsforhold"

  let content = {
    key: "newEmployer",
    header: "Legg til ny arbeidsgiver",
    defaultOpen: true,
  }

  const addNewEmployeeOnClick = () => {
    const isNewEmploymentOld = getValues("isNewEmployment")
    const isNewEmploymentNew = !isNewEmploymentOld

    if (activeMainEmployments === false) {
      setValue("isNewEmployment", isNewEmploymentNew)
      setShowError(false)
    } else {
      setShowError(true)
    }

    if (!isNewEmploymentNew) {
      setValue("newEmployment", undefined)
      setValue("iSNewEmploymentAsEmployee", false)
      setValue("iSNewEmploymentAsIndependent", false)
      setValue("makeIncidentAsEmployee", false)
    }

    // this is to force a re-render of the component
    setReRender(prev => !prev)
  }

  const watchEmployments = useWatch({ name: "employments" })

  // Watch all the employments values and check the action. Show error if there are one or multiple "hovedarbeidsforhold"
  useEffect(() => {
    let hasError = false

    watchEmployments?.forEach(work => {
      if (
        work?.role === 125600000 &&
        (work?.action === "cancel" || work?.action === "keep") &&
        (work.endDate === null || work.endDate === undefined)
      ) {
        hasError = true
        setValue("isNewEmployment", false)
        setValue("iSNewEmploymentAsEmployee", false)
        setValue("iSNewEmploymentAsIndependent", false)
        setValue("makeIncidentAsEmployee", false)
      } else if (work.action === "convert") {
        hasError = true
        setValue("isNewEmployment", false)
        setValue("iSNewEmploymentAsEmployee", false)
        setValue("iSNewEmploymentAsIndependent", false)
        setValue("makeIncidentAsEmployee", false)
      }
    })
    setActiveMainEmployments(hasError)
  }, [watchEmployments])

  // Remove the error message if user goes from having an active "hovedarbeidsforhold" to 0.
  useEffect(() => {
    if (!activeMainEmployments) {
      setShowError(false)
    }
  }, [activeMainEmployments])

  return (
    <div>
      {!getValues("isNewEmployment") && (
        <div>
          <div className="flex w-full justify-end">
            <button
              type="button"
              onClick={addNewEmployeeOnClick}
              className="disabled:opacity-50 disabled:bg-meny-600 ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-meny-700 hover:bg-meny-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-meny-700"
            >
              Legg til ny arbeidsgiver
            </button>
          </div>
          {showError && (
            <div className="flex justify-end mt-2">
              <p className="text-red-600">{error}</p>
            </div>
          )}
        </div>
      )}
      {getValues("isNewEmployment") && (
        <>
          <ContentBox props={content}>
            <div>
              <AddNewEmploymentAccordionContent />
              <button
                type="button"
                onClick={addNewEmployeeOnClick}
                className="disabled:opacity-50 disabled:bg-meny-600 ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-meny-700 hover:bg-meny-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-meny-700"
              >
                Avbryt
              </button>
            </div>
          </ContentBox>
        </>
      )}
    </div>
  )
}

export default AddNewEmploymentAccordion
