import { useAccount, useMsal } from "@azure/msal-react"
import { useQuery } from "react-query"
import { fetchApi } from "../../../Utils/Api.utils"

function useMyEvents() {
  const { instance, accounts, inProgress } = useMsal()
  const account = useAccount(accounts[0] || {})

  const eventQuery = useQuery("myEvents", async () =>
    fetchApi(
      process.env.GATSBY_API_URL + `/Events/MyEvents`,
      account,
      inProgress,
      instance
    ).then(res => res.data)
  )

  return eventQuery
}

export default useMyEvents
