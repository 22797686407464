import React, { useCallback, useEffect, useState } from "react"
import { FormProvider, useFieldArray, useForm, useWatch } from "react-hook-form"
import {
  MyEmployments,
  PatchAndPostEmployment,
} from "../../../../Utils/Entities"
import ModifyEmploymentCards from "./ModifyEmploymentCards"
import { FormValues, MyEmploymentsModify } from "./ModifyAllEmploymentsTypes"
import useNewEmploymentSimple from "../../../hooks/newEmploymentAsEmployee/useNewEmploymentSimple"
import usePatchEmploymentSimple from "../../../hooks/newEmploymentAsIndependent/Simple/usePatchEmploymentSimple"
import { toast } from "react-toastify"
import { Link, navigate } from "gatsby"
import { whiteButton } from "../../../../styles/tailwindClasses"

export type ExistingEmploymentsProps = {
  employments: MyEmployments[]
  refetchEmployment: () => void
  setLoading: React.Dispatch<React.SetStateAction<boolean>>
}

function ModifyAllEmploymentsContent(props: ExistingEmploymentsProps) {
  const employmentData = props.employments
    ?.filter(isEmploymentOngoing)
    .filter(isValidEmployment)
    .sort(sortEmployments)
    .map(item => ({
      ...item,
      action: item.endDate ? "cancel" : "keep",
    })) as MyEmploymentsModify[]

  const formController = useForm<FormValues>({
    defaultValues: {
      employments: employmentData,
    },
  })

  useEffect(() => {
    formController.setValue("employments", employmentData)
  }, [props.employments])

  const fieldController = useFieldArray({
    control: formController.control,
    name: "employments",
  })

  const watchForm = useWatch({
    control: formController.control,
    name: "employments",
  })

  const mutationNewEmployment = useNewEmploymentSimple()

  const mutationPatchEmployment = usePatchEmploymentSimple()

  const onSubmit = async (data: FormValues) => {
    props.setLoading(true) // Set loading state to true

    try {
      // Update existing employments
      const mutationPromises = data.employments.map(async work => {
        let body: Partial<MyEmployments> = {}
        let modifyBody: Partial<PatchAndPostEmployment> = {}

        switch (work.action) {
          case "keep":
            // Nothing to do
            return null
          case "modify":
            // Modify and set new position, positionPercentage, and startDate on existing employment
            const startDate: Date = new Date(work.endDate!)
            startDate.setDate(startDate.getDate() + 1)
            const {
              action,
              employeeName,
              workplaceName,
              endDate,
              id,
              ...originalEmploymentWithoutExtras
            } = work
            modifyBody = {
              newEmployment: {
                ...originalEmploymentWithoutExtras,
                position: work.position,
                partTimePosition: work.partTimePosition,
                startDate: startDate,
              },
              prevEmployment: {
                id: work.id,
                endDate: work.endDate,
              },
            }

            await mutationNewEmployment.mutateAsync(modifyBody.newEmployment)
            await mutationPatchEmployment.mutateAsync(modifyBody.prevEmployment)
            break
          default:
            // Nothing to do
            return null
        }

        return body
      })

      // Wait for all mutations to complete
      await Promise.all(mutationPromises)

      // Refetch employment data after all mutations are done
      await props.refetchEmployment()
    } catch (error) {
      toast.error("Det har skjedd en feil, prøv igjen senere", {
        position: "top-center",
        autoClose: 6000,
      })
    } finally {
      props.setLoading(false) // Set loading state to false
    }
  }

  return (
    <div>
      <div className="max-w-5xl mx-auto divide-y-2 divide-gray-200">
        <FormProvider {...formController}>
          <form onSubmit={formController.handleSubmit(onSubmit)}>
            {fieldController.fields?.map((work: MyEmployments, index) => {
              return (
                <div key={work?.id}>
                  <ModifyEmploymentCards
                    work={work}
                    index={index}
                    formController={formController}
                  />
                </div>
              )
            })}
            <div className="pt-5 flex justify-end py-4">
              <button
                onClick={() => navigate(-1)}
                className={
                  whiteButton +
                  " inline-flex items-center justify-center focus:ring-offset-2 sm:w-auto "
                }
              >
                Tilbake
              </button>
              <button
                type="submit"
                className="disabled:opacity-50 disabled:bg-meny-600 ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-meny-700 hover:bg-meny-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-meny-700"
              >
                Lagre
              </button>
            </div>
          </form>
        </FormProvider>
      </div>
    </div>
  )
}

const isValidEmployment = (work: MyEmployments) => {
  const isEmployerDefined =
    work.employer !== undefined || work.employerAlternative !== undefined

  return isEmployerDefined
}

const isEmploymentOngoing = (work: MyEmployments) => {
  const today = new Date()
  if (work?.endDate != undefined) {
    const endDate = new Date(work?.endDate)
    if (endDate.getTime() < today.getTime()) return false
  }
  return true
}

const sortEmployments = (a, b) => {
  const rolePriority = {
    125600000: 1, // highest priority
    125600001: 2, // second highest priority
  }

  const priorityA = rolePriority[a.role] || 3 // default priority if not found
  const priorityB = rolePriority[b.role] || 3 // default priority if not found

  // First, sort by role priority
  if (priorityA !== priorityB) {
    return priorityA - priorityB
  }

  // If roles are the same, sort by date
  // If both a and b have no endDate, sort by startDate
  if (!a.endDate && !b.endDate) {
    return (
      new Date(b.startDate || "").getTime() -
      new Date(a.startDate || "").getTime()
    )
  }

  // If only a has no endDate, a should come first
  if (!a.endDate) {
    return -1
  }

  // If only b has no endDate, b should come first
  if (!b.endDate) {
    return 1
  }

  // If both a and b have an endDate, sort by startDate
  return (
    new Date(b.startDate || "").getTime() -
    new Date(a.startDate || "").getTime()
  )
}

export default ModifyAllEmploymentsContent
