import { useAccount, useMsal } from "@azure/msal-react"
import { ErrorMessage } from "@hookform/error-message"
import nb from "date-fns/locale/nb"
import { navigate } from "gatsby"
import React, { useContext, useEffect, useState } from "react"
import ReactDatePicker, { registerLocale } from "react-datepicker"
import "react-datepicker/dist/react-datepicker.css"
import { Controller, useFormContext, useWatch } from "react-hook-form"
import { useQuery } from "react-query"
import Select from "react-select"
import { fetchApi } from "../../../../../Utils/Api.utils"
import {
  Agreementspecialist,
  CheckForClient,
  TypePrivatePractitioner,
  WorkCategory,
} from "../../../../../Utils/optionSet"
import useGetEmployers from "../../../../hooks/employers/useGetEmployers"
import useMyEvents from "../../../../hooks/events/useMyEvents"
import { FormValues } from "./FlowToOrdinaryTypes"
import { EmploymentContext } from "../../../../hooks/useContext/employmentContext"
registerLocale("nb", nb)

// type FormValues = Employment

export const NewEmploymentAsIndependent = () => {
  const {
    register,
    watch,
    getValues,
    setValue,
    control,
    formState: { errors },
  } = useFormContext<FormValues>()

  const watchEmployments = useWatch({ name: "employments" })

  const employeeAsIndependentProps = getValues(
    "newEmploymentAsIndependentProps"
  )

  const [parent, setParent] = useState<string>()
  const [position, setPosition] = useState<string>()
  const [positions, setPositions] = useState<any[]>()

  const [employerAndWorkplace, setEmployerAndWorkplace] =
    useState<boolean>(false)

  const [workcategory, setWorkcategory] = useState<number>(0)
  const [typePrivatePractitioner, setTypePrivatePractitioner] =
    useState<number>(0)
  const [agreementspecialist, setAgreementspecialist] = useState<number | null>(
    null
  )
  const [accountWithOrgNumber, setAccountWithOrgNumber] = useState<any>("")
  const [accountWithOrgNumberExist, setAccountWithOrgNumberExist] =
    useState<boolean>(false)
  const [clientAccountWithOrgNumber, setClientAccountWithOrgNumber] =
    useState<any>("")
  const [showEmpAlt, setShowEmpAlt] = useState<boolean>(false)
  const [employerError, setEmployerError] = useState<string>("")

  const [orgNumber, setOrgNumber] = useState<string>("")
  const [zipCode, setZipCode] = useState<string>("")
  const [zipCodeData, setZipCodeData] = useState<any>("")
  const [zipCodeError, setZipCodeError] = useState<boolean>(false)

  const [selectedClientAccount, setSelectedClientAccount] = useState<any>()
  const [worksForClient, setWorksForClient] = useState<string>("")

  const { instance, accounts, inProgress } = useMsal()
  const account = useAccount(accounts[0] || {})
  const [accountName, setAccountName] = useState<string>(
    account?.name + ", Privatpraksis"
  )

  const [clientAccountName, setClientAccountName] = useState<string>("")
  const [clientAccountAddress, setClientAccountAddress] = useState<string>("")
  const [clientAccountZipCode, setClientAccountZipCode] = useState<string>("")
  const [clientAccountZipCodeData, setClientAccountZipCodeData] =
    useState<any>("")
  const [clientAccountZipCodeError, setClientAccountZipCodeError] =
    useState<boolean>(false)
  const [clientOrgNumber, setClientOrgNumber] = useState<string>("")
  const [confirmClient, setConfirmClient] = useState<boolean>(false)

  const startDateLimit = getValues("employments")?.find(work => {
    return work.role === 125600000 && work.action === "cancel"
  })?.endDate

  const [startDate, setStartDate] = useState<Date>(() => {
    const endDate = new Date(startDateLimit || new Date())
    endDate.setDate(endDate.getDate() + 1)
    return endDate
  })

  const maxDate = new Date(
    startDate.getFullYear(),
    startDate.getMonth() + 4,
    startDate.getDate()
  )

  useEffect(() => {
    const limit = getValues("employments")?.find(work => {
      return work.role === 125600000 && work.action === "cancel"
    })?.endDate

    if (limit) {
      const startDateLimit = new Date(limit)
      startDateLimit.setDate(startDateLimit.getDate() + 1)
      setStartDate(startDateLimit)
    }
  }, [watchEmployments])

  // Set the predifined values for the form if workcategory === 292460001 && typePrivatePractitioner === 292460000
  useEffect(() => {
    if (workcategory === 292460001 && typePrivatePractitioner === 292460000) {
      setAccountName(account?.name + ", Privatpraksis")
    } else {
      setAccountName("") // Reset
    }
  }, [workcategory, typePrivatePractitioner])

  const eventQuery = useMyEvents()

  const updateEmployer = event => {
    if (event.target.value != "") {
      fetchApi(
        `${process.env.GATSBY_API_URL}/Accounts/${event.target.value}`,
        account,
        inProgress,
        instance
      ).then(response => {
        if (response.data.parentEmployerId != undefined) {
          setParent(response.data.parentEmployerId)
        } else {
          setParent("")
        }
      })
    }
  }

  // Search for "Organisasjonsnr"
  const findOrgNumber = e => {
    setEmployerAndWorkplace(false)
    setOrgNumber(e.target.value)
    if (e.target.value.length < 9) {
      setAccountWithOrgNumber("")
      setShowEmpAlt(false)
      setClientAccountWithOrgNumber("")
      setZipCode("")
      setZipCodeData("")
      setZipCodeError(false)
      setWorksForClient("")
      setAgreementspecialist(null)
    }
  }

  const CheckOrgNumber = useQuery(
    "CheckOrgNumber",
    async () =>
      fetchApi(
        process.env.GATSBY_API_URL +
          `/Accounts/AccountNumberSearch/` +
          orgNumber,
        account,
        inProgress,
        instance
      ).then(res => res.data),
    {
      enabled: orgNumber.length === 9,
      onSuccess: data => {
        setAccountWithOrgNumber(data)
        if (data === null || data === "") {
          setAccountWithOrgNumberExist(false)
        } else {
          setAccountWithOrgNumberExist(true)
        }
      },
      onError: error => {},
    }
  )

  const CheckClientOrgNumber = useQuery(
    "CheckCleintOrgNumber",
    async () =>
      fetchApi(
        process.env.GATSBY_API_URL +
          `/Accounts/AccountNumberSearch/` +
          clientOrgNumber,
        account,
        inProgress,
        instance
      ).then(res => res.data),
    {
      enabled: clientOrgNumber.length === 9,
      onSuccess: data => {
        setClientAccountWithOrgNumber(data)
      },
    }
  )

  // Search for "Oppdragsgiver" with "Organisasjonsnr"
  const findClientOrgNumber = e => {
    setClientOrgNumber(e.target.value)
    if (e.target.value.length < 9) {
      setClientAccountWithOrgNumber("")
    }
  }

  const PostalCodes = useQuery(
    "CheckPostalCodes",
    async () =>
      fetchApi(
        process.env.GATSBY_API_URL + `/Addresses/PostalCodes/` + zipCode,
        account,
        inProgress,
        instance
      ).then(res => res.data),
    {
      enabled: zipCode.length === 4,
      retry: 0,
      onSuccess: data => {
        setZipCodeData(data)
        setZipCodeError(false)
      },
      onError: error => {
        setZipCodeError(true)
      },
    }
  )

  // Search for "ZipCode"
  const findZipCode = e => {
    setZipCode(e.target.value)
    if (e.target.value.length < 4) {
      setZipCodeData("")
      setZipCodeError(false)
    }
  }

  const ClientPostalCode = useQuery(
    "CheckClientPostalCodes",
    async () =>
      fetchApi(
        process.env.GATSBY_API_URL +
          `/Addresses/PostalCodes/` +
          clientAccountZipCode,
        account,
        inProgress,
        instance
      ).then(res => res.data),
    {
      enabled: clientAccountZipCode.length === 4,
      retry: 0,
      onSuccess: data => {
        setClientAccountZipCodeData(data)
        setClientAccountZipCodeError(false)
      },
      onError: error => {
        setClientAccountZipCodeError(true)
      },
    }
  )

  // Search for "ClientAccountZipCode"
  const findClientZipCode = e => {
    setClientAccountZipCode(e.target.value)
    if (e.target.value.length < 4) {
      setClientAccountZipCodeData("")
      setClientAccountZipCodeError(false)
    }
  }

  const employersQuery = useGetEmployers()

  const accountData = employersQuery.data

  const updateWorkPlace = selectedOption => {
    setParent(selectedOption.value)
    if (selectedOption.value != "") {
      fetchApi(
        `${process.env.GATSBY_API_URL}/Accounts/${selectedOption.value}`,
        account,
        inProgress,
        instance
      ).then(response => {
        setSelectedClientAccount(response.data)
      })
    }
  }

  useEffect(() => {
    setValue(
      "newEmploymentAsIndependentProps.clientAccountWithOrgNumber",
      clientAccountWithOrgNumber
    )
    setValue(
      "incidentDescriptionForIndependent.clientOrgNumber",
      clientOrgNumber
    )
    setValue(
      "incidentDescriptionForIndependent.clientAccountName",
      clientAccountName
    )
    setValue(
      "incidentDescriptionForIndependent.clientAccountAddress",
      clientAccountAddress
    )
    setValue(
      "incidentDescriptionForIndependent.clientAccountZipCode",
      clientAccountZipCode
    )
    setValue(
      "newEmploymentAsIndependentProps.employerAndWorkplace",
      employerAndWorkplace
    )
    setValue("newEmploymentAsIndependentProps.showEmpAlt", showEmpAlt)
    setValue("newEmploymentAsIndependentProps.worksForClient", worksForClient)
    setValue("newEmploymentAsIndependentProps.employerError", employerError)
    setValue(
      "newEmploymentAsIndependentProps.selectedClientAccount",
      selectedClientAccount
    )
    setValue("newEmploymentAsIndependentProps.workcategory", workcategory)
    setValue(
      "newEmploymentAsIndependentProps.typePrivatePractitioner",
      typePrivatePractitioner
    )
    setValue(
      "newEmploymentAsIndependentProps.accountWithOrgNumber",
      accountWithOrgNumber
    )
    setValue("newEmploymentAsIndependentProps.accountName", accountName)
    setValue("newEmploymentAsIndependentProps.orgNumber", orgNumber)
    setValue("newEmploymentAsIndependentProps.zipCodeData", zipCodeData)
    setValue(
      "newEmploymentAsIndependentProps.agreementspecialist",
      agreementspecialist
    )
    setValue(
      "newEmploymentAsIndependentProps.accountWithOrgNumberExist",
      accountWithOrgNumberExist
    )
  }, [
    clientAccountWithOrgNumber,
    clientOrgNumber,
    clientAccountName,
    clientAccountAddress,
    clientAccountZipCode,
    employerAndWorkplace,
    showEmpAlt,
    worksForClient,
    employerError,
    selectedClientAccount,
    workcategory,
    typePrivatePractitioner,
    accountWithOrgNumber,
    account,
    orgNumber,
    zipCodeData,
    agreementspecialist,
    accountWithOrgNumberExist,
  ])

  useEffect(() => {
    if (getValues("newEmploymentAsIndependentProps.employerError") !== "") {
      setEmployerError("Du må velge en oppdragsgiver")
    } else {
      setEmployerError("")
    }
  }, [watch("newEmploymentAsIndependentProps.employerError", employerError)])

  const onClickEmployerNotInList = () => {
    if (showEmpAlt === true) {
      setEmployerError("")
      setShowEmpAlt(false)
      setClientAccountWithOrgNumber("")
    } else {
      setEmployerError("")
      setShowEmpAlt(true)
    }
  }

  const onClickAgreementspecialist = e => {
    setAgreementspecialist(parseInt(e.target.value))
    setWorksForClient("")
  }

  const onClickWorksForClient = e => {
    setWorksForClient(e.target.value)
    setEmployerAndWorkplace(false)
  }

  const changePos = event => {
    setPosition(event.target.value)
  }

  // Set positions based on the selected typePrivatePractitioner and if user have searched for an account with orgNumber
  // Set position based orgNumber if it exists
  // Set position based on typePrivatePractitioner if orgNumber does not exist
  useEffect(() => {
    if (
      accountWithOrgNumber !== "" &&
      accountWithOrgNumber !== null &&
      orgNumber.length === 9
    ) {
      fetchApi(
        `${process.env.GATSBY_API_URL}/Accounts/${accountWithOrgNumber.id}`,
        account,
        inProgress,
        instance
      ).then(response => {
        if (response.data.tariff.positions !== undefined) {
          setPositions(response.data.tariff.positions)
        } else {
          setPositions([])
        }
      })
    } else if (
      typePrivatePractitioner === 292460000 &&
      orgNumber.length === 9
    ) {
      fetchApi(
        `${process.env.GATSBY_API_URL}/Tariff/${process.env.GATSBY_TARIFF_INDEPENDENT}`,
        account,
        inProgress,
        instance
      ).then(response => {
        setPositions(response.data.positions)
      })
    } else if (
      typePrivatePractitioner === 292460001 &&
      orgNumber.length === 9
    ) {
      fetchApi(
        `${process.env.GATSBY_API_URL}/Tariff/${process.env.GATSBY_TARIFF_IN_AS}`,
        account,
        inProgress,
        instance
      ).then(response => {
        setPositions(response.data.positions)
      })
    } else {
      setPositions([])
      setPosition("")
    }
  }, [accountWithOrgNumber, typePrivatePractitioner, orgNumber])

  return (
    <div>
      <div className="mb-12">
        <div className="space-y-8 sm:space-y-5">
          <div className="space-y-6sm:space-y-5">
            <div className="space-y-6 sm:space-y-5">
              <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:pt-5">
                <label
                  htmlFor="role"
                  className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                >
                  Rolle:
                </label>
                <div className="mt-1 sm:mt-0 sm:col-span-2">
                  <select
                    {...register("newEmploymentAsIndependent.employment.role")}
                    name="newEmploymentAsIndependent.employment.role"
                    id="newEmploymentAsIndependent.employment.role"
                    key="newEmploymentAsIndependent.employment.role"
                    disabled={true}
                    className=" disabled:bg-gray-200 block w-full shadow-sm focus:ring-meny-700 focus:border-meny-500 sm:text-sm border-gray-300 rounded-md"
                  >
                    <option value={125600000}>Hovedarbeidsgiver</option>
                  </select>
                </div>
              </div>
              <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:pt-5">
                <label
                  htmlFor="workcategory"
                  className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                >
                  Er du privatpraktiserende med klinisk praksis, eller driver du
                  som selvstendig næringsdrivende uten klinisk praksis:
                </label>
                <div className="mt-1 sm:mt-0 sm:col-span-2">
                  <select
                    {...register(
                      "newEmploymentAsIndependent.employment.workcategory",
                      {
                        required: true,
                      }
                    )}
                    name="newEmploymentAsIndependent.employment.workcategory"
                    id="newEmploymentAsIndependent.employment.workcategory"
                    key="newEmploymentAsIndependent.employment.workcategory"
                    defaultValue={""}
                    onChange={e => {
                      setWorkcategory(parseInt(e.target.value))
                      setClientAccountWithOrgNumber("")
                      setShowEmpAlt(false)
                    }}
                    className=" disabled:bg-gray-200 disabled:text-opacity-50 block w-full shadow-sm focus:ring-meny-700 focus:border-meny-500 sm:text-sm border-gray-300 rounded-md"
                  >
                    <option value="" disabled>
                      -- Velg en type --
                    </option>
                    {WorkCategory?.map(p => (
                      <option key={p.id} value={p.id}>
                        {p.title}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
              {/* Choose a workcategory */}
              {workcategory !== 0 && (
                <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:pt-5">
                  <label
                    htmlFor="typePrivatePractitioner"
                    className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                  >
                    {workcategory === 292460001 &&
                      "Driver du privatpraksis som enkeltpersonforetak eller som ansatt i eget AS:"}
                    {workcategory === 292460002 &&
                      "Driver du et enkeltpersonforetak eller som ansatt i eget AS:"}
                  </label>
                  <div className="mt-1 sm:mt-0 sm:col-span-2">
                    <select
                      {...register(
                        "newEmploymentAsIndependent.employment.typePrivatePractitioner",
                        {
                          required: true,
                        }
                      )}
                      name="newEmploymentAsIndependent.employment.typePrivatePractitioner"
                      id="newEmploymentAsIndependent.employment.typePrivatePractitioner"
                      key="newEmploymentAsIndependent.employment.typePrivatePractitioner"
                      defaultValue={""}
                      onChange={e => {
                        setTypePrivatePractitioner(parseInt(e.target.value))
                        setClientAccountWithOrgNumber("")
                        setShowEmpAlt(false)
                      }}
                      className=" disabled:bg-gray-200 disabled:text-opacity-50 block w-full shadow-sm focus:ring-meny-700 focus:border-meny-500 sm:text-sm border-gray-300 rounded-md"
                    >
                      <option value="" disabled>
                        -- Velg en type --
                      </option>
                      {TypePrivatePractitioner?.map(p => (
                        <option key={p.id} value={p.id}>
                          {p.title}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
              )}
              {/* Choose typePrivatePractitioner */}
              {typePrivatePractitioner !== 0 && (
                <>
                  <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:pt-5">
                    <label
                      htmlFor="orgNumber"
                      className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                    >
                      Organisasjonsnr:
                    </label>
                    <div className="mt-1 sm:mt-0 sm:col-span-2">
                      <input
                        required
                        type="text"
                        onChange={findOrgNumber}
                        className="block w-full shadow-sm focus:ring-meny-700 focus:border-meny-500 sm:text-sm border-gray-300 rounded-md"
                        maxLength={9}
                        minLength={9}
                      />
                    </div>
                  </div>
                  {/* show if accountWithOrgNumber is not null or "" */}
                  {accountWithOrgNumber === "" ||
                  accountWithOrgNumber === null ? null : (
                    <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:pt-5">
                      <label
                        htmlFor="Organisasjonsnr er koblet mot"
                        className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                      >
                        Organisasjonsnr er koblet mot:
                      </label>
                      <div className="mt-1 pt-2 sm:mt-0 sm:col-span-2">
                        <p>{accountWithOrgNumber?.name}</p>
                      </div>
                    </div>
                  )}
                  {/* if accountWithOrgNumber is null, show message */}
                  {accountWithOrgNumber === null ? (
                    <p className="block text-sm italic font-normal sm:col-span-2 text-meny-700 sm:mt-px sm:pt-2 sm:col-start-3">
                      {workcategory == 292460001 &&
                      typePrivatePractitioner == 292460000
                        ? "Ditt enkeltpersonsforetak"
                        : "Din virksomhet"}{" "}
                      er ikke registrert hos oss, og vil bli automatisk
                      opprettet når du har oppgitt nok informasjon.
                    </p>
                  ) : null}
                </>
              )}
              {/* Register new Account if accountWithOrgNumber is null */}
              {accountWithOrgNumber === null && (
                <>
                  {workcategory === 292460001 &&
                    typePrivatePractitioner === 292460000 && (
                      <div
                        className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start  sm:pt-5"
                        key={0}
                        id="0"
                      >
                        <label
                          htmlFor="account.name"
                          className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                        >
                          Firmanavn:
                        </label>
                        <div className="mt-1 sm:mt-0 sm:col-span-2">
                          <input
                            type="text"
                            {...register(
                              "newEmploymentAsIndependent.account.name"
                            )}
                            value={accountName}
                            name="newEmploymentAsIndependent.account.name"
                            id="newEmploymentAsIndependent.account.name"
                            disabled
                            className=" disabled:bg-gray-200 block w-full shadow-sm focus:ring-meny-700 focus:border-meny-500 sm:text-sm border-gray-300 rounded-md"
                          />
                        </div>
                      </div>
                    )}
                  {((workcategory === 292460001 &&
                    typePrivatePractitioner === 292460001) ||
                    workcategory === 292460002) && (
                    <div
                      className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start  sm:pt-5"
                      key={1}
                      id="1"
                    >
                      <label
                        htmlFor="account.name"
                        className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                      >
                        Navn fra Brønnøysundregistrene:
                      </label>
                      <div className="mt-1 sm:mt-0 sm:col-span-2">
                        <input
                          type="text"
                          {...register(
                            "newEmploymentAsIndependent.account.name"
                          )}
                          name="newEmploymentAsIndependent.account.name"
                          id="newEmploymentAsIndependent.account.name"
                          required
                          className=" block w-full shadow-sm focus:ring-meny-700 focus:border-meny-500 sm:text-sm border-gray-300 rounded-md"
                        />
                      </div>
                    </div>
                  )}
                  <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start  sm:pt-5">
                    <label
                      htmlFor="orgNumber"
                      className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                    >
                      Organisasjonsnr:
                    </label>
                    <div className="mt-1 sm:mt-0 sm:col-span-2">
                      <input
                        type="text"
                        value={orgNumber}
                        disabled
                        className="disabled:bg-gray-200 block w-full shadow-sm focus:ring-meny-700 focus:border-meny-500 sm:text-sm border-gray-300 rounded-md"
                      />
                    </div>
                  </div>
                  <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start  sm:pt-5">
                    <label
                      htmlFor="account.address1_Line1"
                      className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                    >
                      Adresse:
                    </label>
                    <div className="mt-1 sm:mt-0 sm:col-span-2">
                      <input
                        type="text"
                        {...register(
                          "newEmploymentAsIndependent.account.address1_Line1"
                        )}
                        required
                        name="newEmploymentAsIndependent.account.address1_Line1"
                        id="newEmploymentAsIndependent.account.address1_Line1"
                        className="disabled:bg-gray-200 block w-full shadow-sm focus:ring-meny-700 focus:border-meny-500 sm:text-sm border-gray-300 rounded-md"
                      />
                    </div>
                  </div>
                  <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start  sm:pt-5">
                    <label
                      htmlFor="account.zipCodeId"
                      className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                    >
                      Postnummer:
                    </label>
                    <div className="mt-1 sm:mt-0 sm:col-span-2 flex flex-col space-y-2">
                      <input
                        type="text"
                        {...register(
                          "newEmploymentAsIndependent.account.zipCodeId"
                        )}
                        required
                        name="newEmploymentAsIndependent.account.zipCodeId"
                        id="newEmploymentAsIndependent.account.zipCodeId"
                        maxLength={4}
                        minLength={4}
                        onChange={findZipCode}
                        className=" block w-full shadow-sm focus:ring-meny-700 focus:border-meny-500 sm:text-sm border-gray-300 rounded-md"
                      />
                      {zipCodeError && (
                        <p className="text-red-500">Ugyldig postnummer</p>
                      )}
                      {zipCodeData !== "" && (
                        <div className=" ml-2 mt-1 ">
                          <p>{zipCodeData?.postalPlace}</p>
                        </div>
                      )}
                    </div>
                  </div>
                </>
              )}
              {/* if "Klinisk praksis" is selected and orgNumber registered. Ask for "Avtalespesialist" */}
              {orgNumber.length === 9 && workcategory === 292460001 && (
                <>
                  <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:pt-5">
                    <label
                      htmlFor="employment.agreementspecialist"
                      className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                    >
                      Er du avtalespesialist:
                    </label>
                    <div className="mt-1 sm:mt-0 sm:col-span-2">
                      <select
                        {...register(
                          "newEmploymentAsIndependent.employment.agreementspecialist",
                          {
                            required: true,
                            valueAsNumber: true,
                          }
                        )}
                        name="newEmploymentAsIndependent.employment.agreementspecialist"
                        id="newEmploymentAsIndependent.employment.agreementspecialist"
                        key="newEmploymentAsIndependent.employment.agreementspecialist"
                        defaultValue={""}
                        onChange={onClickAgreementspecialist}
                        className=" disabled:bg-gray-200 disabled:text-opacity-50 block w-full shadow-sm focus:ring-meny-700 focus:border-meny-500 sm:text-sm border-gray-300 rounded-md"
                      >
                        <option value="" disabled>
                          -- Velg en --
                        </option>
                        {Agreementspecialist?.map(p => (
                          <option key={p.id} value={p.id}>
                            {p.title}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>
                  {orgNumber.length === 9 && agreementspecialist === 1 && (
                    <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start  sm:pt-5">
                      <label
                        htmlFor="employment.operatingGrants"
                        className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                      >
                        Oppgi driftstilskudd i % (oppgi 0 hvis du ikke har
                        driftstilskudd):
                      </label>
                      <div className="mt-1 sm:mt-0 sm:col-span-2">
                        <input
                          type="number"
                          {...register(
                            "newEmploymentAsIndependent.employment.operatingGrants",
                            {
                              required: true,
                              valueAsNumber: true,
                            }
                          )}
                          max={100}
                          name="newEmploymentAsIndependent.employment.operatingGrants"
                          id="newEmploymentAsIndependent.employment.operatingGrants"
                          className="block w-full shadow-sm focus:ring-meny-700 focus:border-meny-500 sm:text-sm border-gray-300 rounded-md"
                        />
                      </div>
                    </div>
                  )}
                </>
              )}
              {orgNumber.length === 9 && (
                <>
                  {/* If avtalespesialist is "Nei" */}
                  {agreementspecialist === 0 && (
                    <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:pt-5">
                      <label
                        htmlFor="worksForClient"
                        className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                      >
                        Jobber du for en oppdragsgiver?:
                      </label>
                      <div className="mt-1 sm:mt-0 sm:col-span-2">
                        <select
                          required
                          defaultValue={""}
                          onChange={onClickWorksForClient}
                          className=" disabled:bg-gray-200 disabled:text-opacity-50 block w-full shadow-sm focus:ring-meny-700 focus:border-meny-500 sm:text-sm border-gray-300 rounded-md"
                        >
                          <option value="" disabled>
                            -- Velg en --
                          </option>
                          {CheckForClient?.map(p => (
                            <option key={p.id} value={p.id}>
                              {p.title}
                            </option>
                          ))}
                        </select>
                      </div>
                    </div>
                  )}

                  {worksForClient === "Yes" && agreementspecialist === 0 && (
                    <>
                      <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:pt-5">
                        <label
                          htmlFor="employment.clientId"
                          className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                        >
                          Din oppdragsgiver:
                        </label>
                        <div className="mt-1 sm:mt-0 sm:col-span-2">
                          <Select
                            name="newEmploymentAsIndependent.employment.clientId"
                            id="newEmploymentAsIndependent.employment.clientId"
                            key="newEmploymentAsIndependent.employment.clientId"
                            isSearchable
                            options={accountData?.map(a => ({
                              value: a.id,
                              label: a.name,
                            }))}
                            isDisabled={showEmpAlt}
                            placeholder="-- Oppdragsgivere --"
                            value={
                              parent === null || parent === undefined
                                ? null
                                : accountData?.find(
                                    option => option.name === parent
                                  )
                            }
                            onChange={option => {
                              updateWorkPlace(option)
                              setEmployerAndWorkplace(true)
                              setEmployerError("")
                            }}
                            className=" block w-full shadow-sm focus:ring-meny-700 focus:border-meny-500 sm:text-sm border-gray-300 rounded-md"
                          ></Select>
                        </div>
                      </div>
                      {employerError !== "" && (
                        <p className="text-red-500">{employerError}</p>
                      )}
                      {/* If workplace is not in the list */}
                      <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:pt-5">
                        <label
                          htmlFor="checkbox"
                          className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                        >
                          Min oppdragsgiver er ikke i listen:
                        </label>
                        <div className="pt-4 sm:pt-2.5 h-5 w-5">
                          <input
                            type="checkbox"
                            checked={showEmpAlt}
                            onChange={onClickEmployerNotInList}
                            className="block w-full shadow-sm focus:ring-meny-700 focus:border-meny-500 sm:text-sm border-gray-300 rounded-md"
                          />
                        </div>
                      </div>
                      {/* Search with orgnr */}
                      {showEmpAlt && (
                        <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:pt-5">
                          <label
                            htmlFor="accountNumber"
                            className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                          >
                            Organisasjonsnr for oppdragsgiver:
                          </label>
                          <div className="mt-1 sm:mt-0 sm:col-span-2">
                            <input
                              required
                              type="text"
                              onChange={findClientOrgNumber}
                              className="block w-full shadow-sm focus:ring-meny-700 focus:border-meny-500 sm:text-sm border-gray-300 rounded-md"
                              maxLength={9}
                              minLength={9}
                            />
                          </div>
                        </div>
                      )}
                      {/* show if clientAccountWithOrgNumber is not null or "" */}
                      {clientAccountWithOrgNumber === "" ||
                      clientAccountWithOrgNumber === null ? null : (
                        <>
                          <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:pt-5">
                            <label
                              htmlFor="Organisasjonsnr er koblet mot"
                              className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                            >
                              Oppdragsgiver:
                            </label>
                            <div className="mt-1 pt-2 sm:mt-0 sm:col-span-2">
                              <p>{clientAccountWithOrgNumber?.name}</p>
                            </div>
                          </div>
                          <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:pt-5">
                            <label
                              htmlFor="checkbox"
                              className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                            >
                              Bekreft oppdragsgiver:
                            </label>
                            <div className="pt-4 sm:pt-2.5 h-5 w-5">
                              <input
                                type="checkbox"
                                required
                                onChange={() =>
                                  setConfirmClient(!confirmClient)
                                }
                                className="block w-full shadow-sm focus:ring-meny-700 focus:border-meny-500 sm:text-sm border-gray-300 rounded-md"
                              />
                            </div>
                          </div>
                        </>
                      )}
                      {/* if clientAccountWithOrgNumber is null, show message */}
                      {clientAccountWithOrgNumber === null && (
                        <div>
                          <p className="block text-sm italic font-normal sm:col-span-2 text-meny-700 sm:mt-px sm:pt-2 sm:col-start-3">
                            Vi har ikke registrert denne virksomheten i vårt
                            system. Dersom du går videre så vil det opprettes en
                            sak hos oss for å ferdigstille registrering
                          </p>
                          <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:pt-5">
                            <label
                              htmlFor="clientaccountname"
                              className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                            >
                              Oppdragsgiver navn:
                            </label>
                            <div className="mt-1 sm:mt-0 sm:col-span-2">
                              <input
                                required
                                type="text"
                                onChange={e => {
                                  setClientAccountName(e.target.value)
                                }}
                                className="block w-full shadow-sm focus:ring-meny-700 focus:border-meny-500 sm:text-sm border-gray-300 rounded-md"
                              />
                            </div>
                          </div>
                          <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:pt-5">
                            <label
                              htmlFor="clientaccountaddress"
                              className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                            >
                              Oppdragsgiver adresse:
                            </label>
                            <div className="mt-1 sm:mt-0 sm:col-span-2">
                              <input
                                required
                                type="text"
                                onChange={e => {
                                  setClientAccountAddress(e.target.value)
                                }}
                                className="block w-full shadow-sm focus:ring-meny-700 focus:border-meny-500 sm:text-sm border-gray-300 rounded-md"
                              />
                            </div>
                          </div>
                          <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:pt-5">
                            <label
                              htmlFor="clientaccountpostalcode"
                              className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                            >
                              Oppdragsgiver postnummer:
                            </label>
                            <div className="mt-1 sm:mt-0 sm:col-span-2">
                              <input
                                required
                                type="text"
                                onChange={findClientZipCode}
                                className="block w-full shadow-sm focus:ring-meny-700 focus:border-meny-500 sm:text-sm border-gray-300 rounded-md"
                                maxLength={4}
                                minLength={4}
                              />
                              {clientAccountZipCodeError && (
                                <p className="text-red-500">
                                  Ugyldig postnummer
                                </p>
                              )}
                              {clientAccountZipCodeData !== "" && (
                                <div className=" ml-2 mt-1 ">
                                  <p>{clientAccountZipCodeData?.postalPlace}</p>
                                </div>
                              )}
                            </div>
                          </div>
                        </div>
                      )}
                    </>
                  )}
                  <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start  sm:pt-5">
                    <label
                      htmlFor="position"
                      className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                    >
                      Stilling:
                    </label>
                    <div className="mt-1 sm:mt-0 sm:col-span-2">
                      <select
                        {...register(
                          "newEmploymentAsIndependent.employment.position"
                        )}
                        required
                        name="newEmploymentAsIndependent.employment.position"
                        id="newEmploymentAsIndependent.employment.position"
                        key="newEmploymentAsIndependent.employment.position"
                        value={position}
                        onChange={changePos}
                        className=" block w-full shadow-sm focus:ring-meny-700 focus:border-meny-500 sm:text-sm border-gray-300 rounded-md"
                      >
                        <option disabled selected value="">
                          {" "}
                          -- Stilling --{" "}
                        </option>

                        {positions?.map(p => (
                          <option key={p.id} value={p.id}>
                            {p.jobTitle}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>
                  <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start  sm:pt-5">
                    <label
                      htmlFor="partTimePosition"
                      className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                    >
                      Prosentvis stilling:
                    </label>
                    <div className="mt-1 sm:mt-0 sm:col-span-2">
                      <input
                        type="text"
                        {...register(
                          "newEmploymentAsIndependent.employment.partTimePosition",
                          {
                            pattern: {
                              value: /^[5-9][0-9]?$|^100$/i,
                              message: "Kun tall mellom 50-100 er tillatt",
                            },
                          }
                        )}
                        name="newEmploymentAsIndependent.employment.partTimePosition"
                        placeholder={"50-100"}
                        defaultValue={0}
                        id="newEmploymentAsIndependent.employment.partTimePosition"
                        className="block w-full shadow-sm focus:ring-meny-700 focus:border-meny-500 sm:text-sm border-gray-300 rounded-md"
                      />
                      <ErrorMessage
                        errors={errors}
                        name="newEmploymentAsIndependent.employment.partTimePosition"
                        render={({ message }) => (
                          <p className="text-sm text-red-600">{message}</p>
                        )}
                      />
                    </div>
                  </div>
                  <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start  sm:pt-5">
                    <label className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                      Startdato:
                    </label>
                    <div className="mt-1 sm:mt-0 sm:col-span-2">
                      <div className="mt-1 sm:mt-0 sm:col-span-2">
                        <Controller
                          rules={{
                            required: {
                              message: "Startdato påkrevet",
                              value: true,
                            },
                          }}
                          control={control}
                          name={`newEmploymentAsIndependent.employment.startDate`}
                          render={({ field: { onChange, onBlur, value } }) => (
                            <ReactDatePicker
                              locale="nb"
                              onChange={onChange}
                              onBlur={onBlur}
                              minDate={
                                startDateLimit === undefined ? null : startDate
                              }
                              maxDate={
                                startDateLimit === undefined ? null : maxDate
                              }
                              selected={value ? new Date(value) : undefined}
                              dateFormat="dd/MM/yyyy"
                              className="block w-full shadow-sm focus:ring-meny-700 focus:border-meny-500 sm:text-sm border-gray-300 rounded-md"
                            />
                          )}
                        />
                        <ErrorMessage
                          errors={errors}
                          name={`newEmploymentAsIndependent.employment.startDate`}
                          render={({ message }) => (
                            <p className="text-sm text-red-600">{message}</p>
                          )}
                        />
                      </div>
                    </div>
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
