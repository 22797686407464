import { useAccount, useMsal } from "@azure/msal-react"
import { ErrorMessage } from "@hookform/error-message"
import { navigate } from "gatsby"
import React, { useState } from "react"
import { useForm } from "react-hook-form"
import { useMutation, useQuery, useQueryClient } from "react-query"
import { fetchApi, patchApi, postApi } from "../../../../Utils/Api.utils"
import { FormValues } from "../../../../Utils/Types"
import LoadingData from "../../../common/graphichs/LoadingData"
import MyPageLayout from "../../../common/layouts/myPageLayout"
import Consent from "../../../mypagecomponents/contacinfo/Consent"
import useNewAccountSimple from "../../../hooks/newEmploymentAsIndependent/Simple/useNewAccountSimple"
import useNewEmploymentAsIndependentSimple from "../../../hooks/newEmploymentAsIndependent/Simple/useNewEmploymentAsIndependentSimple"
import useNewEmploymentSimple from "../../../hooks/newEmploymentAsEmployee/useNewEmploymentSimple"
import useNewIncidentAsEmployee from "../../../hooks/newEmploymentAsEmployee/useNewIncidentAsEmployee"
import { toast } from "react-toastify"
import usePatchEmploymentSimple from "../../../hooks/newEmploymentAsIndependent/Simple/usePatchEmploymentSimple"

const SendStudentToOrdinary = ({ location }) => {
  const { instance, accounts, inProgress } = useMsal()
  const account = useAccount(accounts[0] || {})
  const [description, setDescription] = useState("")
  const [disableButton, setDisabled] = useState<boolean>(false)
  const [showLoadingData, setShowLoadingData] = useState<boolean>(false)

  const today = new Date()
  const tomorrow = new Date(today)
  tomorrow.setDate(today.getDate() + 1).toLocaleString

  const {
    register,
    formState: { errors },
  } = useForm<FormValues>()
  const queryClient = useQueryClient()

  const eventQuery = useQuery(
    "myEvents",
    async () =>
      fetchApi(
        process.env.GATSBY_API_URL + `/Events/MyEvents`,
        account,
        inProgress,
        instance
      ).then(res => res.data),
    {}
  )

  const userInfoQuery = useQuery("userInfo", () =>
    fetchApi(
      process.env.GATSBY_API_URL + "/Contacts/me",
      account,
      inProgress,
      instance
    ).then(res => res.data)
  )

  const updateStatus = useMutation(
    () => {
      return patchApi(
        `${process.env.GATSBY_API_URL}/Contacts/me`,
        {
          professionallyActiveStatus:
            location?.state?.professionallyActiveStatus,
        },
        account,
        inProgress,
        instance
      ).then(res => res.data)
    },
    {
      onSuccess: data => {
        navigate("/app")
      },
      onError: _error => {
        setShowLoadingData(false)
        toast.error("Det har skjedd en feil, prøv igjen senere", {
          position: "top-center",
          autoClose: 6000,
        })
      },
      onSettled: () => {},
    }
  )

  const newEmploymentEmployee = useNewEmploymentSimple()

  const newAccount = useNewAccountSimple(
    setShowLoadingData,
    eventQuery,
    location?.state?.clientAccountWithOrgNumber,
    location?.state?.newEmploymentIndependentIncidentBody?.formattedDescription,
    location?.state?.remainingData
  )

  const newEmploymentAsIndependent = useNewEmploymentAsIndependentSimple(
    setShowLoadingData,
    eventQuery,
    location?.state?.clientAccountWithOrgNumber,
    location?.state?.newEmploymentIndependentIncidentBody?.formattedDescription
  )

  const makeIncidentAsEmployee = useNewIncidentAsEmployee(
    location?.state?.newEmploymentEmployeeIncidentBody
  )

  const mutationCreateIncident = useMutation(
    () => {
      return postApi(
        process.env.GATSBY_API_URL + "/Incidents",
        {
          title: "Endringssøknad: Student til Ordinær",
          description: description,
          caseTypeCode: 16,
          caseOriginCode: 3,
          // Emne = Medlemskap --> Endring av medlemskapstype --> Student til ordinær
          subjectId: "8ae31fc1-f49d-ec11-b400-000d3aadca3e",
          approveStatus: 778380001,
          subscriptionType: 778380000,
          OrganizationId: process.env.GATSBY_ORG_GUID,
          ContineInsurence: location.state.consentLiabality,
          membershipCategory: "d44f2c76-b677-ec11-8d21-000d3aa9685b",
          workSituations: 778380000,
          status: 1,
          state: 0,
          membershipId: userInfoQuery.data.activeMemebershipId,
        },
        account,
        inProgress,
        instance
      ).then(res => res.data)
    },
    {
      onSuccess: data => {
        queryClient.invalidateQueries(["userIncidentData"])
      },

      onError: _error => {
        setShowLoadingData(false)
        toast.error(
          "Det har skjedd en feil med søknaden. Ta kontakt med kundeservice.",
          {
            position: "top-center",
            autoClose: 6000,
          }
        )
      },
    }
  )

  const mutationNewEmployment = useNewEmploymentSimple()

  const mutationPatchEmployment = usePatchEmploymentSimple()

  const sendInnSchema = async () => {
    setDisabled(true)
    setShowLoadingData(true)

    try {
      await handleNewEmployment()
      await handleExistingEmployment()
      await mutationCreateIncident.mutateAsync()
      await updateStatus.mutateAsync()
    } catch (error) {
      toast.error("Det har skjedd en feil, prøv igjen senere", {
        position: "top-center",
        autoClose: 6000,
      })
    } finally {
      setShowLoadingData(false)
      setDisabled(false)
    }
  }

  // Handle new employment scenarios
  const handleNewEmployment = async () => {
    // Create new employment as independent
    if (location?.state?.iSNewEmploymentAsIndependent) {
      if (location?.state?.accountWithOrgNumberExist === true) {
        await newEmploymentAsIndependent.mutateAsync(
          location?.state?.newEmploymentIndependentBody?.employment
        )
      } else {
        await newAccount.mutateAsync(
          location?.state?.newEmploymentIndependentBody?.account
        )
      }
    }

    // Create new employment as employee
    if (location?.state?.iSNewEmploymentAsEmployee) {
      await newEmploymentEmployee.mutateAsync(
        location?.state?.newEmploymentEmployeeBody
      )
    }

    // Create incident for employee employment
    if (location?.state?.makeIncidentAsEmployee) {
      await makeIncidentAsEmployee.mutateAsync(
        location?.state?.newEmploymentEmployeeIncidentBody
      )
    }
  }

  // Handle existing employment scenarios
  const handleExistingEmployment = async () => {
    const today = new Date()
    const tomorrow = new Date(today)
    tomorrow.setDate(today.getDate() + 1)

    // Make API changes depending on the action related to each work
    if (
      location?.state?.existingEmployment &&
      location?.state?.existingEmployment.length > 0
    ) {
      await Promise.all(
        location?.state?.existingEmployment.map(async work => {
          switch (work?.action) {
            case "keep":
              return null
            case "convert":
              // Remove these values and only add the remaining values from work as newEmploymentConvert
              const {
                action,
                employeeName,
                workplaceName,
                endDate,
                id,
                ...newEmploymentConvert
              } = work
              const newEmployment = {
                ...newEmploymentConvert,
                startDate: tomorrow,
                role: 125600000,
              }
              const patchEmployment = {
                id: work.id,
                endDate: today,
              }
              await mutationNewEmployment.mutateAsync(newEmployment)
              await mutationPatchEmployment.mutateAsync(patchEmployment)
              break
            case "cancel":
              const patchEmploymentCancel = {
                id: work.id,
                endDate: work.endDate,
              }
              await mutationPatchEmployment.mutateAsync(patchEmploymentCancel)
          }
        })
      )
    }
  }

  return showLoadingData ? (
    // // "Laster inn..."
    <LoadingData subject="Oppretter..." />
  ) : userInfoQuery.isLoading ? (
    <LoadingData />
  ) : (
    <MyPageLayout props={"Endringssøknad"}>
      <div>
        <div>
          <div>
            <p className="mt-1 text-lg text-gray-900">
              Ordinært medlemskap i Norsk psykologforening er betinget av
              godkjenning (autorisasjon/lisens) som psykolog i Norge iht.
              helsepersonelloven av 16. juni 2000. Godkjenning foretas av
              Helsedirektoratet.
            </p>
          </div>
        </div>
        <br />
        <br />

        <Consent />

        <br />
        <br />
        <div className="sm:col-span-6">
          <label
            htmlFor="description"
            className="block text-sm font-medium text-gray-700"
          >
            Kommentar
          </label>
          <p className="mt-2 text-sm text-gray-600">Skriv noen ord om saken.</p>
          <div className="mt-1">
            <textarea
              {...register("description", {
                minLength: {
                  value: 10,
                  message: "Minimum tegn er 10",
                },
                maxLength: {
                  value: 2000,
                  message: "Maks tegn er 2000",
                },
              })}
              id="description"
              name="description"
              onChange={e => setDescription(e.target.value)}
              rows={3}
              className="shadow-sm focus:ring-meny-700 focus:border-meny-500 block w-full sm:text-sm border border-gray-300 rounded-md"
              defaultValue={""}
            />
            <ErrorMessage
              errors={errors}
              name="description"
              render={({ message }) => (
                <p className="text-sm text-red-600">{message}</p>
              )}
            />
          </div>
        </div>

        <div className="pt-5 flex justify-end">
          <button
            type="button"
            disabled={disableButton}
            onClick={sendInnSchema}
            className="ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-meny-700 hover:bg-meny-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-meny-700"
          >
            Send søknaden
          </button>
        </div>
      </div>
    </MyPageLayout>
  )
}

export default SendStudentToOrdinary
