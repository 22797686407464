import { useAccount, useMsal } from "@azure/msal-react"
import React, { useEffect, useState } from "react"
import { UseFormReturn } from "react-hook-form"
import { fetchApi } from "../../../../../Utils/Api.utils"
import { DateTimeToNorwegian } from "../../../../../Utils/DateFormater"
import {
  typePrivatepractitioner,
  workcategory,
} from "../../../../../Utils/Maps"
import ContentBox from "../../../../common/inputFields/ContentBox"
import { ButtonRadioGroup } from "../../../../common/radioGroup/buttonRadioGroup"
import CancelEmployment from "./CancelEmployment"
import ConvertEmployment from "./ConvertEmployment"
import { FormValues } from "./FlowToOrdinaryTypes"

type EmploymentCardType = {
  work: any
  index: number
  formController: UseFormReturn<FormValues, any>
  employerType?: number
}

const EmploymentCard = (props: EmploymentCardType) => {
  const work = props?.work

  const [employerName, setEmployerName] = useState<string>()
  const [roleName, setRoleName] = useState<string>()
  const [workPlaceName, setWorkPlaceName] = useState<string>()
  const [position, setPosition] = useState<string>()
  const [cardAction, setCardAction] = useState<"keep" | "cancel" | "convert">(
    props.work.action
  )

  const { instance, accounts, inProgress } = useMsal()
  const account = useAccount(accounts[0] || {})

  useEffect(() => {
    if (work?.role == 125600000) setRoleName("Hovedarbeidsgiver")
    else setRoleName("Biarbeidsgiver")

    const getEName = id => {
      fetchApi(
        `${process.env.GATSBY_API_URL}/Accounts/${id}`,
        account,
        inProgress,
        instance
      )
        .then(response => {
          setEmployerName(response.data.name)
          response.data?.tariff?.positions?.forEach(p => {
            if (p.id === work?.position) setPosition(p.jobTitle)
          })
        })
        .catch(() => {
          setEmployerName("")
        })
    }
    const getPName = async id => {
      fetchApi(
        `${process.env.GATSBY_API_URL}/Accounts/${id}`,
        account,
        inProgress,
        instance
      )
        .then(response => {
          setWorkPlaceName(response.data.name)
        })
        .catch(() => {
          setWorkPlaceName("")
        })
    }
    if (work?.employer != undefined) getEName(work?.employer)
    else if (work?.employerAlternative != undefined)
      setEmployerName(work?.employerAlternative)
    if (work?.workPlace != undefined) getPName(work?.workPlace)
    else if (work?.workPlaceAlternative != undefined)
      setWorkPlaceName(work?.workPlaceAlternative)
    if (work?.tariff?.positions === undefined)
      setPosition(work?.positionAlternative)
  }, [])

  const startDate = DateTimeToNorwegian(work?.startDate)
  const endDate = DateTimeToNorwegian(work?.endDate)

  let content = {
    key: work?.employer,
    header: employerName,
    defaultOpen: true,
  }

  // Auto open the "Avslutt arbeidsforhold" whenever we need to user to set an endDate"
  useEffect(() => {
    if (
      props.employerType === 292460001 ||
      (props.employerType === 292460002 && props.work.role === 125600000)
    ) {
      setCardAction("cancel")
      props.formController.setValue(
        `employments.${props.index}.action`,
        "cancel"
      )
    } else {
      setCardAction("keep")
      props.formController.setValue(`employments.${props.index}.action`, "keep")
    }
  }, [props.employerType])

  const changeRadio = (e: "keep" | "cancel" | "convert") => {
    setCardAction(e)
    props.formController.setValue(`employments.${props.index}.action`, e)
  }

  return (
    <div>
      <ContentBox props={content}>
        {/* Check if workcategory is as "Ansatt" or "undefined"*/}
        {work?.workcategory == 292460000 || work?.workcategory == undefined ? (
          <div>
            <div className="sm:grid sm:grid-cols-2 gap-3 mb-4">
              <p className="text-base text-gray-600 sm:col-span-1">
                Rolle: {roleName}
              </p>
              <p className="text-base text-gray-600 sm:col-span-1">
                Arbeidssted: {workPlaceName}
              </p>
              <p className="text-base text-gray-600 sm:col-span-1">
                Stilling: {position}
              </p>
              <p className="text-base text-gray-600 sm:col-span-1">
                Prosentvis stilling: {work?.partTimePosition}
              </p>
              {work?.startDate != undefined ? (
                <p className="text-base text-gray-600 sm:col-span-1">
                  Startdato: {startDate}
                </p>
              ) : (
                <p className="text-base text-gray-600 sm:col-span-1">
                  Startdato:
                </p>
              )}

              <p className="text-base text-gray-600 sm:col-span-1">
                Sluttdato: {endDate}
              </p>
            </div>
          </div>
        ) : (
          <div className="sm:grid sm:grid-cols-2 gap-3 mb-4">
            <p className="text-base text-gray-600 sm:col-span-1">
              Rolle: {roleName}
            </p>
            <p className="text-base text-gray-600 sm:col-span-1">
              Arbeidskategori: {workcategory.get(work?.workcategory)}
            </p>
            <p className="text-base text-gray-600 sm:col-span-1">
              Type: {typePrivatepractitioner.get(work?.typePrivatePractitioner)}
            </p>
            <p className="text-base text-gray-600 sm:col-span-1">
              Stilling: {position}
            </p>
            <p className="text-base text-gray-600 sm:col-span-1">
              Prosentvis stilling: {work?.partTimePosition}
            </p>
            <p className="text-base text-gray-600 sm:col-span-1">
              Avtalespesialist:{" "}
              {work?.agreementspecialist == true ? "Ja" : "Nei"}
            </p>
            {work?.agreementspecialist == true && (
              <p className="text-base text-gray-600 sm:col-span-1">
                Driftstilskudd: {work?.operatingGrants}%
              </p>
            )}
            {work?.clientsName != undefined && (
              <p className="text-base text-gray-600 sm:col-span-1">
                Oppdragsgiver: {work?.clientsName}
              </p>
            )}
            <p className="text-base text-gray-600 sm:col-span-1">
              Startdato: {startDate}
            </p>
            <p className="text-base text-gray-600 sm:col-span-1">
              Sluttdato: {endDate}
            </p>
          </div>
        )}
        {work?.endDate === undefined && (
          <div className="mt-12">
            <ButtonRadioGroup
              label={"Velg et arbeidsforhold"}
              value={cardAction}
              onChange={e => {
                changeRadio(e)
              }}
              type={CardRadioActions.filter(item =>
                filterCardRadioActions(
                  item,
                  props.work.role,
                  props.employerType
                )
              )}
            />

            {cardAction === "cancel" && (
              <CancelEmployment
                index={props.index}
                formController={props.formController}
              />
            )}
            {cardAction === "convert" && (
              <ConvertEmployment
                index={props.index}
                formController={props.formController}
              />
            )}
          </div>
        )}
      </ContentBox>
    </div>
  )
}

const filterCardRadioActions = (
  item: {
    name: string
    value: string
  },
  employmentRole: number,
  employerType?: number
) => {
  if (item.value === "keep") {
    if (employerType === 292460001) return false // if 'ikke yrkesaktiv' can't keep employments
    if (employerType === 292460002 && employmentRole === 125600000) return false
    return true
  }

  if (item.value === "cancel") {
    return true
  }

  if (item.value === "convert") {
    if (employerType === 292460001) return false // if 'ikke yrkesaktiv' can't convert to main employment
    if (employerType === 292460002) return false // if 'arbeidssøkende' can't convert to main employment
    if (employmentRole === 125600000) return false // if employment is already main employment can't convert
    return true
  }
}

const CardRadioActions = [
  {
    name: "Behold arbeidsforhold",
    value: "keep",
  },
  {
    name: "Avslutt arbeidsforhold",
    value: "cancel",
  },
  {
    name: "Konverter til hovedarbeidsforhold",
    value: "convert",
  },
]

export default EmploymentCard
