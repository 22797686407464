import { InteractionType } from "@azure/msal-browser"
import {
  MsalAuthenticationTemplate,
  useAccount,
  useMsal,
} from "@azure/msal-react"
import { ErrorMessage } from "@hookform/error-message"
import { navigate } from "gatsby"
import React, { useEffect, useState } from "react"
import ReactDatePicker from "react-datepicker"
import { Controller, useForm } from "react-hook-form"
import { useMutation, useQuery, useQueryClient } from "react-query"
import { toast } from "react-toastify"
import {
  fetchApi,
  fetchApiStandard,
  patchApi,
} from "../../../../Utils/Api.utils"
import { DateTimeToDateTime } from "../../../../Utils/DateFormater"
import { Contact, ExpenseReport, Project } from "../../../../Utils/Entities"
import { Loading } from "../../../../Utils/Loading"
import { ErrorComponent } from "../../../common/graphichs/ErrorComponent"
import LoadingData from "../../../common/graphichs/LoadingData"
import MyPageLayout from "../../../common/layouts/myPageLayout"
import VisualStep from "../../../common/Progress/VisualStep"
import { StepsType } from "../../../common/Steps/StepsType"
import HelperText from "../../../common/text/HelperText"
import { loginRequest } from "../../../security/authConfig"

const EditExpenseReportContent = ({ showSteps, reportId }) => {
  const queryClient = useQueryClient()

  // const showSteps: boolean = showSteps

  const [invalidProject, setInvalidProject] = useState<boolean>(false)
  const [loadingProject, setLoadingProject] = useState<boolean>(false)
  const [projectNo, setProjectNo] = useState<string>("")
  const [projectId, setProjectId] = useState<string>()
  const [project, setProject] = useState<Project>()
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const { instance, accounts, inProgress } = useMsal()
  const account = useAccount(accounts[0] || {})
  const [refetch, setRefetch] = useState<boolean>(true)
  const [invalidAccountNo, setInvalidAccountNo] = useState<boolean>(false)
  const [accountNo, setAccountNo] = useState<string>()
  const [contact, setContact] = useState<Contact>()
  const [invalidDate, setInvalidDate] = useState<boolean>(false)
  const [report, setReport] = useState<ExpenseReport>()
  const [fromDate, setFromDate] = useState<Date>()
  const [toDate, setToDate] = useState<Date>()

  const steps: StepsType = [
    {
      id: "1",
      name: "Beskrivelse",
      status: "current",
      completed: false,
    },
    {
      id: "2",
      name: "Detaljer",
      status: "upcoming",
      completed: false,
    },
  ]

  // const report: ExpenseReport = props.report
  const {
    register,
    handleSubmit,
    reset,
    control,
    setError,
    formState: { errors },
  } = useForm()

  const reportQuery = useQuery(
    "getReport",
    () =>
      fetchApi(
        process.env.GATSBY_API_URL + "/ExpenseReports/" + reportId,
        account,
        inProgress,
        instance
      ).then(res => res.data),
    {
      // enabled: reportId !== undefined,
      onSuccess: data => {
        setReport(data)
        setFromDate(data.fromDate)
        setToDate(data.toDate)
        setProjectNo(data.project.projectNo)
        setProjectId(data.project.id)
        reset(data)

        // setIsLoading(false)
      },
      onError: _error => {
        // setIsLoading(false)
      },
    }
  )

  const userInfoQuery = useQuery(
    "userInfo",
    () =>
      fetchApi(
        process.env.GATSBY_API_URL + "/Contacts/me",
        account,
        inProgress,
        instance
      ).then(res => res.data),
    {
      onSuccess: data => {
        setContact(data)
        // setIsLoading(false)
        setAccountNo(data.accountNo)
      },
      onError: _error => {},
    }
  )

  const mutationUpdateExpense = useMutation(
    data => {
      return patchApi(
        process.env.GATSBY_API_URL + "/ExpenseReports/" + reportId,
        data,
        account,
        inProgress,
        instance
      ).then(res => res.data)
    },
    {
      onSuccess: data => {
        if (accountNo !== contact?.accountNo) {
          mutationUpdateContactAccountNo.mutate()
        } else {
          // setStep1(false)
          // setFirstStep("complete")
          // setSecondStep("current")
          setIsLoading(false)
          navigate("/app/dialog/nyttutlegg", {
            state: {
              isDefaultStep2: true,
              reportId: reportId,
            },
          })
        }
      },
      onError: _error => {
        setIsLoading(false)
        console.log(_error)
      },
    }
  )

  const mutationUpdateContactAccountNo = useMutation(
    () => {
      return patchApi(
        `${process.env.GATSBY_API_URL}/Contacts/me`,
        {
          accountNo: accountNo,
        },
        account,
        inProgress,
        instance
      ).then(res => res.data)
    },
    {
      onSuccess: data => {
        // setStep1(false)
        // setFirstStep("complete")
        // setSecondStep("current")
        navigate("/app/dialog/nyttutlegg", {
          state: {
            isDefaultStep2: true,
            reportId: reportId,
          },
        })
        setIsLoading(false)
      },
      onError: _error => {},
    }
  )

  const projectQuery = useQuery(
    "projectQuery",
    () =>
      fetchApiStandard(
        process.env.GATSBY_API_URL + "/Projects/" + projectNo,
        account,
        inProgress,
        instance
      ).then(res => res.data),
    {
      enabled: !!projectNo && refetch,
      onSuccess: data => {
        setProjectId(data.id)
        setProject(data)
        setInvalidProject(false)
        setRefetch(false)
      },
      onError: _error => {
        setProjectNo("")
        setProject(null)
        setRefetch(false)
        setInvalidProject(true)
        toast.warning("Vennligst tast inn et gyldig prosjektnummer!", {
          position: "top-center",
          autoClose: 10000,
        })
      },
    }
  )

  const validateProject = event => {
    queryClient.invalidateQueries("projectQuery")
    if (event.target.value.length === 10) {
      setRefetch(true)
      setProjectNo(event.target.value)
    } else setInvalidProject(true)
  }

  const changeAccountNo = event => {
    const acNo = event.target.value.replaceAll(".", "")
    const acNo2 = acNo.replaceAll(" ", "")
    setAccountNo(acNo2)
  }

  useEffect(() => {
    if (accountNo?.length !== 11 && accountNo?.length !== 0)
      setInvalidAccountNo(true)
    else {
      setInvalidAccountNo(false)
    }
  }, [accountNo])

  const onSetFromDate = date => {
    setFromDate(date)
  }
  const onSetToDate = date => {
    setToDate(date)
  }

  const patchExpense = (data, e) => {
    e.preventDefault()
    data.projectId = projectId
    data.id = reportId
    if (new Date(fromDate || "") > new Date(toDate || "")) {
      setInvalidDate(true)
      return
    } else setInvalidDate(false)
    setIsLoading(true)

    data.fromDate = fromDate
    data.toDate = toDate

    console.log(data)
    mutationUpdateExpense.mutate(data)
  }

  return reportQuery.isLoading ? (
    <LoadingData />
  ) : isLoading ? (
    <LoadingData subject="Oppdaterer" />
  ) : (
    <MyPageLayout props="Rediger">
      <div>
        {showSteps ? (
          <div className="mb-4">
            <VisualStep steps={steps} />
          </div>
        ) : null}
        <form method="PATCH" onSubmit={handleSubmit(patchExpense)}>
          <div className="grid grid-cols-6 gap-6">
            <div className="col-span-6 sm:col-span-3">
              <label
                htmlFor="name"
                className="block text-sm font-medium text-gray-700"
              >
                Formål med reisen/utlegget*
              </label>
              <input
                {...register("name", {
                  // required: { value: true, message: "Obligatorisk felt." },
                  pattern: {
                    value: /^[a-zA-Z0-9æøåÆØÅ\s]*$/,
                    message: "Kun bokstaver og tall er tillatt.",
                  },
                  maxLength: {
                    value: 200,
                    message: "Maks 200 tegn.",
                  },
                })}
                type="text"
                name="name"
                id="name"
                required
                defaultValue={report?.name}
                className="mt-1 focus:ring-meny-700 focus:border-meny-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
              />
              <ErrorMessage
                errors={errors}
                name="name"
                render={({ message }) => (
                  <p className="text-sm text-red-600">{message}</p>
                )}
              />
            </div>

            <div className="col-span-6 sm:col-span-3">
              <label
                htmlFor="name"
                className="block text-sm font-medium text-gray-700"
              >
                Prosjektnummer*
              </label>
              <input
                // {...register("projectNo", {
                //   required: { value: true, message: "Obligatorisk felt." },
                // })}
                type="text"
                name="projectId"
                id="projectId"
                required
                defaultValue={report?.project?.projectNo}
                onChange={validateProject}
                className="mt-1 focus:ring-meny-700 focus:border-meny-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
              />
              <ErrorMessage
                errors={errors}
                name="projectId"
                render={({ message }) => (
                  <p className="text-sm text-red-600">{message}</p>
                )}
              />
              {invalidProject ? (
                <p className="text-red-500 text-sm">
                  Prosjektnummeret er ugyldig.
                </p>
              ) : (
                <div></div>
              )}
            </div>

            {project?.event !== undefined &&
            project?.session === undefined &&
            !invalidProject ? (
              <div className="col-span-6 sm:col-span-3 sm:col-start-4">
                <label
                  htmlFor="name"
                  className="block text-sm font-medium text-gray-700"
                >
                  Arrangement: {project.event?.name}
                </label>
                <div className="mt-1 sm:mt-0 sm:col-span-2 text-sm">
                  Startdato:{" "}
                  {DateTimeToDateTime(project.event?.startDate.toString())}
                </div>
                <div className="mt-1 sm:mt-0 sm:col-span-2 text-sm">
                  Sluttdato:{" "}
                  {DateTimeToDateTime(project.event?.endDate.toString())}
                </div>
                <div className="mt-1 sm:mt-0 sm:col-span-2 text-sm">
                  Sted: {project.event?.building?.name}
                </div>
              </div>
            ) : null}

            {project?.session !== undefined && !invalidProject ? (
              <div className="col-span-6 sm:col-span-3 sm:col-start-4">
                <label
                  htmlFor="name"
                  className="block text-sm font-medium text-gray-700"
                >
                  Arrangement: {project.event?.name}
                </label>
                <label
                  htmlFor="name"
                  className="block text-sm font-medium text-gray-700"
                >
                  Kurs: {project.session?.name}
                </label>
                <div className="mt-1 sm:mt-0 sm:col-span-2 text-sm">
                  Startdato:{" "}
                  {DateTimeToDateTime(project.session?.startDate.toString())}
                </div>
                <div className="mt-1 sm:mt-0 sm:col-span-2 text-sm">
                  Sluttdato:{" "}
                  {DateTimeToDateTime(project.session?.endDate.toString())}
                </div>
                <div className="mt-1 sm:mt-0 sm:col-span-2 text-sm">
                  Sted: {project.session?.building?.name}
                </div>
              </div>
            ) : null}

            {project?.session === undefined &&
            project?.event === undefined &&
            projectNo !== "" &&
            !invalidProject ? (
              <div className="col-span-6 sm:col-span-3 sm:col-start-4">
                <label
                  htmlFor="name"
                  className="block text-sm font-medium text-gray-700"
                >
                  Prosjektnavn: {project?.projectName}
                </label>{" "}
              </div>
            ) : null}

            <div className="col-span-6 sm:col-span-3">
              <label
                htmlFor="fromDate"
                className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
              >
                Fra dato*:
              </label>
              <div className="mt-1 sm:mt-0 sm:col-span-2">
                <Controller
                  control={control}
                  name="fromDate"
                  // defaultValue={new Date(report?.fromDate || "")}
                  // defaultValue={new Date()}
                  render={({ field: { onChange, onBlur, value, ref } }) => (
                    <ReactDatePicker
                      id="fromDate"
                      locale="nb"
                      onBlur={onBlur}
                      required
                      selected={fromDate ? new Date(fromDate) : null}
                      onChange={(date: Date) => onSetFromDate(date)}
                      dateFormat="dd/MM/yyyy HH:mm"
                      timeFormat="HH:mm"
                      showTimeSelect
                      className="block w-full shadow-sm focus:ring-meny-700 focus:border-meny-500 sm:text-sm border-gray-300 rounded-md"
                    />
                  )}
                />
              </div>
            </div>

            <div className="col-span-6 sm:col-span-3">
              <label
                htmlFor="toDate"
                className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
              >
                Til dato*:
              </label>
              <div className="mt-1 sm:mt-0 sm:col-span-2">
                <Controller
                  control={control}
                  name="toDate"
                  // defaultValue={new Date(report?.toDate || "")}
                  // defaultValue={new Date()}
                  render={({ field: { onChange, onBlur, value, ref } }) => (
                    <ReactDatePicker
                      id="toDate"
                      locale="nb"
                      onBlur={onBlur}
                      showTimeSelect
                      required
                      selected={toDate ? new Date(toDate) : null}
                      onChange={(date: Date) => onSetToDate(date)}
                      dateFormat="dd/MM/yyyy HH:mm"
                      timeFormat="HH:mm"
                      className="block w-full shadow-sm focus:ring-meny-700 focus:border-meny-500 sm:text-sm border-gray-300 rounded-md"
                    />
                  )}
                />
              </div>
              {invalidDate ? (
                <p className="text-red-500 text-sm">
                  Til dato kan ikke være tidligere enn fra dato.
                </p>
              ) : null}
            </div>
            <div className="col-span-6 sm:col-span-3">
              <label
                htmlFor="name"
                className="block text-sm font-medium text-gray-700"
              >
                Kontonummer*
              </label>
              <input
                // {...register("accountno", {
                //   required: {
                //     value: accountNo === "",
                //     message: "Obligatorisk felt.",
                //   },
                //   // pattern: { value: , message: "Kontonummer kan kun bestå av ha 11 siffer"}
                // })}
                type="text"
                name="accountno"
                id="accountno"
                defaultValue={contact?.accountNo}
                onChange={changeAccountNo}
                className="mt-1 focus:ring-meny-700 focus:border-meny-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
              />
              {/* <ErrorMessage
              errors={errors}
              name="accountNo"
              render={({ message }) => (
                <p className="text-sm text-red-600">{message}</p>
              )}
            /> */}
              {/* <div className="mb-2 flex justify-center"> */}
              {invalidAccountNo ? (
                <p className="text-red-500 text-sm">
                  Kontonummer er obligatorisk og må inneholde 11 siffer.
                </p>
              ) : (
                <div></div>
              )}
              {/* </div> */}
            </div>

            <div className="col-span-6">
              <label
                htmlFor="comment"
                className="block text-sm font-medium text-gray-700"
              >
                Ekstra opplysninger
              </label>
              <div className="mt-1">
                <textarea
                  id="description"
                  {...register("description", {
                    maxLength: 2000,
                  })}
                  //   onChange={e => {
                  //     setActivityComment(e.target.value)
                  //   }}
                  rows={3}
                  defaultValue={report?.description}
                  className="shadow-sm focus:ring-meny-700 focus:border-meny-500 block w-full sm:text-sm border border-gray-300 rounded-md"
                  placeholder="Ekstra opplysninger"
                />
                <ErrorMessage
                  errors={errors}
                  name="description"
                  render={({ message }) => (
                    <p className="text-sm text-red-600">{message}</p>
                  )}
                />
              </div>
            </div>
            <div className="col-span-6">
              <HelperText
                helpertext={
                  "Hvis du skal ha diett, må dette oppgis med sats basert på overnattingssted (privat/hotell)."
                }
              />
            </div>

            <div className="col-span-6">
              <HelperText
                helpertext={
                  "Reiseutgifter for billigste reise dekkes mot originalbilag. Reiseregning må sendes Psykologforeningen innen en måned etter kursets avslutning."
                }
              />
            </div>
          </div>

          <div className="mt-8 border-t flex justify-end">
            <button
              type="submit"
              className="ml-3 mt-6 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-meny-600 hover:bg-meny-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-meny-700"
            >
              Neste
            </button>
          </div>
        </form>
      </div>
    </MyPageLayout>
  )
}

const EditExpenseReport = ({ location }) => {
  const authRequest = {
    ...loginRequest,
  }

  return (
    <MsalAuthenticationTemplate
      interactionType={InteractionType.Redirect}
      authenticationRequest={authRequest}
      errorComponent={ErrorComponent}
      loadingComponent={Loading}
    >
      <EditExpenseReportContent
        showSteps={location.state.showSteps}
        reportId={location.state.id}
      />
    </MsalAuthenticationTemplate>
  )
}

export default EditExpenseReport
