import React, { useEffect } from "react"
import ReactDatePicker from "react-datepicker"
import { Controller, UseFormReturn } from "react-hook-form"
import {
  FormValues,
  RetiredKeepEmploymentsFormType,
} from "./RetiredKeepEmploymentsType"
import TableText from "../../../../common/tabel/TableText"

const RetiredKeepEmploymentsTableRow = (props: {
  employment: RetiredKeepEmploymentsFormType
  formController: UseFormReturn<FormValues, any>
  index: number
}) => {
  const {
    register,
    control,
    // formState: { errors },
  } = props.formController

  const endDate = props.employment.endDate

  const [isEmploymentCanceled, setIsEmploymentCanceled] = React.useState(
    endDate ? true : false
  )

  useEffect(() => {
    props.formController.resetField(`employments.${props.index}.endDate`)
  }, [isEmploymentCanceled])

  return (
    <tr>
      <TableText className="text-left">
        {props.employment?.employeeName?.name}
      </TableText>
      <TableText className="text-left">
        {props.employment?.workplaceName?.name}
      </TableText>
      <th className="space-y-6 sm:space-y-5 px-2">
        <input
          defaultValue={props.employment?.partTimePosition}
          type="number"
          {...register(
            `employments.${props.index}.newPositionPercentage` as const,
            {
              required: {
                value: true,
                message: "Obligatorisk felt.",
              },
              valueAsNumber: true,
              min: {
                message: "Må være mellom 0 og 50",
                value: 0,
              },
              max: {
                message: "Må være mellom 0 og 50",
                value: 50,
              },
            }
          )}
          className="block w-full shadow-sm focus:ring-meny-700 focus:border-meny-500 sm:text-sm border-gray-300 rounded-md"
        />
        {/* <ErrorMessage
            errors={errors}
            name={`employments.${props.index}.newPositionPercentage`}
            render={({ message }) => (
              <p className="text-sm text-red-600">{message}</p>
            )}
          /> */}
      </th>
      <th className="px-2">
        {/* <button
          type="button"
          onClick={() => setIsEmploymentCanceled(!isEmploymentCanceled)}
          className="w-full inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-meny-700 hover:bg-meny-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-meny-700"
        >
          Avslutt
        </button> */}
        <input
          type="checkbox"
          checked={isEmploymentCanceled}
          className="focus:ring-meny-700 h-4 w-4 text-gray-600 border-gray-300 rounded bg-gray-100"
          onChange={() => setIsEmploymentCanceled(!isEmploymentCanceled)}
        />
      </th>
      {isEmploymentCanceled && (
        <th className="px-2">
          <Controller
            rules={{ required: true }}
            control={control}
            name={`employments.${props.index}.endDate`}
            render={({ field: { onChange, onBlur, value } }) => (
              <ReactDatePicker
                locale="nb"
                onChange={onChange}
                onBlur={onBlur}
                selected={value ? new Date(value) : undefined}
                dateFormat="dd/MM/yyyy"
                className="block max-w-sm w-full shadow-sm focus:ring-meny-700 focus:border-meny-500 sm:text-sm border-gray-300 rounded-md"
              />
            )}
          />
          {/* <ErrorMessage
              errors={errors}
              name={`employments.${props.index}.endDate`}
              render={({ message }) => (
                <p className="text-sm text-red-600">{message}</p>
              )}
            /> */}
        </th>
      )}
    </tr>
  )
}

export default RetiredKeepEmploymentsTableRow
