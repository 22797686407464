import React, { useState, useEffect } from "react"
import { useForm, Controller } from "react-hook-form"
import { Account, Employment } from "../../../../Utils/Entities"
import { navigate } from "gatsby"
import ReactDatePicker, {
  registerLocale,
  setDefaultLocale,
} from "react-datepicker"
import nb from "date-fns/locale/nb"
import "react-datepicker/dist/react-datepicker.css"
import {
  fetchApi,
  patchApi,
  patchApiAnonymous,
} from "../../../../Utils/Api.utils"
import { InteractionType } from "@azure/msal-browser"
import {
  useMsal,
  useAccount,
  MsalAuthenticationTemplate,
} from "@azure/msal-react"
import { Loading } from "../../../../Utils/Loading"
import { ErrorComponent } from "../../../common/graphichs/ErrorComponent"
import MyPageLayout2 from "../../../common/layouts/myPageLayout2"
import { loginRequest } from "../../../security/authConfig"
import EditWork from "../../profil/EditWork"
import { useQuery } from "react-query"
import LoadingData from "../../../common/graphichs/LoadingData"
registerLocale("nb", nb)

type FormValues = Employment

const EditNewWorkContent = ({ location }) => {
  // const workId = props.props
  // console.log(props.props)

  const {
    register,
    handleSubmit,
    reset,
    control,
    formState: { errors },
  } = useForm<FormValues>()

  const [AStedFri, setAStedFri] = useState<boolean>(false)
  const [aGiverFri, setAGiverFri] = useState<boolean>(false)
  const [empName, setEmpName] = useState<string>()
  const [placeName, setPlaceName] = useState<string>()
  const [posName, setPosName] = useState<string>()

  const [employment, setEmployment] = useState<Employment>()

  const [startDate, setStartDate] = useState<Date>()
  const [endDate, setEndDate] = useState<Date>()

  const [showPos, setShowPos] = useState<boolean>(false)
  const [isPAlt, setIsPAlt] = useState<boolean>(false)
  const [smedlem, setSMedlem] = useState<boolean>(false)

  const [roleName, setRoleName] = useState<string>()

  const [invalidDate, setInvalidDate] = useState<boolean>(false)

  const { instance, accounts, inProgress } = useMsal()
  const account = useAccount(accounts[0] || {})
  const [workId, setWorkId] = useState(null)

  const [noWork, setNoWork] = useState<boolean>(true)
  const [showLoadingData, setShowLoadingData] = useState<boolean>(true)

  const employmentQuery = useQuery(
    "employment",
    () =>
      fetchApi(
        process.env.GATSBY_API_URL + "/Contacts/Employments",
        account,
        inProgress,
        instance
      ).then(res => res.data),
    {
      onSuccess: data => {
        if (data.findIndex(x => x?.role == 125600000) > -1) setNoWork(false)
        else setShowLoadingData(false)
      },
      onError: _error => {},
    }
  )

  const mainEmploymentQuery = useQuery(
    "mainEmployment",
    () =>
      fetchApi(
        `${process.env.GATSBY_API_URL}/Employments/main`,
        account,
        inProgress,
        instance
      ).then(res => res.data),
    {
      enabled: !noWork,
      onSuccess: data => {
        setShowLoadingData(false)
        if (data.role === 125600000) setRoleName("Hovedarbeidsgiver")
        else setRoleName("Biarbeidsgiver")
        setEmployment(data)
        if (data.startDate != undefined) setStartDate(data.startDate)
        if (data.endDate != undefined) setEndDate(data.endDate)
        if (data.employer != undefined) {
          fetchApi(
            `${process.env.GATSBY_API_URL}/Accounts/${data.employer}`,
            account,
            inProgress,
            instance
          ).then(r => {
            setEmpName(r.data.name)
            if (data.position != undefined) {
              r.data.tariff.positions.forEach(p => {
                if (p.id === data.position) setPosName(p.jobTitle)
              })
              setShowPos(true)
            } else {
              setShowPos(false)
              setIsPAlt(true)
            }
          })
        } else setAGiverFri(true)
        if (data.workPlace != undefined)
          fetchApi(
            `${process.env.GATSBY_API_URL}/Accounts/${data.workPlace}`,
            account,
            inProgress,
            instance
          ).then(r => {
            setPlaceName(r.data.name)
          })
        else setAStedFri(true)
        reset(data)
      },
      onError: _error => {},
    }
  )

  const patchWork = async (data, event) => {
    if (workId === null) {
      navigate("/app/medlemskap/application/docupload", {
        state: { consentLiabality: location.location.state.consentLiabality },
      })
    } else {
      const end = new Date(data.endDate)
      if (data.endDate != null || data.endDate != undefined) {
        if (startDate !== undefined && startDate >= end) {
          console.log("Datoen er ugyldig")
          setInvalidDate(true)
        } else {
          console.log("datoen er gyldig")
          setInvalidDate(false)
          patchApi(
            `${process.env.GATSBY_API_URL}/Employments/${workId}`,
            {
              endDate: end,
            },
            account,
            inProgress,
            instance
          )
            .then(response => {
              // console.log(response)
              window.setTimeout(() => {
                navigate("/app/medlemskap/application/docupload")
              }, 2500)
            })
            .catch(error => {})

          event.preventDefault()
        }
      } else {
        navigate("/app/medlemskap/application/docupload")
      }
    }
  }

  return showLoadingData ? (
    <LoadingData />
  ) : (
    <MyPageLayout2 props="Rediger arbeidsforhold">
      <div className="">
        <form
          method="PATCH"
          onSubmit={handleSubmit(patchWork)}
          className="space-y-8 "
        >
          {noWork ? (
            <div className="space-y-8 sm:space-y-5">
              <p className="text-red-500 italic flex justify-center">
                Du har ikke registrert en hovedarbeidsgiver.
              </p>
              <div className="pt-5 flex justify-end">
                <button
                  type="submit"
                  className="ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-meny-700 hover:bg-meny-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-meny-700"
                >
                  Neste
                </button>
              </div>
            </div>
          ) : (
            <div className="space-y-8 sm:space-y-5">
              <div className="space-y-6sm:space-y-5">
                <div className="space-y-6 sm:space-y-5">
                  {smedlem ? (
                    console.log("Kun biarbeid tillatt")
                  ) : (
                    <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:pt-5">
                      <label
                        htmlFor="role"
                        className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                      >
                        Rolle:
                      </label>
                      <div className="mt-1 sm:mt-0 sm:col-span-2">
                        <input
                          type="text"
                          name="role"
                          id="role"
                          readOnly={true}
                          key="role"
                          defaultValue={roleName}
                          className="bg-gray-200 block w-full shadow-sm focus:ring-meny-700 focus:border-meny-500 sm:text-sm border-gray-300 rounded-md"
                        ></input>
                      </div>
                    </div>
                  )}

                  {aGiverFri ? (
                    <div>
                      <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start  sm:pt-5">
                        <label
                          htmlFor="employerAlternative"
                          className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                        >
                          Arbeidsgivers navn:
                        </label>
                        <div className="mt-1 sm:mt-0 sm:col-span-2">
                          <input
                            type="text"
                            name="employerAlternative"
                            id="employerAlternative"
                            readOnly={true}
                            defaultValue={employment?.employerAlternative}
                            className="bg-gray-200 block w-full shadow-sm focus:ring-meny-700 focus:border-meny-500 sm:text-sm border-gray-300 rounded-md"
                          />
                        </div>
                      </div>
                      <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start  sm:pt-5">
                        <label
                          htmlFor="workTelephone"
                          className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                        >
                          Arbeidsgivers telefon:
                        </label>
                        <div className="mt-1 sm:mt-0 sm:col-span-2">
                          <input
                            type="text"
                            readOnly={true}
                            defaultValue={employment?.workTelephone}
                            name="workTelephone"
                            id="workTelephone"
                            className="bg-gray-200 block w-full shadow-sm focus:ring-meny-700 focus:border-meny-500 sm:text-sm border-gray-300 rounded-md"
                          />
                        </div>
                      </div>

                      <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start  sm:pt-5">
                        <label
                          htmlFor="employerAlternativeAddress"
                          className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                        >
                          Arbeidsgivers adresse:
                        </label>
                        <div className="mt-1 sm:mt-0 sm:col-span-2">
                          <textarea
                            rows={3}
                            id="employerAlternativeAddress"
                            readOnly={true}
                            defaultValue={
                              employment?.employerAlternativeAddress
                            }
                            // required
                            name="employerAlternativeAddress"
                            className="bg-gray-200 form-control h-20  block w-full text-base  px-3 py-1.5 shadow-sm focus:ring-meny-700 sm:text-sm focus:border-meny-500 border-gray-300 rounded-md transition ease-in-out m-0"
                          />
                        </div>
                      </div>
                    </div>
                  ) : (
                    <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start  sm:pt-5">
                      <label
                        htmlFor="employer"
                        className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                      >
                        Arbeidsgiver:
                      </label>
                      <div className="mt-1 sm:mt-0 sm:col-span-2">
                        <input
                          type="text"
                          name="employer"
                          id="employer"
                          key="employer"
                          readOnly={true}
                          value={empName}
                          className="bg-gray-200 block w-full shadow-sm focus:ring-meny-700 focus:border-meny-500 sm:text-sm border-gray-300 rounded-md"
                        />
                      </div>
                    </div>
                  )}

                  {AStedFri ? (
                    <div>
                      <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start  sm:pt-5">
                        <label
                          htmlFor="workPlaceAlternative"
                          className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                        >
                          Arbeidsstedets navn:
                        </label>
                        <div className="mt-1 sm:mt-0 sm:col-span-2">
                          <input
                            type="text"
                            name="workPlaceAlternative"
                            id="workPlaceAlternative"
                            readOnly={true}
                            defaultValue={employment?.workPlaceAlternative}
                            className="bg-gray-200 block w-full shadow-sm focus:ring-meny-700 focus:border-meny-500 sm:text-sm border-gray-300 rounded-md"
                          />
                        </div>
                      </div>
                      <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start  sm:pt-5">
                        <label
                          htmlFor="workPlaceTelephone"
                          className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                        >
                          Arbeidsstedets telefon:
                        </label>
                        <div className="mt-1 sm:mt-0 sm:col-span-2">
                          <input
                            type="text"
                            readOnly={true}
                            name="workPlaceTelephone"
                            id="workPlaceTelephone"
                            defaultValue={employment?.workPlaceTelephone}
                            className="bg-gray-200 block w-full shadow-sm focus:ring-meny-700 focus:border-meny-500 sm:text-sm border-gray-300 rounded-md"
                          />
                        </div>
                      </div>

                      <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start  sm:pt-5">
                        <label
                          htmlFor="workPlaceAlternativeAddress"
                          className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                        >
                          Arbeidsstedets adresse:
                        </label>
                        <div className="mt-1 sm:mt-0 sm:col-span-2">
                          <textarea
                            rows={3}
                            id="workPlaceAlternativeAddress"
                            readOnly={true}
                            defaultValue={
                              employment?.workPlaceAlternativeAddress
                            }
                            // required
                            name="workPlaceAlternativeAddress"
                            className="bg-gray-200 form-control h-20  block w-full text-base  px-3 py-1.5 shadow-sm focus:ring-meny-700 sm:text-sm focus:border-meny-500 border-gray-300 rounded-md transition ease-in-out m-0"
                          />
                        </div>
                      </div>
                    </div>
                  ) : (
                    <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start  sm:pt-5">
                      <label
                        htmlFor="workPlace"
                        className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                      >
                        Arbeidssted:
                      </label>
                      <div className="mt-1 sm:mt-0 sm:col-span-2">
                        <input
                          type="text"
                          name="workPlace"
                          id="workPlace"
                          key="workPlace"
                          readOnly={true}
                          defaultValue={placeName}
                          className="bg-gray-200 block w-full shadow-sm focus:ring-meny-700 focus:border-meny-500 sm:text-sm border-gray-300 rounded-md"
                        />
                      </div>
                    </div>
                  )}

                  {showPos ? (
                    <div>
                      <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start  sm:pt-5">
                        <label
                          htmlFor="position"
                          className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                        >
                          Stilling:
                        </label>
                        <div className="mt-1 sm:mt-0 sm:col-span-2">
                          <input
                            type="text"
                            name="position"
                            id="position"
                            key="position"
                            readOnly={true}
                            value={posName}
                            className="bg-gray-200 block w-full shadow-sm focus:ring-meny-700 focus:border-meny-500 sm:text-sm border-gray-300 rounded-md"
                          />
                        </div>
                      </div>
                    </div>
                  ) : (
                    <div></div>
                  )}

                  {isPAlt ? (
                    <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start  sm:pt-5">
                      <label
                        htmlFor="workPlaceAlternative"
                        className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                      >
                        Stilling (fritekst):
                      </label>
                      <div className="mt-1 sm:mt-0 sm:col-span-2">
                        <input
                          type="text"
                          name="positionAlternative"
                          id="positionAlternative"
                          readOnly={true}
                          defaultValue={employment?.positionAlternative}
                          className="bg-gray-200 block w-full shadow-sm focus:ring-meny-700 focus:border-meny-500 sm:text-sm border-gray-300 rounded-md"
                        />
                      </div>
                    </div>
                  ) : (
                    <div></div>
                  )}

                  <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start  sm:pt-5">
                    <label
                      htmlFor="partTimePosition"
                      className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                    >
                      Prosentvis stilling:
                    </label>
                    <div className="mt-1 sm:mt-0 sm:col-span-2">
                      <input
                        type="text"
                        name="partTimePosition"
                        readOnly={true}
                        defaultValue={employment?.partTimePosition}
                        id="partTimePosition"
                        className="bg-gray-200 block w-full shadow-sm focus:ring-meny-700 focus:border-meny-500 sm:text-sm border-gray-300 rounded-md"
                      />
                    </div>
                  </div>

                  <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start  sm:pt-5">
                    <label
                      htmlFor="startDate"
                      className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                    >
                      Startdato:
                    </label>
                    <div className="mt-1 sm:mt-0 sm:col-span-2">
                      <Controller
                        control={control}
                        name="startDate"
                        render={({ field: { onChange, onBlur, value } }) => (
                          <ReactDatePicker
                            locale="nb"
                            onChange={onChange}
                            onBlur={onBlur}
                            readOnly={true}
                            selected={Date.parse(value)}
                            dateFormat="dd/MM/yyyy"
                            className="bg-gray-200 block w-full shadow-sm focus:ring-meny-700 focus:border-meny-500 sm:text-sm border-gray-300 rounded-md"
                          />
                        )}
                      />
                    </div>
                  </div>

                  <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start  sm:pt-5">
                    <label
                      htmlFor="endDate"
                      className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                    >
                      Sluttdato:
                    </label>
                    <div className="mt-1 sm:mt-0 sm:col-span-2">
                      <Controller
                        control={control}
                        name="endDate"
                        render={({ field: { onChange, onBlur, value } }) => (
                          <ReactDatePicker
                            locale="nb"
                            onChange={onChange}
                            onBlur={onBlur}
                            selected={Date.parse(value)}
                            dateFormat="dd/MM/yyyy"
                            className="block w-full shadow-sm focus:ring-meny-700 focus:border-meny-500 sm:text-sm border-gray-300 rounded-md"
                          />
                        )}
                      />
                    </div>
                  </div>
                </div>
                <div className="pt-5 flex justify-end">
                  {/* <button
                  type="button"
                  onClick={() => navigate("/app/profil/arbeidsforhold")}
                  className="bg-white py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-meny-700"
                >
                  Tilbake
                </button> */}
                  <button
                    type="submit"
                    className="ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-meny-700 hover:bg-meny-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-meny-700"
                  >
                    Neste
                  </button>
                </div>
              </div>
            </div>
          )}
        </form>
        <div className="mb-2 flex justify-center">
          {invalidDate ? (
            <p className="text-red-500">
              Startdato kan ikke være etter sluttdato.
            </p>
          ) : (
            <div></div>
          )}
        </div>
        <div className="mb-2 flex justify-center">
          {invalidDate ? (
            <p className="text-red-500">
              Startdato kan ikke være etter sluttdato.
            </p>
          ) : (
            <div></div>
          )}
        </div>
      </div>
    </MyPageLayout2>
  )
}
// export default EditWork

const EditNewWork = location => {
  // const workId = location?.state?.workId

  const authRequest = {
    ...loginRequest,
  }
  console.log("Hva er location: ", location)
  const workId = location?.state?.workId
  return (
    <MsalAuthenticationTemplate
      interactionType={InteractionType.Redirect}
      authenticationRequest={authRequest}
      errorComponent={ErrorComponent}
      loadingComponent={Loading}
    >
      <EditNewWorkContent props={workId} location={location} />
    </MsalAuthenticationTemplate>
  )
}

export default EditNewWork
