import { useAccount, useMsal } from "@azure/msal-react"
import { Disclosure } from "@headlessui/react"
import { ChevronDownIcon } from "@heroicons/react/solid"
import React, { useEffect, useState } from "react"
import { useForm } from "react-hook-form"
import { Subscription } from "react-hook-form/dist/utils/createSubject"
import { useQuery } from "react-query"
import dateFormat, { i18n } from "dateformat"
import { Contact, Subscriptions } from "../../../Utils/Entities"
import { fetchApi, patchApi } from "../../../Utils/Api.utils"
import { DateTimeToNorwegian } from "../../../Utils/DateFormater"
import { Link, navigate } from "gatsby"
import GetLinkHelpText from "../../common/text/GetLinkHelpText"

function classNames(...classes) {
  return classes.filter(Boolean).join(" ")
}

const Membership = props => {
  const subscription: Subscriptions = props?.subscription
  const forsikring: Subscriptions = props?.forsikring

  const [contact, setContact] = useState<Contact>()

  const profileInfo = useQuery(
    "contactInfo",
    () =>
      fetchApi(
        `${process.env.GATSBY_API_URL}/Contacts/me`,
        account,
        inProgress,
        instance
      ).then(res => res.data),
    {
      onSuccess: data => {
        setContact(data)
      },
      onError: _error => {},
    }
  )

  const content = {
    header: "Ansvarsforsikring",
    key: "ansvar",
  }
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<Subscriptions>()

  const startDate = DateTimeToNorwegian(subscription?.startDate)
  const endDate = DateTimeToNorwegian(subscription?.endDate)
  const [status, setStatus] = useState<string>("")
  // const [url, setURL] = useState<string>()
  // const [isStudent, setIsStudent] = useState<boolean>(false)
  const [number, setNumber] = useState<number>()

  useEffect(() => {
    if (subscription?.liabilityInsurance) setStatus("Aktiv")
    else setStatus("Ikke aktiv")

    if (subscription?.membershipCategory?.name !== "Student")
      setNumber(1005) // helptext number ordinary member
    // setURL(
    //   "https://www.psykologforeningen.no/content/download/59822/933119/version/1/file/Forsikringsbevis%2Bmedlem%2Bprofesjonsansvar%2B2022.pdf"
    // )
    else setNumber(1004) // helptext number student
    // setURL(
    //   "https://www.psykologforeningen.no/content/download/59823/933123/version/1/file/Forsikringsbevis%2Bstudenmedlem%2Bprofesjonsansvar%2B2022.pdf"
    // )
  }, [])

  const [enabled, setEnabled] = useState<boolean>(
    !subscription?.liabilityInsurance
  )

  const { instance, accounts, inProgress } = useMsal()
  const account = useAccount(accounts[0] || {})

  const checkboxChanged = value => {
    setEnabled(value)
  }

  const updateForsikring = data => {
    data.liabilityInsurance = !data.liabilityInsurance
    const url = `${process.env.GATSBY_API_URL}/Subscriptions/${subscription?.id}`
    patchApi(url, data, account, inProgress, instance).then(res => {
      window.setTimeout(() => {
        window.location.reload()
      }, 1500)
    })
  }
  return (
    <div>
      <Disclosure as="div" key={subscription?.id} className="pt-6">
        {({ open }) => (
          <>
            <form
              className="mt-6
                  space-y-6
                  
                  bg-white
                  rounded-lg
                  shadow
                  px-5
                  py-6
                  sm:px-6"
              action="#"
              method="PATCH"
              onSubmit={handleSubmit(updateForsikring)}
            >
              <dt className="text-lg">
                <Disclosure.Button
                  key={subscription?.id}
                  className="text-left w-full flex justify-between items-start text-gray-400"
                >
                  <span className="font-medium text-gray-900">
                    {subscription?.subscriptionTypeName} -{" "}
                    {subscription?.parentOrganizationUnit?.name}
                  </span>
                  <span className="ml-6 h-7 flex items-center">
                    <ChevronDownIcon
                      className={classNames(
                        open ? "-rotate-180" : "rotate-0",
                        "h-6 w-6 transform"
                      )}
                      aria-hidden="true"
                    />
                  </span>
                </Disclosure.Button>
              </dt>
              <Disclosure.Panel as="dd" className="mt-2 pr-12">
                <div className="sm:grid sm:grid-cols-2 gap-3 mb-4">
                  <p className="text-base text-gray-600 sm:col-span-1">
                    Medlemskategori : {subscription?.membershipCategory?.name}
                  </p>
                  {subscription?.organizationUnit !== undefined &&
                  subscription?.parentOrganizationUnit?.id ===
                    "1331728e-2078-ec11-8d21-000d3aa9614b" ? (
                    <p className="text-base text-gray-600 sm:col-span-1">
                      Lokalavdeling: {subscription?.organizationUnit?.name}
                    </p>
                  ) : (
                    <div></div>
                  )}
                  <p className="text-base text-gray-600 sm:col-span-1">
                    Startdato: {startDate}
                  </p>

                  {subscription?.endDate !== undefined ? (
                    <p className="text-base text-gray-600 sm:col-span-1">
                      Dato avsluttet: {endDate}
                    </p>
                  ) : (
                    <div>
                      {subscription?.parentOrganizationUnit?.id ===
                        "1331728e-2078-ec11-8d21-000d3aa9614b" &&
                      contact?.erpNumber !== undefined ? (
                        <p className="text-base text-gray-600 sm:col-span-1">
                          Medlemsnummer: {contact?.erpNumber}
                        </p>
                      ) : null}
                    </div>
                  )}
                </div>
                {subscription?.membershipCategory?.name !== "Student" &&
                subscription?.parentOrganizationUnit?.id ===
                  "1331728e-2078-ec11-8d21-000d3aa9614b" &&
                (subscription?.endDate === undefined ||
                  subscription?.endDate === null) ? (
                  <>
                    <h4 className="mt-4 font-medium text-meny-700">
                      Ansvarsforsikring
                    </h4>
                    <p className="italic text-gray-900 ">
                      Ved innmelding er du dekket av en kollektiv
                      ansvarsforsikring. Vi anbefaler deg å ha denne
                      forsikringen. Forsikringspremien kommer i tillegg til
                      kontingenten, og betales på egen giro/faktura.
                    </p>
                    <div className="sm:grid sm:grid-cols-2 gap-3 mt-4">
                      <p className="sm:col-span-1 text-base text-gray-600">
                        Status: {status}
                      </p>
                      {forsikring !== null ? (
                        <p className="sm:col-span-1 text-base text-gray-600">
                          Startdato:{" "}
                          {DateTimeToNorwegian(forsikring?.startDate)}
                        </p>
                      ) : (
                        <div></div>
                      )}
                    </div>

                    <div className="mr-10 relative flex items-start py-4">
                      <div className="min-w-0 flex-1 text-sm">
                        <p id="comments-description" className="text-gray-600">
                          Jeg har lest vilkårene, men ønsker å{" "}
                          <span className="font-bold">reservere</span> meg fra
                          ansvarsforsikringen.
                        </p>
                      </div>
                      <div className="ml-3 flex items-center h-5 mt-2">
                        <input
                          {...register("liabilityInsurance")}
                          id="liabilityInsurance"
                          key="liabilityInsurance"
                          type="checkbox"
                          defaultChecked={enabled}
                          // value={event?.target.checked}
                          onChange={checkboxChanged}
                          className="text-meny-600 relative justify-end inline-flex flex-shrink-0 h-6 w-11 border-2 border-gray-300 rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-meny-700"
                        />
                      </div>
                    </div>

                    <div className="flex justify-center py-4">
                      <button
                        type="submit"
                        className="ml-1 inline-flex justify-center py-2 px-8 border border-transparent shadow-sm text-md font-medium rounded-md text-white bg-meny-700 hover:bg-meny-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-meny-600"
                      >
                        Oppdater
                      </button>
                    </div>
                  </>
                ) : (
                  <div></div>
                )}

                {subscription?.parentOrganizationUnit?.id ===
                  "1331728e-2078-ec11-8d21-000d3aa9614b" &&
                (subscription?.endDate === undefined ||
                  subscription?.endDate === null) ? (
                  <>
                    <div className="mb-2">
                      <a
                        className="hover:cursor-pointer underline hover:text-meny-700 hover:font-bold "
                        target="_blank"
                        href="https://www.storebrand.no/site/akademikerne.nsf/profesjon-psykologforeningen/psykologforeningen"
                      >
                        Forsikringsvilkår
                      </a>
                    </div>
                    <div className="mb-2">
                      {subscription?.liabilityInsurance ? (
                        //
                        <>
                          <GetLinkHelpText
                            number={number}
                            text={"Last ned forsikringsbevis"}
                          />
                        </>
                      ) : (
                        <></>
                      )}
                    </div>
                  </>
                ) : (
                  <></>
                )}
              </Disclosure.Panel>
            </form>
          </>
        )}
      </Disclosure>
    </div>
  )
}

export default Membership
