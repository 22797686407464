import React, { useState, useEffect } from "react"
import { useForm } from "react-hook-form"
import { Account } from "../../../Utils/Entities"
import { ErrorMessage } from "@hookform/error-message"
import { navigate } from "gatsby"
import ReactDatePicker, { registerLocale } from "react-datepicker"
import nb from "date-fns/locale/nb"
import "react-datepicker/dist/react-datepicker.css"
import { useAccount, useMsal } from "@azure/msal-react"
import { fetchApi } from "../../../Utils/Api.utils"
import { useQuery, useQueryClient } from "react-query"
import {
  Agreementspecialist,
  CheckForClient,
  TypePrivatePractitioner,
  WorkCategory,
} from "../../../Utils/optionSet"
registerLocale("nb", nb)
import Select from "react-select"
import useNewAccount from "../../hooks/newEmploymentAsIndependent/useNewAccount"
import useNewEmploymentAsIndependent from "../../hooks/newEmploymentAsIndependent/useNewEmploymentAsIndependent"

// type FormValues = Employment

export const NewEmploymentAsIndependent = ({
  roleOption,
  professionallyActiveStatus,
  prevEmploymentData,
  setShowLoadingData,
}) => {
  const {
    register,
    handleSubmit,
    setValue,
    control,
    formState: { errors },
  } = useForm({ shouldUnregister: true })

  const queryClient = useQueryClient()

  const [bedrift, setAccounts] = useState<Account[]>()
  const [parent, setParent] = useState<string>()
  const [position, setPosition] = useState<string>()

  const [positions, setPositions] = useState<any[]>()

  const [smedlem, setSMedlem] = useState<boolean>(false)

  const [employerAndWorkplace, setEmployerAndWorkplace] =
    useState<boolean>(false)

  const [workcategory, setWorkcategory] = useState<number>(0)
  const [typePrivatePractitioner, setTypePrivatePractitioner] =
    useState<number>(0)
  const [agreementspecialist, setAgreementspecialist] = useState<number | null>(
    null
  )
  const [accountWithOrgNumber, setAccountWithOrgNumber] = useState("")
  const [accountWithOrgNumberExist, setAccountWithOrgNumberExist] =
    useState<boolean>(false)
  const [clientAccountWithOrgNumber, setClientAccountWithOrgNumber] =
    useState("")
  const [showEmpAlt, setShowEmpAlt] = useState<boolean>(false)
  const [employerError, setEmployerError] = useState<string>("")

  const [orgNumber, setOrgNumber] = useState<string>("")
  const [zipCode, setZipCode] = useState<string>("")
  const [zipCodeData, setZipCodeData] = useState("")
  const [zipCodeError, setZipCodeError] = useState<boolean>(false)

  const [selectedClientAccount, setSelectedClientAccount] = useState<string>()
  const [worksForClient, setWorksForClient] = useState<string>("")

  const { instance, accounts, inProgress } = useMsal()
  const account = useAccount(accounts[0] || {})
  const [accountName, setAccountName] = useState(
    account?.name + ", Privatpraksis"
  )

  const [remainingData, setRemainingData] = useState<object>({})

  const [incidentDescription, setIncidentDescription] = useState<string>("")
  const [clientAccountName, setClientAccountName] = useState<string>("")
  const [clientAccountAddress, setClientAccountAddress] = useState<string>("")
  const [clientAccountZipCode, setClientAccountZipCode] = useState<string>("")
  const [clientAccountZipCodeData, setClientAccountZipCodeData] = useState("")
  const [clientAccountZipCodeError, setClientAccountZipCodeError] =
    useState<boolean>(false)
  const [clientOrgNumber, setClientOrgNumber] = useState<string>("")
  const [confirmClient, setConfirmClient] = useState<boolean>(false)

  const [startDate, setStartDate] = useState<Date>(() => {
    const endDate = new Date(prevEmploymentData?.endDate)
    endDate.setDate(endDate.getDate() + 1)
    return endDate
  })

  const [originalStartDate, setOriginalStartDate] = useState<Date>(() => {
    const endDate = new Date(prevEmploymentData?.endDate)
    endDate.setDate(endDate.getDate() + 1)
    return endDate
  })

  const [role, setRole] = useState<number>(
    roleOption === undefined ? 125600000 : 0
  )

  // Set the predifined values for the form if workcategory === 292460001 && typePrivatePractitioner === 292460000
  useEffect(() => {
    if (workcategory === 292460001 && typePrivatePractitioner === 292460000) {
      setAccountName(account?.name + ", Privatpraksis")
    } else {
      setAccountName("") // Reset
    }
  }, [workcategory, typePrivatePractitioner])

  const eventQuery = useQuery(
    "myEvents",
    async () =>
      fetchApi(
        process.env.GATSBY_API_URL + `/Events/MyEvents`,
        account,
        inProgress,
        instance
      ).then(res => res.data),
    {}
  )

  useEffect(() => {
    const getSub = async () => {
      fetchApi(
        `${process.env.GATSBY_API_URL}/Contacts/Subscriptions`,
        account,
        inProgress,
        instance
      ).then(response => {
        response?.data.forEach(sub => {
          if (
            sub?.parentOrganizationUnit?.name == "Norsk Psykologforening" &&
            sub?.subscriptionTypeName == "Medlemskap" &&
            sub?.stateCode == 0 &&
            sub?.membershipCategory?.name == "Student"
          ) {
            setSMedlem(true)
          }
        })
      })
    }
    getSub()
  }, [])

  const CheckOrgNumber = useQuery(
    "CheckOrgNumber",
    async () =>
      fetchApi(
        process.env.GATSBY_API_URL +
          `/Accounts/AccountNumberSearch/` +
          orgNumber,
        account,
        inProgress,
        instance
      ).then(res => res.data),
    {
      enabled: orgNumber.length === 9,
      onSuccess: data => {
        setShowLoadingData(false)
        setAccountWithOrgNumber(data)
        if (data === null || data === "") {
          setAccountWithOrgNumberExist(false)
        } else {
          setAccountWithOrgNumberExist(true)
        }
      },
      onError: error => {
        setShowLoadingData(false)
      },
    }
  )

  // Search for "Organisasjonsnr"
  const findOrgNumber = e => {
    setEmployerAndWorkplace(false)
    setOrgNumber(e.target.value)
    if (e.target.value.length < 9) {
      setAccountWithOrgNumber("")
      setShowEmpAlt(false)
      setClientAccountWithOrgNumber("")
      setZipCode("")
      setZipCodeData("")
      setZipCodeError(false)
      setWorksForClient("")
    }
  }

  const CheckClientOrgNumber = useQuery(
    "CheckCleintOrgNumber",
    async () =>
      fetchApi(
        process.env.GATSBY_API_URL +
          `/Accounts/AccountNumberSearch/` +
          clientOrgNumber,
        account,
        inProgress,
        instance
      ).then(res => res.data),
    {
      enabled: clientOrgNumber.length === 9,
      onSuccess: data => {
        setClientAccountWithOrgNumber(data)
        setShowLoadingData(false)
      },
      onError: error => {
        setShowLoadingData(false)
      },
    }
  )

  // Search for "Oppdragsgiver" with "Organisasjonsnr"
  const findClientOrgNumber = e => {
    setClientOrgNumber(e.target.value)
    if (e.target.value.length < 9) {
      setClientAccountWithOrgNumber("")
    }
  }

  const PostalCodes = useQuery(
    "CheckPostalCodes",
    async () =>
      fetchApi(
        process.env.GATSBY_API_URL + `/Addresses/PostalCodes/` + zipCode,
        account,
        inProgress,
        instance
      ).then(res => res.data),
    {
      enabled: zipCode.length === 4,
      retry: 0,
      onSuccess: data => {
        setZipCodeData(data)
        setZipCodeError(false)
        setShowLoadingData(false)
      },
      onError: error => {
        setZipCodeError(true)
        setShowLoadingData(false)
      },
    }
  )

  // Search for "ZipCode"
  const findZipCode = e => {
    setZipCode(e.target.value)
    if (e.target.value.length < 4) {
      setZipCodeData("")
      setZipCodeError(false)
    }
  }

  const ClientPostalCode = useQuery(
    "CheckClientPostalCodes",
    async () =>
      fetchApi(
        process.env.GATSBY_API_URL +
          `/Addresses/PostalCodes/` +
          clientAccountZipCode,
        account,
        inProgress,
        instance
      ).then(res => res.data),
    {
      enabled: clientAccountZipCode.length === 4,
      retry: 0,
      onSuccess: data => {
        setClientAccountZipCodeData(data)
        setClientAccountZipCodeError(false)
        setShowLoadingData(false)
      },
      onError: error => {
        setClientAccountZipCodeError(true)
        setShowLoadingData(false)
      },
    }
  )

  // Search for "ClientAccountZipCode"
  const findClientZipCode = e => {
    setClientAccountZipCode(e.target.value)
    if (e.target.value.length < 4) {
      setClientAccountZipCodeData("")
      setClientAccountZipCodeError(false)
    }
  }

  const employersQuery = useQuery(
    "employers",
    async () =>
      fetchApi(
        `${process.env.GATSBY_API_URL}/Accounts/Employers`,
        account,
        inProgress,
        instance
      ).then(res => res.data),
    {
      onSuccess: data => {
        setAccounts(data)
      },
    }
  )

  const updateWorkPlace = selectedOption => {
    setParent(selectedOption.value)
    if (selectedOption.value != "") {
      fetchApi(
        `${process.env.GATSBY_API_URL}/Accounts/${selectedOption.value}`,
        account,
        inProgress,
        instance
      )
        .then(response => {
          setSelectedClientAccount(response.data)
        })
        .catch(error => {})
    }
  }

  const newAccount = useNewAccount(
    prevEmploymentData,
    professionallyActiveStatus,
    setShowLoadingData,
    eventQuery,
    clientAccountWithOrgNumber,
    incidentDescription,
    remainingData
  )

  const newEmploymentAsIndependent = useNewEmploymentAsIndependent(
    prevEmploymentData,
    professionallyActiveStatus,
    setShowLoadingData,
    eventQuery,
    clientAccountWithOrgNumber,
    incidentDescription
  )

  const postWork = async data => {
    data.employment.startDate = startDate

    if (roleOption !== undefined) {
      data.employment.role = roleOption
    }

    //Set statuscode 292460002 if start date is in the future so it can become "Kommende" as statuscode
    if (data.employment.startDate > new Date()) {
      data.employment.statecode = 0
      data.employment.statuscode = 292460002
    }

    // Format the incident description
    if (clientAccountWithOrgNumber === null) {
      const formattedDescription = `Orgnummer: ${clientOrgNumber},
      Navn: ${clientAccountName},
      Adresse: ${clientAccountAddress},
      Postnummer: ${clientAccountZipCode}`
        .split("\n") // Split the string into individual lines
        .map(line => line.trim()) // Trim each line
        .join("\n") // Join the lines back together
      setIncidentDescription(formattedDescription)
    }

    //Check if the user have selected a valid client
    if (
      employerAndWorkplace === false &&
      showEmpAlt === false &&
      worksForClient === "Yes"
    ) {
      setEmployerError("Du må velge en oppdragsgiver")
    } else {
      // Check if the user works for a client
      if (worksForClient === "Yes") {
        // If client employment is selected from the list or not. showEmpAlt = true means that the employer client is not in the list
        if (showEmpAlt) {
          data.employment.clientsName = clientAccountWithOrgNumber?.name
          data.employment.clientOrgNo =
            clientAccountWithOrgNumber?.accountNumber
          data.employment.clientAddress =
            clientAccountWithOrgNumber?.address1_Line1
          data.employment.clientZipCodeId =
            clientAccountWithOrgNumber?.zipCodeId
          data.employment.clientId = clientAccountWithOrgNumber?.id
        } else {
          data.employment.clientsName = selectedClientAccount?.name
          data.employment.clientOrgNo = selectedClientAccount?.accountNumber
          data.employment.clientAddress = selectedClientAccount?.address1_Line1
          data.employment.clientZipCodeId = selectedClientAccount?.zipCodeId
          data.employment.clientId = selectedClientAccount?.id
        }
      }

      // Remove undefined properties from data.employment
      Object.keys(data.employment).forEach(key => {
        if (data.employment[key] === undefined) {
          delete data.employment[key]
        }
      })

      // Privatpraksis + ENK
      if (workcategory === 292460001 && typePrivatePractitioner === 292460000) {
        //Check if there is an account with the orgNumber
        if (accountWithOrgNumber === null) {
          data.account.name = account?.name + ", Privatpraksis"
          data.account.accountNumber = orgNumber
          data.account.countryCodeId = process.env.GATSBY_COUNTRY_CODE
          data.account.address1_Line2 = data.account.address1_Line1
          data.account.zipCodeId = zipCodeData?.id
          data.account.employer = false
          data.account.tariffId = process.env.GATSBY_TARIFF_INDEPENDENT
        } else {
          data.employment.employer = accountWithOrgNumber?.id
        }

        //Check if clientAccountWithOrgNumber is not ""
        if (clientAccountWithOrgNumber !== "") {
          data.employment.clientId = clientAccountWithOrgNumber?.id
        }

        //Check if aggremmentspecialist is "yes" or "no"
        if (agreementspecialist === 1) {
          data.employment.rightOfReimbursment = true
        } else if (agreementspecialist === 0) {
          data.employment.rightOfReimbursment = false
        }

        setRemainingData(data.employment)

        //Check if an incident should be created
        if (accountWithOrgNumberExist === true) {
          setShowLoadingData(true)
          newEmploymentAsIndependent.mutate(data.employment) // Post new employment
        } else {
          setShowLoadingData(true)
          newAccount.mutate(data.account) // Post new account
        }
      }

      // Privatpraksis + Selvstenig AS
      if (workcategory === 292460001 && typePrivatePractitioner === 292460001) {
        //Check if there is an account with the orgNumber
        if (accountWithOrgNumber === null) {
          data.account.accountNumber = orgNumber
          data.account.countryCodeId = process.env.GATSBY_COUNTRY_CODE
          data.account.address1_Line2 = data.account.address1_Line1
          data.account.zipCodeId = zipCodeData?.id
          data.account.employer = true
          data.account.tariffId = process.env.GATSBY_TARIFF_IN_AS
        } else {
          data.employment.employer = accountWithOrgNumber?.id
        }

        //Check if clientAccountWithOrgNumber is not ""
        if (clientAccountWithOrgNumber !== "") {
          data.employment.clientId = clientAccountWithOrgNumber?.id
        }

        //Check if aggremmentspecialist is "yes" or "no"
        if (agreementspecialist === 1) {
          data.employment.rightOfReimbursment = true
        } else if (agreementspecialist === 0) {
          data.employment.rightOfReimbursment = false
        }

        setRemainingData(data.employment)

        if (accountWithOrgNumberExist === true) {
          setShowLoadingData(true)
          newEmploymentAsIndependent.mutate(data.employment)
        } else {
          setShowLoadingData(true)
          newAccount.mutate(data.account)
        }
      }

      // Selvstendig næringsdrivende
      if (workcategory === 292460002) {
        //Check if there is an account with the orgNumber
        if (accountWithOrgNumber === null) {
          data.account.accountNumber = orgNumber
          data.account.countryCodeId = process.env.GATSBY_COUNTRY_CODE
          data.account.address1_Line2 = data.account.address1_Line1
          data.account.zipCodeId = zipCodeData?.id
          data.account.employer = true //Check true or false
          if (typePrivatePractitioner === 292460000) {
            data.account.tariffId = process.env.GATSBY_TARIFF_INDEPENDENT
          } else if (typePrivatePractitioner === 292460001) {
            data.account.tariffId = process.env.GATSBY_TARIFF_IN_AS
          }
        } else {
          data.employment.employer = accountWithOrgNumber?.id
        }

        //Check if clientAccountWithOrgNumber is not ""
        if (clientAccountWithOrgNumber !== "") {
          data.employment.clientId = clientAccountWithOrgNumber?.id
        }

        setRemainingData(data.employment)

        if (accountWithOrgNumberExist === true) {
          setShowLoadingData(true)
          newEmploymentAsIndependent.mutate(data.employment)
        } else {
          setShowLoadingData(true)
          newAccount.mutate(data.account)
        }
      }
    }
  }

  const onClickEmployerNotInList = () => {
    if (showEmpAlt === true) {
      setEmployerError("")
      setShowEmpAlt(false)
      setClientAccountWithOrgNumber("")
    } else {
      setEmployerError("")
      setShowEmpAlt(true)
    }
  }

  const onClickAgreementspecialist = e => {
    setAgreementspecialist(parseInt(e.target.value))
    setWorksForClient("")
  }

  const onClickWorksForClient = e => {
    setWorksForClient(e.target.value)
    setEmployerAndWorkplace(false)
  }

  const changePos = event => {
    setPosition(event.target.value)
  }

  // Set positions based on the selected typePrivatePractitioner and if user have searched for an account with orgNumber
  // Set position based orgNumber if it exists
  // Set position based on typePrivatePractitioner if orgNumber does not exist
  useEffect(() => {
    if (
      accountWithOrgNumber !== "" &&
      accountWithOrgNumber !== null &&
      orgNumber.length === 9
    ) {
      fetchApi(
        `${process.env.GATSBY_API_URL}/Accounts/${accountWithOrgNumber.id}`,
        account,
        inProgress,
        instance
      )
        .then(response => {
          if (response.data.tariff.positions !== undefined) {
            setPositions(response.data.tariff.positions)
          } else {
            setPositions([])
          }
        })
        .catch(error => {})
    } else if (
      typePrivatePractitioner === 292460000 &&
      orgNumber.length === 9
    ) {
      fetchApi(
        `${process.env.GATSBY_API_URL}/Tariff/${process.env.GATSBY_TARIFF_INDEPENDENT}`,
        account,
        inProgress,
        instance
      )
        .then(response => {
          setPositions(response.data.positions)
        })
        .catch(error => {})
    } else if (
      typePrivatePractitioner === 292460001 &&
      orgNumber.length === 9
    ) {
      fetchApi(
        `${process.env.GATSBY_API_URL}/Tariff/${process.env.GATSBY_TARIFF_IN_AS}`,
        account,
        inProgress,
        instance
      )
        .then(response => {
          setPositions(response.data.positions)
        })
        .catch(error => {})
    } else {
      setPositions([])
      setPosition("")
    }
  }, [accountWithOrgNumber, typePrivatePractitioner, orgNumber])

  return (
    <div>
      <div className="">
        <form
          method="POST"
          onSubmit={handleSubmit(postWork)}
          className="space-y-8 "
        >
          <div className="space-y-8 sm:space-y-5">
            <div className="space-y-6sm:space-y-5">
              <div className="space-y-6 sm:space-y-5">
                {smedlem ? (
                  <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:pt-5">
                    <label
                      htmlFor="role"
                      className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                    >
                      Rolle:
                    </label>
                    <div className="mt-1 sm:mt-0 sm:col-span-2">
                      <select
                        {...register("employment.role")}
                        name="employment.role"
                        id="employment.role"
                        key="employment.role"
                        disabled={true}
                        className=" block w-full shadow-sm focus:ring-meny-700 focus:border-meny-500 sm:text-sm border-gray-300 rounded-md bg-gray-50"
                      >
                        {/* <option value={125600000}>Hovedarbeidsgiver</option> */}
                        <option value={125600001}>Biarbeidsgiver</option>
                      </select>
                    </div>
                  </div>
                ) : (
                  <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:pt-5">
                    <label
                      htmlFor="role"
                      className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                    >
                      Rolle:
                    </label>
                    <div className="mt-1 sm:mt-0 sm:col-span-2">
                      <select
                        {...register("employment.role")}
                        name="employment.role"
                        id="employment.role"
                        key="employment.role"
                        disabled={roleOption === undefined ? false : true}
                        onChange={e => setRole(parseInt(e.target.value))}
                        className=" disabled:bg-gray-200 block w-full shadow-sm focus:ring-meny-700 focus:border-meny-500 sm:text-sm border-gray-300 rounded-md"
                      >
                        {roleOption === undefined ? (
                          <>
                            <option value={125600000}>Hovedarbeidsgiver</option>
                            <option value={125600001}>Biarbeidsgiver</option>
                          </>
                        ) : (
                          <option value={roleOption}>
                            {roleOption === 125600000
                              ? "Hovedarbeidsgiver"
                              : "Biarbeidsgiver"}
                          </option>
                        )}
                      </select>
                    </div>
                  </div>
                )}

                <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:pt-5">
                  <label
                    htmlFor="workcategory"
                    className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                  >
                    Er du privatpraktiserende med klinisk praksis, eller driver
                    du som selvstendig næringsdrivende uten klinisk praksis:
                  </label>
                  <div className="mt-1 sm:mt-0 sm:col-span-2">
                    <select
                      {...register("employment.workcategory", {
                        required: true,
                      })}
                      name="employment.workcategory"
                      id="employment.workcategory"
                      key="employment.workcategory"
                      defaultValue={""}
                      onChange={e => {
                        setWorkcategory(parseInt(e.target.value))
                        setClientAccountWithOrgNumber("")
                        setShowEmpAlt(false)
                      }}
                      className=" disabled:bg-gray-200 disabled:text-opacity-50 block w-full shadow-sm focus:ring-meny-700 focus:border-meny-500 sm:text-sm border-gray-300 rounded-md"
                    >
                      <option value="" disabled>
                        -- Velg en type --
                      </option>
                      {WorkCategory?.map(p => (
                        <option key={p.id} value={p.id}>
                          {p.title}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>

                {/* Choose a workcategory */}
                {workcategory !== 0 && (
                  <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:pt-5">
                    <label
                      htmlFor="typePrivatePractitioner"
                      className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                    >
                      {workcategory === 292460001 &&
                        "Driver du privatpraksis som enkeltpersonforetak eller som ansatt i eget AS:"}
                      {workcategory === 292460002 &&
                        "Driver du et enkeltpersonforetak eller som ansatt i eget AS:"}
                    </label>
                    <div className="mt-1 sm:mt-0 sm:col-span-2">
                      <select
                        {...register("employment.typePrivatePractitioner", {
                          required: true,
                        })}
                        name="employment.typePrivatePractitioner"
                        id="employment.typePrivatePractitioner"
                        key="employment.typePrivatePractitioner"
                        defaultValue={""}
                        onChange={e => {
                          setTypePrivatePractitioner(parseInt(e.target.value))
                          setClientAccountWithOrgNumber("")
                          setShowEmpAlt(false)
                        }}
                        className=" disabled:bg-gray-200 disabled:text-opacity-50 block w-full shadow-sm focus:ring-meny-700 focus:border-meny-500 sm:text-sm border-gray-300 rounded-md"
                      >
                        <option value="" disabled>
                          -- Velg en type --
                        </option>
                        {TypePrivatePractitioner?.map(p => (
                          <option key={p.id} value={p.id}>
                            {p.title}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>
                )}

                {/* Choose typePrivatePractitioner */}
                {typePrivatePractitioner !== 0 && (
                  <>
                    <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:pt-5">
                      <label
                        htmlFor="orgNumber"
                        className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                      >
                        Organisasjonsnr:
                      </label>
                      <div className="mt-1 sm:mt-0 sm:col-span-2">
                        <input
                          required
                          type="text"
                          onChange={findOrgNumber}
                          className="block w-full shadow-sm focus:ring-meny-700 focus:border-meny-500 sm:text-sm border-gray-300 rounded-md"
                          maxLength={9}
                          minLength={9}
                        />
                      </div>
                    </div>
                    {/* show if accountWithOrgNumber is not null or "" */}
                    {accountWithOrgNumber === "" ||
                    accountWithOrgNumber === null ? null : (
                      <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:pt-5">
                        <label
                          htmlFor="Organisasjonsnr er koblet mot"
                          className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                        >
                          Organisasjonsnr er koblet mot:
                        </label>
                        <div className="mt-1 pt-2 sm:mt-0 sm:col-span-2">
                          <p>{accountWithOrgNumber?.name}</p>
                        </div>
                      </div>
                    )}
                    {/* if accountWithOrgNumber is null, show message */}
                    {accountWithOrgNumber === null ? (
                      <p className="block text-sm italic font-normal sm:col-span-2 text-meny-700 sm:mt-px sm:pt-2 sm:col-start-3">
                        {workcategory == 292460001 &&
                        typePrivatePractitioner == 292460000
                          ? "Ditt enkeltpersonsforetak"
                          : "Din virksomhet"}{" "}
                        er ikke registrert hos oss, og vil bli automatisk
                        opprettet når du har oppgitt nok informasjon.
                      </p>
                    ) : null}
                  </>
                )}
                {/* Register new Account if accountWithOrgNumber is null */}
                {accountWithOrgNumber === null && (
                  <>
                    {workcategory === 292460001 &&
                      typePrivatePractitioner === 292460000 && (
                        <div
                          className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start  sm:pt-5"
                          key={0}
                          id="0"
                        >
                          <label
                            htmlFor="account.name"
                            className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                          >
                            Firmanavn:
                          </label>
                          <div className="mt-1 sm:mt-0 sm:col-span-2">
                            <input
                              type="text"
                              {...register("account.name")}
                              value={accountName}
                              name="account.name"
                              id="account.name"
                              disabled
                              className=" disabled:bg-gray-200 block w-full shadow-sm focus:ring-meny-700 focus:border-meny-500 sm:text-sm border-gray-300 rounded-md"
                            />
                          </div>
                        </div>
                      )}
                    {((workcategory === 292460001 &&
                      typePrivatePractitioner === 292460001) ||
                      workcategory === 292460002) && (
                      <div
                        className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start  sm:pt-5"
                        key={1}
                        id="1"
                      >
                        <label
                          htmlFor="account.name"
                          className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                        >
                          Navn fra Brønnøysundregistrene:
                        </label>
                        <div className="mt-1 sm:mt-0 sm:col-span-2">
                          <input
                            type="text"
                            {...register("account.name")}
                            name="account.name"
                            id="account.name"
                            required
                            className=" block w-full shadow-sm focus:ring-meny-700 focus:border-meny-500 sm:text-sm border-gray-300 rounded-md"
                          />
                        </div>
                      </div>
                    )}
                    <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start  sm:pt-5">
                      <label
                        htmlFor="orgNumber"
                        className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                      >
                        Organisasjonsnr:
                      </label>
                      <div className="mt-1 sm:mt-0 sm:col-span-2">
                        <input
                          type="text"
                          value={orgNumber}
                          disabled
                          className="disabled:bg-gray-200 block w-full shadow-sm focus:ring-meny-700 focus:border-meny-500 sm:text-sm border-gray-300 rounded-md"
                        />
                      </div>
                    </div>
                    <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start  sm:pt-5">
                      <label
                        htmlFor="account.address1_Line1"
                        className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                      >
                        Adresse:
                      </label>
                      <div className="mt-1 sm:mt-0 sm:col-span-2">
                        <input
                          type="text"
                          {...register("account.address1_Line1")}
                          required
                          name="account.address1_Line1"
                          id="account.address1_Line1"
                          className="disabled:bg-gray-200 block w-full shadow-sm focus:ring-meny-700 focus:border-meny-500 sm:text-sm border-gray-300 rounded-md"
                        />
                      </div>
                    </div>
                    <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start  sm:pt-5">
                      <label
                        htmlFor="account.zipCodeId"
                        className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                      >
                        Postnummer:
                      </label>
                      <div className="mt-1 sm:mt-0 sm:col-span-2 flex flex-col space-y-2">
                        <input
                          type="text"
                          {...register("account.zipCodeId")}
                          required
                          name="account.zipCodeId"
                          id="account.zipCodeId"
                          maxLength={4}
                          minLength={4}
                          onChange={findZipCode}
                          className=" block w-full shadow-sm focus:ring-meny-700 focus:border-meny-500 sm:text-sm border-gray-300 rounded-md"
                        />
                        {zipCodeError && (
                          <p className="text-red-500">Ugyldig postnummer</p>
                        )}
                        {zipCodeData !== "" && (
                          <div className=" ml-2 mt-1 ">
                            <p>{zipCodeData?.postalPlace}</p>
                          </div>
                        )}
                      </div>
                    </div>
                  </>
                )}
                {/* if "Klinisk praksis" is selected and orgNumber registered. Ask for "Avtalespesialist" */}
                {orgNumber.length === 9 && workcategory === 292460001 && (
                  <>
                    <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:pt-5">
                      <label
                        htmlFor="employment.agreementspecialist"
                        className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                      >
                        Er du avtalespesialist:
                      </label>
                      <div className="mt-1 sm:mt-0 sm:col-span-2">
                        <select
                          {...register("employment.agreementspecialist", {
                            required: true,
                            valueAsNumber: true,
                          })}
                          name="employment.agreementspecialist"
                          id="employment.agreementspecialist"
                          key="employment.agreementspecialist"
                          defaultValue={""}
                          onChange={onClickAgreementspecialist}
                          className=" disabled:bg-gray-200 disabled:text-opacity-50 block w-full shadow-sm focus:ring-meny-700 focus:border-meny-500 sm:text-sm border-gray-300 rounded-md"
                        >
                          <option value="" disabled>
                            -- Velg en --
                          </option>
                          {Agreementspecialist?.map(p => (
                            <option key={p.id} value={p.id}>
                              {p.title}
                            </option>
                          ))}
                        </select>
                      </div>
                    </div>
                    {agreementspecialist === 1 && (
                      <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start  sm:pt-5">
                        <label
                          htmlFor="employment.operatingGrants"
                          className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                        >
                          Oppgi driftstilskudd i % (oppgi 0 hvis du ikke har
                          driftstilskudd):
                        </label>
                        <div className="mt-1 sm:mt-0 sm:col-span-2">
                          <input
                            type="number"
                            {...register("employment.operatingGrants", {
                              required: true,
                              valueAsNumber: true,
                            })}
                            max={100}
                            name="employment.operatingGrants"
                            id="employment.operatingGrants"
                            className="block w-full shadow-sm focus:ring-meny-700 focus:border-meny-500 sm:text-sm border-gray-300 rounded-md"
                          />
                        </div>
                      </div>
                    )}
                  </>
                )}
                {orgNumber.length === 9 && (
                  <>
                    {/* If avtalespesialist is "Nei" */}
                    {agreementspecialist === 0 && (
                      <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:pt-5">
                        <label
                          htmlFor="worksForClient"
                          className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                        >
                          Jobber du for en oppdragsgiver?:
                        </label>
                        <div className="mt-1 sm:mt-0 sm:col-span-2">
                          <select
                            required
                            defaultValue={""}
                            onChange={onClickWorksForClient}
                            className=" disabled:bg-gray-200 disabled:text-opacity-50 block w-full shadow-sm focus:ring-meny-700 focus:border-meny-500 sm:text-sm border-gray-300 rounded-md"
                          >
                            <option value="" disabled>
                              -- Velg en --
                            </option>
                            {CheckForClient?.map(p => (
                              <option key={p.id} value={p.id}>
                                {p.title}
                              </option>
                            ))}
                          </select>
                        </div>
                      </div>
                    )}

                    {worksForClient === "Yes" && agreementspecialist === 0 && (
                      <>
                        <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:pt-5">
                          <label
                            htmlFor="employment.clientId"
                            className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                          >
                            Din oppdragsgiver:
                          </label>
                          <div className="mt-1 sm:mt-0 sm:col-span-2">
                            <Select
                              name="employment.clientId"
                              id="employment.clientId"
                              key="employment.clientId"
                              isSearchable
                              options={bedrift?.map(a => ({
                                value: a.id,
                                label: a.name,
                              }))}
                              isDisabled={showEmpAlt}
                              placeholder="-- Oppdragsgivere --"
                              value={
                                parent === null || parent === undefined
                                  ? null
                                  : bedrift?.find(
                                      option => option.name === parent
                                    )
                              }
                              onChange={option => {
                                updateWorkPlace(option)
                                setEmployerAndWorkplace(true)
                                setEmployerError("")
                              }}
                              className=" block w-full shadow-sm focus:ring-meny-700 focus:border-meny-500 sm:text-sm border-gray-300 rounded-md"
                            ></Select>
                          </div>
                        </div>
                        {employerError !== "" && (
                          <p className="text-red-500">{employerError}</p>
                        )}
                        {/* If workplace is not in the list */}
                        <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:pt-5">
                          <label
                            htmlFor="checkbox"
                            className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                          >
                            Min oppdragsgiver er ikke i listen:
                          </label>
                          <div className="pt-4 sm:pt-2.5 h-5 w-5">
                            <input
                              type="checkbox"
                              checked={showEmpAlt}
                              onChange={onClickEmployerNotInList}
                              className="block w-full shadow-sm focus:ring-meny-700 focus:border-meny-500 sm:text-sm border-gray-300 rounded-md"
                            />
                          </div>
                        </div>
                        {/* Search with orgnr */}
                        {showEmpAlt && (
                          <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:pt-5">
                            <label
                              htmlFor="accountNumber"
                              className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                            >
                              Organisasjonsnr for oppdragsgiver:
                            </label>
                            <div className="mt-1 sm:mt-0 sm:col-span-2">
                              <input
                                required
                                type="text"
                                onChange={findClientOrgNumber}
                                className="block w-full shadow-sm focus:ring-meny-700 focus:border-meny-500 sm:text-sm border-gray-300 rounded-md"
                                maxLength={9}
                                minLength={9}
                              />
                            </div>
                          </div>
                        )}
                        {/* show if clientAccountWithOrgNumber is not null or "" */}
                        {clientAccountWithOrgNumber === "" ||
                        clientAccountWithOrgNumber === null ? null : (
                          <>
                            <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:pt-5">
                              <label
                                htmlFor="Organisasjonsnr er koblet mot"
                                className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                              >
                                Oppdragsgiver:
                              </label>
                              <div className="mt-1 pt-2 sm:mt-0 sm:col-span-2">
                                <p>{clientAccountWithOrgNumber?.name}</p>
                              </div>
                            </div>
                            <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:pt-5">
                              <label
                                htmlFor="checkbox"
                                className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                              >
                                Bekreft oppdragsgiver:
                              </label>
                              <div className="pt-4 sm:pt-2.5 h-5 w-5">
                                <input
                                  type="checkbox"
                                  required
                                  onChange={() =>
                                    setConfirmClient(!confirmClient)
                                  }
                                  className="block w-full shadow-sm focus:ring-meny-700 focus:border-meny-500 sm:text-sm border-gray-300 rounded-md"
                                />
                              </div>
                            </div>
                          </>
                        )}
                        {/* if clientAccountWithOrgNumber is null, show message */}
                        {clientAccountWithOrgNumber === null && (
                          <div>
                            <p className="block text-sm italic font-normal sm:col-span-2 text-meny-700 sm:mt-px sm:pt-2 sm:col-start-3">
                              Vi har ikke registrert denne virksomheten i vårt
                              system. Dersom du går videre så vil det opprettes
                              en sak hos oss for å ferdigstille registrering
                            </p>
                            <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:pt-5">
                              <label
                                htmlFor="clientaccountname"
                                className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                              >
                                Oppdragsgiver navn:
                              </label>
                              <div className="mt-1 sm:mt-0 sm:col-span-2">
                                <input
                                  required
                                  type="text"
                                  onChange={e => {
                                    setClientAccountName(e.target.value)
                                  }}
                                  className="block w-full shadow-sm focus:ring-meny-700 focus:border-meny-500 sm:text-sm border-gray-300 rounded-md"
                                />
                              </div>
                            </div>
                            <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:pt-5">
                              <label
                                htmlFor="clientaccountaddress"
                                className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                              >
                                Oppdragsgiver adresse:
                              </label>
                              <div className="mt-1 sm:mt-0 sm:col-span-2">
                                <input
                                  required
                                  type="text"
                                  onChange={e => {
                                    setClientAccountAddress(e.target.value)
                                  }}
                                  className="block w-full shadow-sm focus:ring-meny-700 focus:border-meny-500 sm:text-sm border-gray-300 rounded-md"
                                />
                              </div>
                            </div>
                            <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:pt-5">
                              <label
                                htmlFor="clientaccountpostalcode"
                                className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                              >
                                Oppdragsgiver postnummer:
                              </label>
                              <div className="mt-1 sm:mt-0 sm:col-span-2">
                                <input
                                  required
                                  type="text"
                                  onChange={findClientZipCode}
                                  className="block w-full shadow-sm focus:ring-meny-700 focus:border-meny-500 sm:text-sm border-gray-300 rounded-md"
                                  maxLength={4}
                                  minLength={4}
                                />
                                {clientAccountZipCodeError && (
                                  <p className="text-red-500">
                                    Ugyldig postnummer
                                  </p>
                                )}
                                {clientAccountZipCodeData !== "" && (
                                  <div className=" ml-2 mt-1 ">
                                    <p>
                                      {clientAccountZipCodeData?.postalPlace}
                                    </p>
                                  </div>
                                )}
                              </div>
                            </div>
                          </div>
                        )}
                      </>
                    )}
                    <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start  sm:pt-5">
                      <label
                        htmlFor="position"
                        className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                      >
                        Stilling:
                      </label>
                      <div className="mt-1 sm:mt-0 sm:col-span-2">
                        <select
                          {...register("employment.position")}
                          required
                          name="employment.position"
                          id="employment.position"
                          key="employment.position"
                          value={position}
                          onChange={changePos}
                          className=" block w-full shadow-sm focus:ring-meny-700 focus:border-meny-500 sm:text-sm border-gray-300 rounded-md"
                        >
                          <option disabled selected value="">
                            {" "}
                            -- Stilling --{" "}
                          </option>

                          {positions?.map(p => (
                            <option key={p.id} value={p.id}>
                              {p.jobTitle}
                            </option>
                          ))}
                        </select>
                      </div>
                    </div>
                    <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start  sm:pt-5">
                      <label
                        htmlFor="partTimePosition"
                        className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                      >
                        Prosentvis stilling:
                      </label>
                      <div className="mt-1 sm:mt-0 sm:col-span-2">
                        <input
                          type="text"
                          {...register("employment.partTimePosition", {
                            pattern: {
                              value:
                                roleOption === 125600000 || role === 125600000
                                  ? /^[5-9][0-9]?$|^100$/i
                                  : /^[1-9][0-9]?$|^100$/i,
                              message:
                                roleOption === 125600000 || role === 125600000
                                  ? "Kun tall mellom 50-100 er tillatt"
                                  : "Kun tall mellom 1-100 er tillatt",
                            },
                          })}
                          name="employment.partTimePosition"
                          placeholder={
                            roleOption === 125600000 || role === 125600000
                              ? "50-100"
                              : "1-100"
                          }
                          defaultValue={0}
                          id="employment.partTimePosition"
                          className="block w-full shadow-sm focus:ring-meny-700 focus:border-meny-500 sm:text-sm border-gray-300 rounded-md"
                        />
                        <ErrorMessage
                          errors={errors}
                          name="employment.partTimePosition"
                          render={({ message }) => (
                            <p className="text-sm text-red-600">{message}</p>
                          )}
                        />
                      </div>
                    </div>
                    <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start  sm:pt-5">
                      <label className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                        Startdato:
                      </label>
                      <div className="mt-1 sm:mt-0 sm:col-span-2">
                        <ReactDatePicker
                          locale="nb"
                          required
                          onChange={date => setStartDate(date)}
                          defaultValue={originalStartDate}
                          selected={
                            startDate === undefined
                              ? new Date()
                              : Date.parse(startDate)
                          }
                          dateFormat="dd/MM/yyyy"
                          minDate={originalStartDate}
                          maxDate={(() => {
                            const endDate = new Date(
                              prevEmploymentData?.endDate
                            )
                            return new Date(
                              endDate.getFullYear(),
                              endDate.getMonth() + 4,
                              endDate.getDate()
                            )
                          })()}
                          className=" block w-full shadow-sm focus:ring-meny-700 focus:border-meny-500 sm:text-sm border-gray-300 rounded-md"
                        />
                      </div>
                    </div>
                  </>
                )}
                <div className="pt-5 flex justify-end">
                  <button
                    type="button"
                    onClick={() => navigate("/app/profil/arbeidsforhold")}
                    className="bg-white py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-meny-700"
                  >
                    Tilbake
                  </button>
                  <button
                    type="submit"
                    disabled={zipCodeError || clientAccountZipCodeError}
                    className="disabled:bg-gray-200 disabled:text-opacity-50 ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-meny-700 hover:bg-meny-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-meny-700"
                  >
                    Lagre
                  </button>
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  )
}
