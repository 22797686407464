import { ErrorMessage } from "@hookform/error-message"
import React, { useEffect, useState } from "react"
import { Controller, UseFormReturn } from "react-hook-form"
import { FormValues } from "../../membership/applications/FlowToOrdinary/FlowToOrdinaryTypes"
import useApi from "../../../hooks/useApi"
import { Account } from "../../../../Utils/Entities"
import ReactDatePicker from "react-datepicker"

const ModifyEmployment = (props: {
  index: number
  formController: UseFormReturn<FormValues, any>
}) => {
  const [positions, setPositions] = useState<any[]>()

  const {
    register,
    control,
    formState: { errors },
  } = props.formController

  const id = props.formController.getValues(
    `employments.${props.index}.employer`
  )
  const data = useApi<Account>(`/Accounts/${id}`)

  const startDateString = props?.formController?.getValues(
    `employments.${props.index}.startDate`
  )
  const startDate = startDateString
    ? new Date(
        new Date(startDateString).setDate(
          new Date(startDateString).getDate() + 1
        )
      )
    : undefined

  useEffect(() => {
    if (data.isSuccess) {
      //@ts-ignore
      setPositions(data.data.tariff.positions)
    }
  }, [data])

  return (
    <div className="space-y-4">
      <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start  sm:pt-5">
        <label
          htmlFor={`employments.${props.index}.position`}
          className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
        >
          Stilling:
        </label>
        <div className="mt-1 sm:mt-0 sm:col-span-2">
          <select
            {...register(`employments.${props.index}.position`, {
              required: { value: true, message: "Stilling er påkrevd" },
            })}
            name={`employments.${props.index}.position`}
            id={`employments.${props.index}.position`}
            key={`employments.${props.index}.position`}
            className=" block w-full shadow-sm focus:ring-meny-700 focus:border-meny-500 sm:text-sm border-gray-300 rounded-md"
          >
            <option disabled selected value="">
              {" "}
              -- Stilling --{" "}
            </option>

            {positions?.map(p => (
              <option key={p.id} value={p.id}>
                {p.jobTitle}
              </option>
            ))}
          </select>
          <ErrorMessage
            errors={errors}
            name={`employments.${props.index}.position`}
            render={({ message }) => (
              <p className="text-sm text-red-600">{message}</p>
            )}
          />
        </div>
      </div>

      <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start  sm:pt-5">
        <label
          htmlFor={`employments.${props.index}.partTimePosition`}
          className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
        >
          Prosentvis stiling:
        </label>
        <div className="mt-1 sm:mt-0 sm:col-span-2">
          <input
            type="number"
            {...register(`employments.${props.index}.partTimePosition`, {
              valueAsNumber: true,
              min: {
                value:
                  props.formController.getValues(
                    `employments.${props.index}.role`
                  ) === 125600000
                    ? 50
                    : 1,
                message:
                  props.formController.getValues(
                    `employments.${props.index}.role`
                  ) === 125600000
                    ? "Prosentvis stilling kan ikke være under 50"
                    : "Prosentvis stilling kan ikke være under 1",
              },
              max: {
                value: 100,
                message: "Prosentvis stilling kan ikke være over 100",
              },
              required: {
                value: true,
                message: "Prosentvis stilling er påkrevd",
              },
            })}
            name={`employments.${props.index}.partTimePosition`}
            placeholder={
              props.formController.getValues(
                `employments.${props.index}.role`
              ) == 125600000
                ? "50-100"
                : "1-100"
            }
            id={`employments.${props.index}.partTimePosition`}
            className="block w-full shadow-sm focus:ring-meny-700 focus:border-meny-500 sm:text-sm border-gray-300 rounded-md"
          />
          <ErrorMessage
            errors={errors}
            name={`employments.${props.index}.partTimePosition`}
            render={({ message }) => (
              <p className="text-sm text-red-600">{message}</p>
            )}
          />
        </div>
      </div>

      <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:pt-5">
        <label
          htmlFor={`employments.${props.index}.endDate`}
          className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
        >
          Dato for endring:
        </label>
        <div className="mt-1 sm:mt-0 sm:col-span-2">
          <Controller
            rules={{ required: { message: "Sluttdato påkrevet", value: true } }}
            control={control}
            name={`employments.${props.index}.endDate`}
            render={({ field: { onChange, onBlur, value } }) => (
              <ReactDatePicker
                locale="nb"
                onChange={onChange}
                onBlur={onBlur}
                selected={value ? new Date(value) : undefined}
                dateFormat="dd/MM/yyyy"
                minDate={startDate}
                className="block w-full shadow-sm focus:ring-meny-700 focus:border-meny-500 sm:text-sm border-gray-300 rounded-md"
              />
            )}
          />
          <ErrorMessage
            errors={errors}
            name={`employments.${props.index}.endDate`}
            render={({ message }) => (
              <p className="text-sm text-red-600">{message}</p>
            )}
          />
        </div>
      </div>
    </div>
  )
}

export default ModifyEmployment
