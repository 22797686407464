import {
  MsalAuthenticationTemplate,
  useAccount,
  useMsal,
} from "@azure/msal-react"
import { navigate } from "gatsby"
import React, { useState } from "react"
import { useMutation, useQuery, useQueryClient } from "react-query"
import DocumentList from "../../mypagecomponents/document/DocumentList"
import FileUpload from "../../mypagecomponents/document/FileUpload"
import { DropDown } from "../../common/inputFields/DropDown"
import MyPageLayout from "../../common/layouts/myPageLayout"
import {
  fetchApi,
  patchApi,
  postApi,
  postGetDocumentApi,
} from "../../../Utils/Api.utils"
import { toast } from "react-toastify"
import { useForm } from "react-hook-form"
import { NewIncidentVal } from "../../../Utils/Types"
import { ErrorMessage } from "@hookform/error-message"
import { loginRequest } from "../../security/authConfig"
import { InteractionType } from "@azure/msal-browser"
import { ErrorComponent } from "../../common/graphichs/ErrorComponent"
import { Loading } from "../../../Utils/Loading"
import LoadingData from "../../common/graphichs/LoadingData"
import { cyanButton } from "../../../styles/tailwindClasses"
import HelperText from "../../common/text/HelperText"
import { DropDownPresetValues } from "../../common/inputFields/DropDownPresetValues"

const NewIncidentContent = ({ presetValues }) => {
  const queryClient = useQueryClient()
  const { instance, accounts, inProgress } = useMsal()
  const account = useAccount(accounts[0] || {})
  const [showDocumentComponent, setShowDocumentComponent] = useState(false)
  const [showCaseButtons, setShowCaseButtons] = useState(true)
  const [title, setTitle] = useState(
    presetValues === undefined ? "" : presetValues.title
  )
  const [description, setDescription] = useState(
    presetValues === undefined ? "" : presetValues.description
  )
  const [incidentType, setIncidentType] = useState(
    presetValues === undefined ? 0 : presetValues.incidentType
  )
  // const [incidentStatus, setIncidentStatus] = useState(1)
  const [incidentId, setIncidentId] = useState("")
  const [documents, setDocuments] = useState([])
  const [caseTypeCodes, setCaseTypeCodes] = useState([])
  const [showLoadingData, setShowLoadingData] = useState(false)
  const [withFile, setWithFile] = useState<boolean>(false)
  const {
    register,
    handleSubmit,
    reset,
    setError,
    clearErrors,
    resetField,
    formState: { errors, isDirty, isValid },
  } = useForm<NewIncidentVal>()

  const caseTypeCodeQuery = useQuery(
    "userIncidentsData",
    () =>
      fetchApi(
        process.env.GATSBY_API_URL + "/OptionSets/casetypecode/incident",
        account,
        inProgress,
        instance
      ).then(res => res.data),
    {
      onSuccess: data => {
        //remove all above 10 in value
        data = data.filter(
          element => element.value < 10 || element.value === 20
        )
        data = data.filter(element => element.label !== "Question")
        data = data.filter(element => element.label !== "Problem")
        data = data.filter(element => element.label !== "Request")
        data = data.filter(element => element.label !== "")

        setCaseTypeCodes(data)
      },
      onError: _error => {},
    }
  )

  const mutationGetDocuments = useMutation(
    () => {
      return postGetDocumentApi(
        process.env.GATSBY_API_URL + "/Documents/Regarding/Get",
        {
          CaseId: incidentId,
        },
        account,
        inProgress,
        instance
      ).then(res => res.data)
    },
    {
      onSuccess: data => {
        setDocuments(data)
      },
      onError: _error => {},
      onSettled: () => {},
    }
  )

  const mutationCreateIncident = useMutation(
    () => {
      return postApi(
        process.env.GATSBY_API_URL + "/Incidents",
        {
          title: title,
          description: description,
          caseTypeCode: incidentType,
          caseOriginCode: 3,
          status: 1,
          state: 0,
        },
        account,
        inProgress,
        instance
      ).then(res => res.data)
    },
    {
      onSuccess: data => {
        setIncidentId(data)
        toast.success(
          "Saken er opprettet! Du vil nå bli videreført til dine saker.",
          {
            position: "top-center",
            autoClose: 5000,
          }
        )
        mutationCreateMessage.mutate()
        queryClient.invalidateQueries(["userIncidentData"])
      },
      onError: _error => {},
      onSettled: () => {
        // setShowLoadingData(false)
      },
    }
  )

  const mutationCreateIncidentWithFile = useMutation(
    () => {
      return postApi(
        process.env.GATSBY_API_URL + "/Incidents",
        {
          title: title,
          description: description,
          caseTypeCode: incidentType,
          caseOriginCode: 3,
          status: 292460000, //Draft
          state: 0,
        },
        account,
        inProgress,
        instance
      ).then(res => res.data)
    },
    {
      onSuccess: data => {
        setIncidentId(data)
        queryClient.invalidateQueries(["userIncidentData"])
        setShowDocumentComponent(true)
        setShowCaseButtons(false)
        setShowLoadingData(false)
        // mutationCreateMessage.mutate()
        toast.success("Saken er opprettet! Du kan nå laste opp dokumenter.", {
          position: "top-center",
          autoClose: 5000,
        })
      },
      onError: _error => {},
      onSettled: () => {
        setShowLoadingData(false)
      },
    }
  )

  const mutationUpdateIncident = useMutation(
    () => {
      return patchApi(
        process.env.GATSBY_API_URL + "/Incidents",
        {
          id: incidentId,
          title: title,
          description: description,
          caseTypeCode: incidentType,
          caseOriginCode: 3,
          status: 1,
          state: 0,
        },
        account,
        inProgress,
        instance
      ).then(res => res.data)
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["userIncidentData"])
        mutationCreateMessage.mutate()
        toast.success(
          "Saken er opprettet! Du vil nå bli videreført til dine saker.",
          {
            position: "top-center",
            autoClose: 5000,
          }
        )
      },
      onError: _error => {
        setShowLoadingData(false)
      },
      onSettled: () => {},
    }
  )

  const mutationCreateMessage = useMutation(
    () => {
      return postApi(
        process.env.GATSBY_API_URL + "/Messages",
        {
          subject: title,
          description: description,
          direction: false,
          regardingObjectIncidentId: incidentId,
        },
        account,
        inProgress,
        instance
      ).then(res => res.data)
    },
    {
      onSuccess: data => {
        queryClient.invalidateQueries(["userMessagesData"])
        setShowLoadingData(false)
        // if (!withFile) {
        setTimeout(() => {
          navigate("/app/dialog/minesaker")
        }, 4000)
        // }
      },
      onError: _error => {
        setShowLoadingData(false)
      },
      onSettled: () => {},
    }
  )

  const postIncident = async e => {
    setWithFile(false)
    e.preventDefault()

    if (description === "" || incidentType === 0) {
      setShowLoadingData(false)
      toast.warning("Vennligst fyll ut alle obligatoriske felter!", {
        position: "top-center",
        autoClose: 10000,
      })
      return
    }

    setShowLoadingData(true)
    mutationCreateIncident.mutate()
  }

  const postIncidentWithFile = async e => {
    setWithFile(true)
    e.preventDefault()

    if (title === "" || description === "" || incidentType === 0) {
      toast.warning("Vennligst fyll ut alle obligatoriske felter!", {
        position: "top-center",
        autoClose: 10000,
      })
      return
    }

    setShowLoadingData(true)
    mutationCreateIncidentWithFile.mutate()
  }

  const patchIncident = async e => {
    e.preventDefault()
    if (title === "" || description === "" || incidentType === 0) {
      toast.warning("Vennligst fyll ut alle obligatoriske felter!", {
        position: "top-center",
        autoClose: 10000,
      })
      return
    }

    setShowLoadingData(true)
    mutationUpdateIncident.mutate()
  }

  const setIncidentTypeDropDown = incidentTypeValue =>
    setIncidentType(incidentTypeValue)

  return (
    <MyPageLayout props="Ny sak">
      <div>{showLoadingData && <LoadingData subject="Oppretter sak..." />}</div>
      <form
        method="POST"
        onSubmit={postIncident}
        className="space-y-8 divide-y divide-gray-200"
      >
        <div className="space-y-8 divide-y divide-gray-200">
          <div className="pt-8">
            <div>
              <h3 className="text-lg leading-6 font-medium text-meny-700">
                Saksinformasjon
              </h3>
              <HelperText helpertext="Felter markert med * er obligatoriske" />
              {incidentType == 8 ? (
                <HelperText
                  helpertext=' Merk at opplasting av dokumentasjon knyttet til
                spesialistutdanningen (veiledningskontrakter/attester,
                praksisattester, eksterne kurs mv) ikke skal registreres som
                ny sak her, men under Kurs og utdanning - Spesialistutdanning
                og knyttet til det aktuelle programmet
                (fellesprogram/obligatorisk program/valgfritt
                program/vedlikehold). Dokumentasjon som blir innsendt her vil
                ikke bli behandlet og må sendes inn på korrekt måte senere.
                Dersom du skal sende oss en annen henvendelse/spørsmål knyttet
                til spesialistutdanning skal det gjøres her. Velg da type
                "Spesialistutdanning".'
                />
              ) : null}
            </div>
            <div className="mt-6 grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6">
              <div className="sm:col-span-3">
                <label
                  htmlFor="title"
                  className="block text-sm font-medium text-gray-700"
                >
                  Emne*
                </label>
                <div className="mt-1">
                  <input
                    {...register("title", {
                      required: {
                        value: true,
                        message: "Obligatorisk felt.",
                      },
                      minLength: {
                        value: 3,
                        message: "Minimum tegn er 3",
                      },
                      maxLength: {
                        value: 100,
                        message: "Maks tegn er 100",
                      },
                    })}
                    maxLength={100}
                    type="text"
                    name="title"
                    id="title"
                    required
                    disabled={presetValues === undefined ? false : true}
                    defaultValue={title}
                    onChange={e => setTitle(e.target.value)}
                    className="shadow-sm focus:ring-meny-700 focus:border-meny-500 block w-full sm:text-sm border-gray-300 rounded-md"
                  />
                  <ErrorMessage
                    errors={errors}
                    name="title"
                    render={({ message }) => (
                      <p className="text-sm text-red-600">{message}</p>
                    )}
                  />
                </div>
              </div>

              <div className="sm:col-span-3">
                <label
                  htmlFor="country"
                  className="block text-sm font-medium text-gray-700"
                >
                  Type*
                </label>
                <div className="mt-1">
                  {presetValues !== undefined ? (
                    <DropDownPresetValues
                      options={caseTypeCodes}
                      setParentValue={setIncidentTypeDropDown}
                      text={presetValues.optionText}
                    />
                  ) : (
                    <DropDown
                      options={caseTypeCodes}
                      setParentValue={setIncidentTypeDropDown}
                    />
                  )}
                </div>
              </div>

              <div className="sm:col-span-6">
                <label
                  htmlFor="description"
                  className="block text-sm font-medium text-gray-700"
                >
                  Beskrivelse*
                </label>
                <p className="mt-2 text-sm text-gray-600">
                  Skriv noen ord om saken.
                </p>
                <div className="mt-1">
                  <textarea
                    {...register("description")}
                    id="description"
                    name="description"
                    minLength={10}
                    maxLength={2000}
                    onChange={e => setDescription(e.target.value)}
                    rows={3}
                    required
                    className=" h-72 shadow-sm focus:ring-meny-700 focus:border-meny-500 block w-full sm:text-sm border border-gray-300 rounded-md"
                    defaultValue={description}
                  />
                  <ErrorMessage
                    errors={errors}
                    name="description"
                    render={({ message }) => (
                      <p className="text-sm text-red-600">{message}</p>
                    )}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        {showCaseButtons ? (
          <div className="pt-5">
            <div className="flex justify-end">
              <button
                type="button"
                onClick={e => postIncidentWithFile(e)}
                className={cyanButton + " mr-3 inline-flex justify-center "}
              >
                Last opp dokumenter
              </button>
              <button type="submit" className={cyanButton}>
                Send Inn
              </button>
            </div>
          </div>
        ) : null}
      </form>
      {showDocumentComponent ? (
        <div>
          <p className="mt-2 text-sm text-gray-600"></p>
          <DocumentList
            documents={documents}
            mutationGetDocuments={mutationGetDocuments}
            canDelete={true}
          />
          <p className="mt-2 text-sm text-gray-600"></p>
          <FileUpload
            entityId={incidentId}
            entityName={"incident"}
            entityId2={null}
            entityName2={null}
            entityId3={null}
            entityName3={null}
            mutationGetDocuments={mutationGetDocuments}
            setDocuments={null}
            isReturn={false}
            isRequired={false}
          />
          <div className="pt-5">
            <div className="flex justify-center">
              <button
                type="button"
                onClick={e => patchIncident(e)}
                className={cyanButton + " px-4 inline-flex items-center "}
              >
                Send Inn
              </button>
            </div>
          </div>
        </div>
      ) : null}
    </MyPageLayout>
  )
}

const NewIncident = ({ location }) => {
  const authRequest = {
    ...loginRequest,
  }
  const presetValues = location?.state.presetValues
  return (
    <MsalAuthenticationTemplate
      interactionType={InteractionType.Redirect}
      authenticationRequest={authRequest}
      errorComponent={ErrorComponent}
      loadingComponent={Loading}
    >
      <NewIncidentContent presetValues={presetValues} />
    </MsalAuthenticationTemplate>
  )
}

export default NewIncident
