import { Link } from "gatsby"
import React from "react"
import HeaderText from "../../../common/text/Header"

const ChangePensioner = () => {
  return (
    <div className="max-w-3xl mx-auto divide-y-2 divide-gray-200">
      <HeaderText text="Endring fra ordinært medlemskap til pensjonistmedlemskap" />
      <form
        className="mt-6
                  space-y-6
                  
                  bg-white
                  rounded-lg
                  shadow
                  px-5
                  py-6
                  sm:px-6"
        method="POST"
      >
        <h1 className="flex justify-center">
          Delvis yrkesaktive pensjonister eller ikke yrkesaktive pensjonister
          kan søke om varig nedsatt kontingent på 20 % av full kontingent. Det
          kreves at du har sterkt redusert inntekt mer enn 6 måneder i et
          kalenderår.
        </h1>
        <div className="flex justify-center py-4">
          <div>
            <Link to="/app/medlemskap/applications/ordinarytopension">
              <button className="ml-1 inline-flex justify-center py-2 px-8 border border-transparent shadow-sm text-md font-medium rounded-md text-white bg-meny-700 hover:bg-meny-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-meny-600">
                Søk her
              </button>
            </Link>
          </div>
        </div>
        {/* <h2> Trenger tekst fra dere</h2> */}
      </form>
    </div>
  )
}

export default ChangePensioner
