import { Link } from "gatsby"
import React, { ReactChild, useEffect, useState } from "react"
import { DateTimeToDate, DateTimeToDateTime } from "../../../Utils/DateFormater"
import { Accordion } from "../../common/accordion"
import { TextWithIcon } from "../../common/text"
import { EventRegistration, SessionRegistrationCrm } from "./EventType"
import {
  CityOptionsetValueToString,
  StatusAttendee,
  StatusAttendeeToString,
} from "./helpers/Event"

const MyEventAccordionGroup = (props: {
  data: Array<EventRegistration>
  cancelled: boolean
}) => {
  return (
    <>
      <div className="max-w-3xl mx-auto divide-y divide-gray-200 rounded-lg overflow-hidden mb-6">
        {props.data.map(item => (
          <Accordion
            title={item.eventCrm?.name}
            tag={StatusAttendeeToString(item.statusattendee)}
            key={item.eventregistrationid}
          >
            <>
              <div className="w-full my-4 space-y-4">
                <Payment eventReg={item} />
                <Details eventReg={item} />
              </div>
              <div className="w-full my-4">
                <Sessions sessionReg={item.sessionRegistrations || []} />
              </div>
              {props.cancelled ? null : (
                <div className="flex flex-row py-4">
                  <Link
                    to={
                      "/app/kurs/mineKurs/kursRegistrering?id=" +
                      item.eventregistrationid
                    }
                    className="ml-1 inline-flex justify-end py-2 px-8 border border-transparent shadow-sm text-md font-medium rounded-md text-white bg-meny-700 hover:bg-meny-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-meny-700"
                  >
                    Les mer
                  </Link>
                </div>
              )}
            </>
          </Accordion>
        ))}
      </div>
    </>
  )
}

const Details = (props: { eventReg: EventRegistration }) => {
  return (
    <>
      <h1>Detaljer:</h1>
      <div className="flex w-full pb-4">
        <div className="w-full pl-4 space-y-4">
          <TextWithIcon
            text={
              "Startdato: " +
              DateTimeToDateTime(props.eventReg.eventCrm?.eventstartdate)
            }
          />
          <TextWithIcon
            text={
              "Sluttdato: " +
              DateTimeToDateTime(props.eventReg.eventCrm?.eventenddate)
            }
          />
          <TextWithIcon
            text={
              "Påmeldingsfrist: " +
              DateTimeToDateTime(
                props.eventReg.eventCrm?.stopwebsiteregistrationson
              )
            }
          />
        </div>
        <div className="w-full pl-4 space-y-4">
          <TextWithIcon
            text={
              "By: " + CityOptionsetValueToString(props.eventReg.eventCrm?.city)
            }
          />
          <TextWithIcon
            text={"Sted: " + (props.eventReg.eventCrm?.building?.name || "")}
          />
          <TextWithIcon
            text={
              "Total timer: " + (props.eventReg.eventCrm?.amounthours || "")
            }
          />
          <TextWithIcon
            text={
              "Avmeldingsfrist: " +
              DateTimeToDateTime(
                props.eventReg.eventCrm?.stopwebsitecancellationon
              )
            }
          />
        </div>
      </div>
    </>
  )
}

const Payment = (props: { eventReg: EventRegistration }) => {
  const customerName = props.eventReg.customerContact
    ? (props.eventReg.customerContact?.firstname || "") +
      " " +
      (props.eventReg.customerContact?.lastname || "")
    : props.eventReg.customerAccount
    ? props.eventReg.customerAccount.name
    : "ingen betaler registrert"

  return (
    <>
      <h1>Betaling:</h1>
      <div className="flex w-full pb-4">
        <div className="w-full pl-4 space-y-1">
          <TextWithIcon text={"Betaler: " + customerName} />
          <HiddenComponent
            hidden={props.eventReg?.invoicecompanyname === undefined}
          >
            <TextWithIcon
              text={"Firmanavn: " + props.eventReg?.invoicecompanyname}
            />
          </HiddenComponent>
          <HiddenComponent
            hidden={props.eventReg?.invoiceorganizationno === undefined}
          >
            <TextWithIcon
              text={"Orgnr: " + props.eventReg?.invoiceorganizationno}
            />
          </HiddenComponent>
          <HiddenComponent
            hidden={props.eventReg?.invoiceaddressline1 === undefined}
          >
            <TextWithIcon
              text={"Fakturaadresse: " + props.eventReg?.invoiceaddressline1}
            />
          </HiddenComponent>
          <HiddenComponent
            hidden={props.eventReg?.customerpurchaseno === undefined}
          >
            <TextWithIcon
              text={"Fakturaadresse: " + props.eventReg?.customerpurchaseno}
            />
          </HiddenComponent>
          <HiddenComponent
            hidden={props.eventReg?.billtoContactname === undefined}
          >
            <TextWithIcon
              text={"Fakturaadresse: " + props.eventReg?.billtoContactname}
            />
          </HiddenComponent>
        </div>
      </div>
    </>
  )
}

const Sessions = (props: { sessionReg: Array<SessionRegistrationCrm> }) => {
  const [session, setSession] = useState<Array<SessionRegistrationCrm>>()

  useEffect(() => {
    const temp = [...props.sessionReg]

    temp.sort((a, b) => {
      return (
        new Date(a.sessionCrm?.starttime || "").getTime() -
        new Date(b.sessionCrm?.starttime || "").getTime()
      )
    })

    setSession(temp)
  }, [props])

  return (
    <div>
      {session !== undefined && session.length > 0 && (
        <>
          <div className="flex flex-grow">
            <h2 className="w-full">Kurs</h2>
            <h2>Startdato</h2>
          </div>
          <div className="w-full h-px bg-gray-200" />
        </>
      )}

      {session?.map(sessionReg => (
        <div key={sessionReg.name}>
          <div className="flex flex-grow">
            <div className="w-full my-1">{sessionReg.sessionCrm?.name}</div>
            <div className="my-1">
              <div>
                <div className="bg-meny-700 mx-4 text-white text-base px-4 rounded-xl truncate">
                  {StatusAttendeeToString(
                    sessionReg.statusattendee as StatusAttendee
                  )}
                </div>
              </div>
            </div>
            <span className="my-1">
              {DateTimeToDate(sessionReg.sessionCrm?.starttime)}
            </span>
          </div>
          <div className="w-full h-px bg-gray-200" />
        </div>
      ))}
    </div>
  )
}

const HiddenComponent = (props: { hidden: boolean; children: ReactChild }) => {
  if (props.hidden) {
    return <></>
  }

  return <>{props.children}</>
}

export default MyEventAccordionGroup
