import { useAccount, useMsal } from "@azure/msal-react"
import { navigate } from "gatsby"
import { useMutation } from "react-query"
import { toast } from "react-toastify"
import { patchApi, postApi } from "../../../Utils/Api.utils"
import useUpdateEmploymentStatus from "./useUpdateEmploymentStatus"

function useNewEmployment(
  prevEmploymentData,
  professionallyActiveStatus,
  setShowLoadingData,
  eventQuery
) {
  const { instance, accounts, inProgress } = useMsal()
  const account = useAccount(accounts[0] || {})
  const timer = 2500

  const mutationUpdateEmploymentStatus = useUpdateEmploymentStatus(eventQuery)

  const mutationUpdateWork = useMutation(
    data => {
      return patchApi(
        `${process.env.GATSBY_API_URL}/Employments/${prevEmploymentData?.id}`,
        data,
        account,
        inProgress,
        instance
      ).then(res => res.data)
    },
    {
      onSuccess: data => {
        if (professionallyActiveStatus !== undefined) {
          mutationUpdateEmploymentStatus.mutate(professionallyActiveStatus)
        } else {
          if (eventQuery?.data?.eventRegistration?.length > 0) {
            toast.success(
              "Du har en eller flere påmeldinger på kurs, husk å oppdatere fakturamottager på dine deltagelser",
              {
                position: "top-center",
                autoClose: 6000,
              }
            )
          }
          window.setTimeout(() => {
            navigate("/app/profil/arbeidsforhold")
          }, timer)
        }
      },
      onError: _error => {
        setShowLoadingData(false)
        toast.error("Det har skjedd en feil, prøv igjen senere", {
          position: "top-center",
          autoClose: 6000,
        })
      },
      onSettled: () => {},
    }
  )

  const mutationNewEmployment = useMutation(
    data => {
      return postApi(
        `${process.env.GATSBY_API_URL}/Employments`,
        data,
        account,
        inProgress,
        instance
      ).then(res => {
        res.data
      })
    },
    {
      onSuccess: data => {
        if (prevEmploymentData !== undefined) {
          mutationUpdateWork.mutate(prevEmploymentData)
        } else {
          if (professionallyActiveStatus !== undefined) {
            mutationUpdateEmploymentStatus.mutate(professionallyActiveStatus)
          } else {
            if (eventQuery?.data?.eventRegistration?.length > 0) {
              toast.success(
                "Du har en eller flere påmeldinger på kurs, husk å oppdatere fakturamottager på dine deltagelser",
                {
                  position: "top-center",
                  autoClose: 6000,
                }
              )
            }
            window.setTimeout(() => {
              navigate("/app/profil/arbeidsforhold")
            }, timer)
          }
        }
      },
      onError: _error => {
        setShowLoadingData(false)
      },
      onSettled: () => {},
    }
  )
  return mutationNewEmployment
}

export default useNewEmployment
