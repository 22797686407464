import { useAccount, useMsal } from "@azure/msal-react"
import { useMutation, useQueryClient } from "react-query"
import { toast } from "react-toastify"
import useCreateIncidentSimple from "./useCreateIncidentSimple"
import { postApi } from "../../../../Utils/Api.utils"

function useNewEmploymentAsIndependentSimple(
  setShowLoadingData,
  eventQuery,
  clientAccountWithOrgNumber,
  incidentDescription
) {
  const { instance, accounts, inProgress } = useMsal()
  const account = useAccount(accounts[0] || {})

  const queryClient = useQueryClient()

  const mutationCreateIncident = useCreateIncidentSimple(incidentDescription)

  const newEmploymentAsIndependent = useMutation(
    data => {
      return postApi(
        `${process.env.GATSBY_API_URL}/Employments`,
        data,
        account,
        inProgress,
        instance
      ).then(res => res.data)
    },
    {
      onSuccess: data => {
        queryClient.setQueryData("employmentId", data)
        if (eventQuery?.data?.eventRegistration?.length > 0) {
          toast.success(
            "Du har en eller flere påmeldinger på kurs, husk å oppdatere fakturamottager på dine deltagelser",
            {
              position: "top-center",
              autoClose: 6000,
            }
          )
        }
        //Check if an incident should be created
        if (clientAccountWithOrgNumber === null) {
          mutationCreateIncident.mutate()
        }
      },
      onError: _error => {
        setShowLoadingData(false)
      },
      onSettled: () => {},
    }
  )
  return newEmploymentAsIndependent
}

export default useNewEmploymentAsIndependentSimple
