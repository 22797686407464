import { useAccount, useMsal } from "@azure/msal-react"
import { ErrorMessage } from "@hookform/error-message"
import nb from "date-fns/locale/nb"
import React, { useEffect, useState } from "react"
import ReactDatePicker, { registerLocale } from "react-datepicker"
import "react-datepicker/dist/react-datepicker.css"
import { Controller, useFormContext, useWatch } from "react-hook-form"
import Select from "react-select"
import { toast } from "react-toastify"
import { fetchApi } from "../../../../../Utils/Api.utils"
import TreeViewComponent from "../../../../common/treeview/TreeViewComponent"
import useGetEmployers from "../../../../hooks/employers/useGetEmployers"
import { FormValues } from "./FlowToOrdinaryTypes"

registerLocale("nb", nb)

export const NewEmploymentAsEmployee = () => {
  const {
    register,
    setValue,
    control,
    getValues,
    formState: { errors },
  } = useFormContext<FormValues>()

  const watchEmployments = useWatch({ name: "employments" })

  const [parent, setParent] = useState<string>()
  const [position, setPos] = useState<string>()
  const [workplaces, setWorkPlaces] = useState<any[]>([])

  const [positions, setPositions] = useState<any[]>()

  const [workPlace, setWorkPlace] = useState<string>("")

  const [employerAndWorkplace, setEmployerAndWorkplace] =
    useState<boolean>(false)

  const startDateLimit = getValues("employments")?.find(work => {
    return work.role === 125600000 && work.action === "cancel"
  })?.endDate

  const [startDate, setStartDate] = useState<Date>(() => {
    const endDate = new Date(startDateLimit || new Date())
    endDate.setDate(endDate.getDate() + 1)
    return endDate
  })

  const maxDate = new Date(
    startDate.getFullYear(),
    startDate.getMonth() + 4,
    startDate.getDate()
  )

  useEffect(() => {
    const limit = getValues("employments")?.find(work => {
      return work.role === 125600000 && work.action === "cancel"
    })?.endDate

    if (limit) {
      const startDateLimit = new Date(limit)
      startDateLimit.setDate(startDateLimit.getDate() + 1)
      setStartDate(startDateLimit)
    }
  }, [watchEmployments])

  const [treeViewLoading, setTreeViewLoading] = useState<boolean>(false)
  const [notAWorkplace, setNotAWorkplace] = useState(false)
  const [lastSelectedItem, setLastSelectedItem] = useState(null)
  const [lastSelectedItemId, setLastSelectedItemId] = useState(null)
  const [employerName, setEmployerName] = useState(null)
  const [employerId, setEmployerId] = useState(null)
  const [workplaceError, setWorkplaceError] = useState(false)

  const treeViewProps = {
    notAWorkplace,
    lastSelectedItem,
    lastSelectedItemId,
    employerName,
    employerId,
  }

  const setTreeViewProps = {
    setNotAWorkplace,
    setLastSelectedItem,
    setLastSelectedItemId,
    setEmployerName,
    setEmployerId,
  }

  const { instance, accounts, inProgress } = useMsal()
  const account = useAccount(accounts[0] || {})

  const bedrift = useGetEmployers()?.data

  useEffect(() => {
    if (parent !== undefined) {
      const workPlaceQuery = async (id: string) => {
        fetchApi(
          `${process.env.GATSBY_API_URL}/Accounts/tree/` + id,
          account,
          inProgress,
          instance
        ).then(res => {
          setWorkPlaces(res.data)
          setTreeViewLoading(false)
        })
      }
      workPlaceQuery(parent) // Call the async function correctly
    }
  }, [parent])

  const updateWorkPlace = selectedOption => {
    setTreeViewLoading(true)
    setWorkPlace(selectedOption.label)
    setParent(selectedOption.value)
    setWorkplaceError(false)
  }

  const changePos = event => {
    setPos(event.target.value)
  }

  useEffect(() => {
    const employer = employerId === null ? parent : employerId

    if (employer) {
      setValue("newEmployment.employer", employer)
    }
  }, [employerId, parent])

  useEffect(() => {
    const employer = employerId === null ? parent : employerId
    const workplace = employerAndWorkplace ? employer : lastSelectedItemId

    if (workplace) {
      setValue("newEmployment.workPlace", workplace)
      setWorkplaceError(false)
    } else {
      setWorkplaceError(true)
    }
  }, [employerAndWorkplace, parent, lastSelectedItemId])

  useEffect(() => {
    if (treeViewProps.employerId !== null) {
      fetchApi(
        `${process.env.GATSBY_API_URL}/Accounts/${treeViewProps.employerId}`,
        account,
        inProgress,
        instance
      )
        .then(response => {
          if (response.data.tariff.positions.length > 0) {
            setPositions(response.data.tariff.positions)
          }
        })
        .catch(error => {
          toast.error("Det har skjedd en feil, prøv igjen senere", {
            position: "top-center",
            autoClose: 6000,
          })
        })
    }
  }, [treeViewProps.employerId])

  useEffect(() => {
    setValue("newEmploymentAsEmployeeProps.workplaceError", workplaceError)
    setValue("newEmploymentAsEmployeeProps.notAWorkplace", notAWorkplace)
  }, [workplaceError, notAWorkplace])

  return (
    <div>
      <div>
        <p className="block text-sm italic font-normal sm:col-span-2 text-meny-700 sm:mt-px sm:pt-2 sm:col-start-3">
          Hvis du ikke finner riktig arbeidsgiver, arbeidsted eller stilling,{" "}
          <button
            type="button"
            onClick={() => {
              setValue("makeIncidentAsEmployee", true)
              setValue("iSNewEmploymentAsEmployee", false)
            }}
            className="text-meny-700 underline"
          >
            {" "}
            opprett sak her
          </button>
        </p>
      </div>

      <div className="">
        <div className="space-y-8 sm:space-y-5">
          <div className="space-y-6sm:space-y-5">
            <div className="space-y-6 sm:space-y-5">
              <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:pt-5">
                <label
                  htmlFor="newEmployment.role"
                  className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                >
                  Rolle:
                </label>
                <div className="mt-1 sm:mt-0 sm:col-span-2">
                  <select
                    {...register("newEmployment.role")}
                    name="newEmployment.role"
                    id="newEmployment.role"
                    key="newEmployment.role"
                    disabled={true}
                    className=" disabled:bg-gray-200 block w-full shadow-sm focus:ring-meny-700 focus:border-meny-500 sm:text-sm border-gray-300 rounded-md"
                  >
                    (<option value={125600000}>Hovedarbeidsgiver</option>)
                  </select>
                </div>
              </div>

              <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start  sm:pt-5">
                <label
                  htmlFor="newEmployment.employer"
                  className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                >
                  Arbeidsgiver:
                </label>
                <div className="mt-1 sm:mt-0 sm:col-span-2">
                  <Select
                    {...register("newEmployment.employer")}
                    name="newEmployment.employer"
                    id="newEmployment.employer"
                    key="newEmployment.employer"
                    isSearchable
                    options={bedrift?.map(a => ({
                      value: a.id,
                      label: a.name,
                      isEmployerAndWorkplace: a.employerAndWorkplace,
                    }))}
                    placeholder="--Arbeidsgiver--"
                    value={
                      parent === null || parent === undefined
                        ? null
                        : bedrift?.find(option => option.name === parent)
                    }
                    onChange={option => {
                      if (option.isEmployerAndWorkplace) {
                        setEmployerAndWorkplace(true)
                      } else {
                        setEmployerAndWorkplace(false)
                      }
                      updateWorkPlace(option)
                    }}
                    className=" block w-full shadow-sm focus:ring-meny-700 focus:border-meny-500 sm:text-sm border-gray-300 rounded-md"
                  ></Select>
                </div>
              </div>

              {workPlace !== "" && workplaces !== undefined && (
                <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start  sm:pt-5">
                  <label
                    htmlFor="workPlace"
                    className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                  >
                    Arbeidssted:
                  </label>
                  {treeViewLoading === false ? (
                    <div className="mt-1 sm:mt-0 sm:col-span-2">
                      {employerAndWorkplace ? (
                        <p>{workPlace}</p>
                      ) : (
                        <div>
                          {workplaces !== undefined && (
                            <TreeViewComponent
                              workplaces={workplaces}
                              props={treeViewProps}
                              setProps={setTreeViewProps}
                            />
                          )}
                        </div>
                      )}
                    </div>
                  ) : (
                    <p>Henter arbeidssteder...</p>
                  )}
                </div>
              )}

              {treeViewProps.employerId !== null && (
                <div>
                  <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start  sm:pt-5">
                    <label
                      htmlFor="newEmployment.position"
                      className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                    >
                      Stilling:
                    </label>
                    <div className="mt-1 sm:mt-0 sm:col-span-2">
                      <select
                        {...register("newEmployment.position")}
                        required
                        name="newEmployment.position"
                        id="newEmployment.position"
                        key="newEmployment.position"
                        value={position}
                        onChange={changePos}
                        className=" block w-full shadow-sm focus:ring-meny-700 focus:border-meny-500 sm:text-sm border-gray-300 rounded-md"
                      >
                        <option disabled selected value="">
                          {" "}
                          -- Stilling --{" "}
                        </option>

                        {positions?.map(p => (
                          <option key={p.id} value={p.id}>
                            {p.jobTitle}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>
                </div>
              )}

              <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start  sm:pt-5">
                <label
                  htmlFor="partTimePosition"
                  className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                >
                  Prosentvis stilling:
                </label>
                <div className="mt-1 sm:mt-0 sm:col-span-2">
                  <input
                    type="text"
                    {...register("newEmployment.partTimePosition", {
                      pattern: {
                        value: /^[5-9][0-9]?$|^100$/i,

                        message: "Kun tall mellom 50-100 er tillatt",
                      },
                    })}
                    name="newEmployment.partTimePosition"
                    placeholder={"50-100"}
                    defaultValue={0}
                    id="newEmployment.partTimePosition"
                    className="block w-full shadow-sm focus:ring-meny-700 focus:border-meny-500 sm:text-sm border-gray-300 rounded-md"
                  />
                  <ErrorMessage
                    errors={errors}
                    name="newEmployment.partTimePosition"
                    render={({ message }) => (
                      <p className="text-sm text-red-600">{message}</p>
                    )}
                  />
                </div>
              </div>

              <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start  sm:pt-5">
                <label className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                  Startdato:
                </label>
                <div className="mt-1 sm:mt-0 sm:col-span-2">
                  <div className="mt-1 sm:mt-0 sm:col-span-2">
                    <Controller
                      rules={{
                        required: {
                          message: "Startdato påkrevet",
                          value: true,
                        },
                      }}
                      control={control}
                      name={`newEmployment.startDate`}
                      render={({ field: { onChange, onBlur, value } }) => (
                        <ReactDatePicker
                          locale="nb"
                          onChange={onChange}
                          onBlur={onBlur}
                          minDate={
                            startDateLimit === undefined ? null : startDate
                          }
                          maxDate={
                            startDateLimit === undefined ? null : maxDate
                          }
                          selected={value ? new Date(value) : undefined}
                          dateFormat="dd/MM/yyyy"
                          className="block w-full shadow-sm focus:ring-meny-700 focus:border-meny-500 sm:text-sm border-gray-300 rounded-md"
                        />
                      )}
                    />
                    <ErrorMessage
                      errors={errors}
                      name={`newEmployment.startDate`}
                      render={({ message }) => (
                        <p className="text-sm text-red-600">{message}</p>
                      )}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="mb-2 flex justify-end">
          {(notAWorkplace || workplaceError) && (
            <p className="text-red-500">Velg et gyldig arbeidssted.</p>
          )}
        </div>
      </div>
    </div>
  )
}
