import { InteractionType } from "@azure/msal-browser"
import { MsalAuthenticationTemplate } from "@azure/msal-react"
import React, { useState } from "react"
import { MyEmployments } from "../../../../Utils/Entities"
import useApi from "../../../hooks/useApi"
import MyPageLayout from "../../../common/layouts/myPageLayout"
import { Loading } from "../../../../Utils/Loading"
import { loginRequest } from "../../../security/authConfig"
import { ErrorComponent } from "../../../common/graphichs/ErrorComponent"
import ModifyAllEmploymentsContent from "./ModifyAllEmploymentsContent"
import LoadingData from "../../../common/graphichs/LoadingData"

function ModifyAllEmploymentsPage() {
  const employmentQuery = useApi<MyEmployments[]>("/Contacts/Employments")
  const [loading, setLoading] = useState<boolean>(false)

  return (
    <MyPageLayout props="Endre eksisterende arbeidsforhold">
      {(!employmentQuery.isSuccess || loading) && <LoadingData />}
      {employmentQuery.isSuccess && (
        <ModifyAllEmploymentsContent
          employments={employmentQuery.data!}
          refetchEmployment={employmentQuery.refetch}
          setLoading={setLoading}
        />
      )}
    </MyPageLayout>
  )
}

const ModifyAllEmployments = () => {
  const authRequest = {
    ...loginRequest,
  }
  return (
    <MsalAuthenticationTemplate
      interactionType={InteractionType.Redirect}
      authenticationRequest={authRequest}
      errorComponent={ErrorComponent}
      loadingComponent={Loading}
    >
      <ModifyAllEmploymentsPage />
    </MsalAuthenticationTemplate>
  )
}
export default ModifyAllEmployments
